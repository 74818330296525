import { ICharacter } from '@vision/core';

export const getCharacterImage = (character: ICharacter): string => {
  const fallbackImage = character?.identity?.genderIdentity === 'FEMALE'
    ? 'https://st4.depositphotos.com/9998432/27431/v/450/depositphotos_274313380-stock-illustration-person-gray-photo-placeholder-woman.jpg'
    : 'https://www.treasury.gov.ph/wp-content/uploads/2022/01/male-placeholder-image.jpeg';

  const customImage = character?.images?.[0];

  const result = customImage || fallbackImage;

  return result as string;
};

export const getCharacterName = (character: ICharacter) => (
  typeof character?.name === 'object'
    ? JSON.stringify(character?.name)
    : character?.name
) ||
    character?.names?.firstName ||
    character?.names?.lastName ||
    character?.names?.nickName;