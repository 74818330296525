import { IFeature } from '@vision/core';
import { Icon, IconName, Modal, Switch, Typography } from 'components/Core';
import { fetchAppFeaturesAction } from 'features/app/appSlice';
import { useAppLanguage, useAppTheme, useProjectFeatures, useTranslations } from 'hooks';
import { useAppFeatures } from 'hooks/use-app-features';
import FeaturePage from 'pages/_Misc/Features/FeaturePage';
import { getFeatureDetails } from 'pages/_Misc/Features/Features.utils';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';

interface AppFeatureMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AppFeatureMenu: React.FC<AppFeatureMenuProps> = ({
  isOpen,
  onClose,
}) => {
  const { theme } = useAppTheme();
  const { t } = useTranslations();
  const { language } = useAppLanguage();
  const dispatch = useAppDispatch();
  const projectFeatures = useProjectFeatures();
  const [featuresState, setFeaturesState] = useState<string[]>(projectFeatures);
  const [featurePreview, setFeaturePreview] = useState<string | null>(null);

  useEffect(() => {
    console.log('AppFeatureMenu OPEN');

    return () => {
      console.log('AppFeatureMenu CLOSED');
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAppFeaturesAction({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  const features = useAppFeatures();

  return (
    // <Modal isCloseButtonInverted isOpen={isOpen} onClose={(): void => onClose?.()}>
    <div className="w-full overflow-y-auto" style={{
      height: 'calc(100vh - 5rem)',
      margin: 'auto',
      maxWidth: '100%',
      // width: '60rem'
    }}>
      {/* <div className="p-2 w-full" style={{ backgroundColor: theme?.PRIMARY }}>
        <Typography size="xl" color={'#FFF'}>Project Features</Typography>
      </div> */}
      <div className="flex flex-row">
        <div className="w-1/4">
          {features?.map((feature: IFeature) => {
            const isActive = featuresState.includes(feature.key);

            return (
              <div key={feature.key} className="flex flex-row items-center justify-between p-4 card cursor-pointer border-b border-layout-border" onClick={() => setFeaturePreview(feature.key)}>
                {/* @ts-ignore */}
                <div className="flex flex-row items-center" >
                  <Icon name={feature.key as IconName} color={isActive ? theme?.PRIMARY : theme?.TEXT} />
                  {/* @ts-ignore */}
                  <Typography className="ml-4" color={isActive ? theme?.PRIMARY : theme?.TEXT_LIGHT}>{t.features[feature.key]}</Typography>
                </div>
                <div className="flex flex-row items-center">
                  {/* <a className="cursor-pointer mr-4 mt-1 -mb-1"
                    href={`${window.location.origin}/v1/feature-docs/${feature.key}`}
                    target={'_blank'} rel="noreferrer"
                  >
                    <FontAwesome name="external-link" className='focus:outline-none' style={{ color: `${theme?.PRIMARY}66` }} />
                  </a> */}
                  <Switch
                    label={''}
                    value={featuresState?.includes(feature.key)}
                    onChange={(value: boolean): void => {
                      console.log(`switch ${feature.key}: ${value}`);
                      if (value) {
                        setFeaturesState((prev) => ([...prev, feature?.key].sort()));
                      } else {
                        const index = featuresState.findIndex((f: string) => f === feature.key);

                        setFeaturesState((prev) => ([
                          ...prev.slice(0, index),
                          ...prev.slice(index + 1)
                        ]));
                      }

                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ backgroundColor: `${theme?.TEXT}22`, width: '1px' }} />
        <div className="w-3/4" style={{
          // maxHeight: 'calc(100vh - 22rem)'
        }}>
          {featurePreview ? (
            <FeaturePage key={featurePreview}
              feature={
                getFeatureDetails({ key: featurePreview } as IFeature, language, t)
              }
              showFeatureButton={false}
            />
          ) : (
            <div className="flex items-center justify-center">
              <h3>Click on a feature to see the details</h3>
            </div>
          )}
        </div>
      </div>
    </div>
    // </Modal>
  );
};

export default AppFeatureMenu;