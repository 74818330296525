/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { AsyncDataHook, SliceType } from 'models/store';
import { useAppTheme, useAppUrl, usePageId, useProjectId, useTranslations } from 'hooks';
import { SplitView } from 'components/App';
import { IProjectResource } from '@vision/core/lib/models/resource';
import { FeatureGuide } from './FeatureGuide';
import { dispatchCustomEvent } from 'utils/events';
import { ActionBar, GalleryExplorer, IconName, Typography, createDefaultGallery } from 'components/Core';
import { sortBy, toSentenceCase } from '@vision/core';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'components/Core/Toast/ToastProvider';
import _ from 'lodash';
import { useLimits } from 'hooks/use-limits';

interface FeatureContainerProps<T> {
  slice: SliceType;
  // type:
  api: AsyncDataHook<T & IProjectResource>;
  activeItem: IProjectResource;
  itemName: string;
  hasImages?: boolean,
  renderPreview?: (isEditing: boolean, onSetItem: (itemRef: T | null) => void) => React.ReactElement;
  renderTools?: (tab: string, onSave: (item: T & IProjectResource) => void) => React.ReactElement,
  onSetItem: (itemRef: T | null) => void | ((itemRef: T | {}) => void),
  onPreviewItem?: (itemRef: T & IProjectResource | null) => void,
  getItemImage?: (item: T | null) => string,
  getItemName?: (item: T | null) => string,
  hasLeftMenu?: boolean,
  hasRightMenu?: boolean,
}

export const FeatureContainer = <T,>({
  slice,
  api,
  renderPreview,
  renderTools,
  hasImages,
  activeItem,
  onSetItem,
  onPreviewItem,
  getItemImage,
  getItemName,
  hasLeftMenu = true,
  hasRightMenu = true,
  //   activeItem,
}: FeatureContainerProps<T & IProjectResource>): React.ReactElement => {
  // #region HOOKS
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { theme } = useAppTheme();
  const [tab] = useAppUrl('tab');
  //   const { setBreadcrumb } = useAppBreadcrumbs();
  const { addToast } = useToast();
  const { hasCreditsFor, expenseFeature } = useLimits();

  //   const readText = useSpeech();
  const id = usePageId();
  const projectId = useProjectId() as string;
  // @ts-ignore
  const translations = t[slice];
  // #endregion

  // #region STORE
  const {
    list: { data, loading, error },
  } = api;
  // #endregion

  // #region STATE
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // #endregion

  // #region HANDLERS
  const handleSetItem = (itemRef: T | null): void => {
    const item = _.clone(itemRef) as T;
    onSetItem(null);

    setTimeout(() => {
      onSetItem(item as (T & IProjectResource));

      if (item === null) {
        navigate(`/v1/${slice}`);
      } else {
        // @ts-ignore
        navigate(`/v1/${slice}/${item?.id}?tab=${tab || 'edit'}`);
      }
    }, 1);
  };
  const handlePreview = (): void => {
    onPreviewItem?.(activeItem as T & IProjectResource);
    addToast({
      body: `${toSentenceCase(slice)} preview`,
      type: 'info',
    });
  };
  const handleDuplicate = (): void => {
    addToast({
      body: `TODO: Duplicating ${toSentenceCase(slice)}`,
      type: 'primary',
    });
  };
  // #endregion

  //#region CRUD
  const handleError = (error: any): void => {
    addToast({
      body: `Error:\n${JSON.stringify(error, null, 2)}`,
      type: 'error',
    });
  };
  const handleFetch = (): void => {
    api.read({
      onError: handleError,
      onSuccess: () => {
        addToast({
          // @ts-ignore
          body: `Fetched ${t.features[slice]}`,
          type: 'primary',
        });
      },
      projectId,
    });
  };
  const handleSave = (): void => {
    // setHasSubmitted(true)
    addToast({
      body: `Saving ${toSentenceCase(slice)}`,
      type: 'info',
    });

    if (activeItem?.id) {
      api?.update({
        data: activeItem as T & IProjectResource,
        id: activeItem?.id as string,
        onError: handleError,
        onSuccess: () => {
          handleFetch();
          addToast({
            // @ts-ignore
            body: `Updated ${t.features[slice]}`,
            type: 'success',
          });
        },
        projectId,
      });
    } else {
      if (hasCreditsFor(slice)) {
        api.create({
          data: activeItem as T & IProjectResource,
          onError: handleError,
          onSuccess: () => {
            expenseFeature(slice);
            handleFetch();
            addToast({
              // @ts-ignore
              body: `Created ${t.features[slice]}`,
              type: 'success',
            });
          },
          projectId,
        });
      }
    }
  };
  const handleDelete = (): void => {
    api.delete({
      // @ts-ignore
      id: activeItem?.ref as string,
      onError: handleError,
      onSuccess: () => {
        handleFetch();
        addToast({
          // @ts-ignore
          body: `Deleted ${t.features[slice]}: ${activeItem?.name}`,
          type: 'success',
        });
      },
      projectId: undefined,
    });
  };
  // #endregion

  useEffect(() => {
    // @ts-ignore
    if (id && api?.list?.data?.length > 0) {
      // @ts-ignore
      const matchedItem = data?.find?.((c: T) => c?.id === id);
      const arg = _.clone(matchedItem) as T;

      handleSetItem(arg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SplitView
      slice={slice}
      isShowingItem={activeItem !== null}
      isShowingControls={isEditing && hasRightMenu}
      hasRightMenu={hasRightMenu}
      hasLeftMenu={hasLeftMenu}
      // @ts-ignore
      controlView={(
        renderTools?.(tab, handleSave)
      )}
      emptyView={(
        <FeatureGuide
          value={slice}
          // @ts-ignore
          name={'Test' || t.features[slice]}
          docs={translations?.docs}
          faqs={translations?.faqs}
          onCreate={() => {
            setIsEditing(true);
            dispatchCustomEvent('ui.openRightSideMenu');

            // @ts-ignore
            onSetItem({
              ...(hasImages ? { images: [] } : {})
            });
          }}
          onClickItem={(item: T) => handleSetItem(item)}
          recentItems={api.list?.data
            ?.slice?.()
            //   @ts-ignore
            ?.sort?.(sortBy<T>('dateUpdated', 'desc'))
            ?.slice(0, 3)
          }
        />
      )}
      exploreView={(
        <GalleryExplorer<T & IProjectResource>
          icon={slice as IconName}
          heading={{
            // @ts-ignore
            description: t[slice]?.description,
            // @ts-ignore
            title: t.features[slice],
          }}
          loading={loading}
          error={error}
          // @ts-ignore
          data={createDefaultGallery<T>(t[slice]?.title as string, data, projectId)}
          // onRefresh={handleFetch}
          onSetItem={handleSetItem}
          renderItem={(item: T & IProjectResource) => (
            <>
              <div
                className="rounded-full mr-2"
                style={{
                  background: `url(${getItemImage?.(item)})`,
                  backgroundAttachment: 'center',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  minHeight: '1.5rem',
                  minWidth: '1.5rem',
                }}
              />
              {/* @ts-ignore */}
              <Typography color={activeItem?.id === item.id ? theme?.PRIMARY : ''}>
                {getItemName ? getItemName?.(item) : null}
              </Typography>
            </>
          )}
        />
      )}
      contentView={(
        <div className="h-full overflow-y-auto overflow-x-hidden no-scrollbar">
          <ActionBar
            sliceType={slice}
            activeItem={activeItem}
            isEditing={isEditing}
            onEdit={() => {
              dispatchCustomEvent('ui.openRightSideMenu');
              setIsEditing(true);
            }}
            onEditCancel={() => {
              setIsEditing(false);
              addToast({
                body: `Cancelled editing ${toSentenceCase(slice)}`,
                type: 'warning'
              });
            }}
            onPreview={handlePreview}
            onSave={handleSave}
            onDuplicate={handleDuplicate}
            onDelete={handleDelete}
            onClose={() => {
              handleSetItem(null);
              setIsEditing(false);
            }}
          />
          {renderPreview?.(isEditing, onSetItem)}
        </div>
      )}
    />
  );
};

export default FeatureContainer;
