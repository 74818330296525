/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { IFeature, toSentenceCase } from '@vision/core';
import { Button, Icon, IconName, MasonryGallery, Typography, View } from 'components/Core';
import TypingAnimation from 'components/Core/TypingAnimation/TypingAnimation';
import { useAnimateOnChange, useAppLanguage, useAppTheme, useTranslations } from 'hooks';
import { useNavigate } from 'react-router-dom';

import { IFeatureCopyTranslated, IFeatureDetails } from './Features.utils';

interface FeaturePageProps {
  feature: IFeature & IFeatureDetails & IFeatureCopyTranslated;
  showFeatureButton?: boolean,
}

const FeaturePage: React.FC<FeaturePageProps> = ({
  feature,
  showFeatureButton = false
}) => {
  useAnimateOnChange([feature]);
  const { theme } = useAppTheme();
  const { t } = useTranslations();
  useAppLanguage();

  const navigate = useNavigate();

  return (
    <View className="p-4">
      <View className="mb-4 flex flex-row justify-between">
        <View className="flex flex-row">
          <View className="mr-4">
            <Icon
              name={feature.key as IconName}
              color={theme?.TEXT}
              size={36}
            />
          </View>
          <Typography size="xl" weight="700">
            <TypingAnimation
              speed={30}
              hasCaret
              texts={[
                // @ts-ignore
                t.features[feature?.key] || ''
              ]}
            />

          </Typography>
        </View>
      </View>
      <Typography size="md" weight="500" className="animate-on-change mb-4">
        <TypingAnimation
          speed={15}
          texts={[
            // @ts-ignore
            feature?.description || feature?.description
          ]}
        />
      </Typography>
      {
        feature?.gif && (
          <img
            src={feature?.gif}
            className="w-full mt-8 animate-on-change"
            alt="feature-gif"
            style={{
              boxShadow: `0 1px 3px ${theme?.TEXT}77, 0 1px 3px ${theme?.PRIMARY}AA`
            }}
          />
        )
      }

      <View className='animate-on-change'>

        <Button
          className="my-4 mt-10"
          onClick={() => navigate(`/docs/${feature?.key}`)}
          size="lg"
          isOutline
        >
          {/* @ts-ignore */}
          {t.common.docs}: {toSentenceCase(t.features[feature?.key])}
        </Button>
      </View>

      {feature?.images && <View className="mt-8">
        <MasonryGallery
          images={feature?.images}
          useAnimate
          twHeight='h-24 md:h-32'
          twHeightHover='hover:h-32 md:hover:h-40'
        />
      </View>}

      <View className="space-y-12 animate-on-change">

        {feature?.examples && (
          <>
            <Typography size="2xl">Examples</Typography>
            <br />
            {feature?.examples?.map((detail, index) => (
              <View key={index}>
                <Typography
                  size="xl"
                  className="w-full text-left">
                  {detail?.title}
                </Typography>
                <br />
                <Typography>
                  {/* @ts-ignore */}
                  <img src={detail?.content} alt={`content-${index}`} />
                </Typography>
              </View>
            ))}
          </>
        )}
      </View>

      <br />
      <br />

      <View className="space-y-12 animate-on-change">
        {feature?.details?.map((detail, index) => (
          <View key={index}>
            <Typography
              size="xl"
              className="w-full text-left">
              {detail?.title}
            </Typography>
            <br />
            <Typography>
              {detail?.content}
            </Typography>
          </View>
        ))}

        {showFeatureButton && (
          <View className="animate-on-change">
            <Button
              className="my-4"
              onClick={() => navigate(`/v1/${feature?.key}`)}
              size="lg"
            >
              <Icon color={'#FFF'}
                name={feature?.key as IconName}
                imageClass='mr-2'
                size={24}
              />
              {/* @ts-ignore */}
              {t.features[feature?.key]} →
            </Button>
          </View>
        )}

        <View className="my-8 h-12 w-full" />
      </View>
    </View>

  );
};

export default FeaturePage;

