export const firebase = {
  config: {
    DEV: {
      apiKey: 'AIzaSyCZWLaYYTXdXzwiHdHTYHJHpsE4VyOGCQM',
      appId: '1:515056409919:web:e73a4c9205d9addb39878a',
      authDomain: 'visionstudio-dev.firebaseapp.com',
      measurementId: 'G-YQM6352VYR',
      messagingSenderId: '515056409919',
      projectId: 'visionstudio-dev',
      storageBucket: 'visionstudio-dev.appspot.com'
    },
    PROD: {
      apiKey: 'AIzaSyAEKkJlcX7u8xo-G0w5UiJUUhdkMzTASc0',
      appId: '1:1043965016335:web:fa62e210e6d98e50ed667a',
      authDomain: 'vision-io.firebaseapp.com',
      databaseURL: 'https://vision-io.firebaseio.com',
      messagingSenderId: '1043965016335',
      projectId: 'vision-io',
      storageBucket: 'vision-io.appspot.com'
    },
    PROD_NEW: {
      apiKey: 'AIzaSyABrgPLfEDUn0UhW0KbVLVk1i6J_ER_HZY',
      appId: '1:733218024131:web:24bc5af5953aff3d021076',
      authDomain: 'visionstudio-ai.firebaseapp.com',
      measurementId: 'G-4SWQVHZKGN',
      messagingSenderId: '733218024131',
      projectId: 'visionstudio-ai',
      storageBucket: 'visionstudio-ai.appspot.com'
    },
    STAGING: {
      apiKey: 'AIzaSyAQwSAtc2YEBBmiJnqcDqOwzcOhy3ObIDg',
      appId: '1:254049788790:web:96204ce3c0ad2170751057',
      authDomain: 'visionstudio-staging.firebaseapp.com',
      measurementId: 'G-KR5DP57BMD',
      messagingSenderId: '254049788790',
      projectId: 'visionstudio-staging',
      storageBucket: 'visionstudio-staging.appspot.com'
    },
  }
};