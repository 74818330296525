import { IProjectResource } from '@vision/core';
import { Accordion } from 'components/Core';
import RandomBackground from 'components/Core/Random/RandomBackground';
import useRandomImage from 'hooks/use-random-image';
import React from 'react';

interface FeaturePreviewProps<T> {
    value: T & IProjectResource,
    header?: React.ReactElement,
    hasImages?: boolean,
}

export const FeaturePreview = <T,>({
    value,
    header,
    hasImages,
}: FeaturePreviewProps<T>): React.ReactElement => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const images = value?.images;
    const image = useRandomImage(3500, images);

    return (
        <div className="w-full">
            <RandomBackground hasBlur className={''} images={images}>
                <div className="flex flex-col items-center justify-center p-0 px-0">
                    {header}
                    {hasImages && images ? (
                        <div className="h-auto">
                            <img className="w-auto h-112 max-h-112 max-w-full rounded-lg m-4 mb-8"
                                // src={getCharacterImage(activeCharacter)}
                                src={image}
                                alt="character"
                            />
                        </div>
                    ) : (
                        <div className="w-80 h-80 rounded-full bg-gray-500 bg-opacity-30 cursor-pointer flex justify-center items-center">
                            <img className="h-40 rounded-full"
                                // src={getCharacterImage(activeCharacter as ICharacter)}
                                src={image}
                                alt="character"
                            />
                        </div>
                    )}
                </div>
            </RandomBackground>

            <hr />

            <Accordion title="Summary" isOpenDefault>
                <div className="p-4">
                    {/* <Typography>
                        {summary}
                    </Typography> */}
                </div>
            </Accordion>
        </div>
    );
};

export default FeaturePreview;