// src/components/Core/Icons/FlagIcon/countries.ts

export interface Country {
    name: string;
    key: string;
    alpha2: string;
    alpha3: string;
    dialCode: string;
    language: string;
    currency: {
        name: string;
        code: string;
        symbol: string;
    };
}

export const countries: Country[] = [
  { alpha2: 'AF', alpha3: 'AFG', currency: { code: 'AFN', name: 'Afghan Afghani', symbol: '؋' }, dialCode: '+93', key: 'Afghanistan', language: 'Pashto, Dari', name: 'Afghanistan' },
  { alpha2: 'AX', alpha3: 'ALA', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+358', key: 'AlandIslands', language: 'Swedish', name: 'Aland Islands' },
  { alpha2: 'AL', alpha3: 'ALB', currency: { code: 'ALL', name: 'Albanian Lek', symbol: 'L' }, dialCode: '+355', key: 'Albania', language: 'Albanian', name: 'Albania' },
  { alpha2: 'DZ', alpha3: 'DZA', currency: { code: 'DZD', name: 'Algerian Dinar', symbol: 'د.ج' }, dialCode: '+213', key: 'Algeria', language: 'Arabic, Berber', name: 'Algeria' },
  { alpha2: 'AS', alpha3: 'ASM', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+1684', key: 'AmericanSamoa', language: 'English, Samoan', name: 'American Samoa' },
  { alpha2: 'AD', alpha3: 'AND', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+376', key: 'Andorra', language: 'Catalan', name: 'Andorra' },
  { alpha2: 'AO', alpha3: 'AGO', currency: { code: 'AOA', name: 'Angolan Kwanza', symbol: 'Kz' }, dialCode: '+244', key: 'Angola', language: 'Portuguese', name: 'Angola' },
  { alpha2: 'AI', alpha3: 'AIA', currency: { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' }, dialCode: '+1264', key: 'Anguilla', language: 'English', name: 'Anguilla' },
  { alpha2: 'AQ', alpha3: 'ATA', currency: { code: 'N/A', name: 'No universal currency', symbol: 'N/A' }, dialCode: '+672', key: 'Antarctica', language: 'English', name: 'Antarctica' },
  { alpha2: 'AG', alpha3: 'ATG', currency: { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' }, dialCode: '+1268', key: 'AntiguaAndBarbuda', language: 'English', name: 'Antigua and Barbuda' },
  { alpha2: 'AR', alpha3: 'ARG', currency: { code: 'ARS', name: 'Argentine Peso', symbol: '$' }, dialCode: '+54', key: 'Argentina', language: 'Spanish', name: 'Argentina' },
  { alpha2: 'AM', alpha3: 'ARM', currency: { code: 'AMD', name: 'Armenian Dram', symbol: '֏' }, dialCode: '+374', key: 'Armenia', language: 'Armenian', name: 'Armenia' },
  { alpha2: 'AW', alpha3: 'ABW', currency: { code: 'AWG', name: 'Aruban Florin', symbol: 'ƒ' }, dialCode: '+297', key: 'Aruba', language: 'Dutch, Papiamento', name: 'Aruba' },
  { alpha2: 'AC', alpha3: 'ASC', currency: { code: 'SHP', name: 'Saint Helena Pound', symbol: '£' }, dialCode: '+247', key: 'AscensionIsland', language: 'English', name: 'Ascension Island' },
  { alpha2: 'AU', alpha3: 'AUS', currency: { code: 'AUD', name: 'Australian Dollar', symbol: '$' }, dialCode: '+61', key: 'Australia', language: 'English', name: 'Australia' },
  { alpha2: 'AT', alpha3: 'AUT', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+43', key: 'Austria', language: 'German', name: 'Austria' },
  { alpha2: 'AZ', alpha3: 'AZE', currency: { code: 'AZN', name: 'Azerbaijani Manat', symbol: '₼' }, dialCode: '+994', key: 'Azerbaijan', language: 'Azerbaijani', name: 'Azerbaijan' },
  { alpha2: 'BS', alpha3: 'BHS', currency: { code: 'BSD', name: 'Bahamian Dollar', symbol: '$' }, dialCode: '+1242', key: 'Bahamas', language: 'English', name: 'Bahamas' },
  { alpha2: 'BH', alpha3: 'BHR', currency: { code: 'BHD', name: 'Bahraini Dinar', symbol: '.د.ب' }, dialCode: '+973', key: 'Bahrain', language: 'Arabic', name: 'Bahrain' },
  { alpha2: 'BD', alpha3: 'BGD', currency: { code: 'BDT', name: 'Bangladeshi Taka', symbol: '৳' }, dialCode: '+880', key: 'Bangladesh', language: 'Bengali', name: 'Bangladesh' },
  { alpha2: 'BB', alpha3: 'BRB', currency: { code: 'BBD', name: 'Barbadian Dollar', symbol: '$' }, dialCode: '+1246', key: 'Barbados', language: 'English', name: 'Barbados' },
  { alpha2: 'BY', alpha3: 'BLR', currency: { code: 'BYN', name: 'Belarusian Ruble', symbol: 'Br' }, dialCode: '+375', key: 'Belarus', language: 'Belarusian, Russian', name: 'Belarus' },
  { alpha2: 'BE', alpha3: 'BEL', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+32', key: 'Belgium', language: 'Dutch, French, German', name: 'Belgium' },
  { alpha2: 'BZ', alpha3: 'BLZ', currency: { code: 'BZD', name: 'Belize Dollar', symbol: '$' }, dialCode: '+501', key: 'Belize', language: 'English', name: 'Belize' },
  { alpha2: 'BJ', alpha3: 'BEN', currency: { code: 'XOF', name: 'West African CFA Franc', symbol: 'Fr' }, dialCode: '+229', key: 'Benin', language: 'French', name: 'Benin' },
  { alpha2: 'BM', alpha3: 'BMU', currency: { code: 'BMD', name: 'Bermudian Dollar', symbol: '$' }, dialCode: '+1441', key: 'Bermuda', language: 'English', name: 'Bermuda' },
  { alpha2: 'BT', alpha3: 'BTN', currency: { code: 'BTN', name: 'Bhutanese Ngultrum', symbol: 'Nu.' }, dialCode: '+975', key: 'Bhutan', language: 'Dzongkha', name: 'Bhutan' },
  { alpha2: 'NA', alpha3: 'NA', currency: { code: 'NA', name: 'NA', symbol: 'NA' }, dialCode: 'NA', key: 'BlackFlag', language: 'NA', name: 'Black Flag' },
  { alpha2: 'BO', alpha3: 'BOL', currency: { code: 'BOB', name: 'Bolivian Boliviano', symbol: 'Bs.' }, dialCode: '+591', key: 'Bolivia', language: 'Spanish, Quechua, Aymara', name: 'Bolivia' },
  { alpha2: 'BA', alpha3: 'BIH', currency: { code: 'BAM', name: 'Bosnia and Herzegovina Convertible Mark', symbol: 'KM' }, dialCode: '+387', key: 'BosniaAndHerzegovina', language: 'Bosnian, Croatian, Serbian', name: 'Bosnia and Herzegovina' },
  { alpha2: 'BW', alpha3: 'BWA', currency: { code: 'BWP', name: 'Botswana Pula', symbol: 'P' }, dialCode: '+267', key: 'Botswana', language: 'English, Tswana', name: 'Botswana' },
  { alpha2: 'BV', alpha3: 'BVT', currency: { code: 'NOK', name: 'Norwegian Krone', symbol: 'kr' }, dialCode: '+47', key: 'BouvetIsland', language: 'Norwegian', name: 'Bouvet Island' },
  { alpha2: 'BR', alpha3: 'BRA', currency: { code: 'BRL', name: 'Brazilian Real', symbol: 'R$' }, dialCode: '+55', key: 'Brazil', language: 'Portuguese', name: 'Brazil' },
  { alpha2: 'IO', alpha3: 'IOT', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+246', key: 'BritishIndianOceanTerritory', language: 'English', name: 'British Indian Ocean Territory' },
  { alpha2: 'VG', alpha3: 'VGB', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+1284', key: 'BritishVirginIslands', language: 'English', name: 'British Virgin Islands' },
  { alpha2: 'BN', alpha3: 'BRN', currency: { code: 'BND', name: 'Brunei Dollar', symbol: '$' }, dialCode: '+673', key: 'Brunei', language: 'Malay', name: 'Brunei' },
  { alpha2: 'BG', alpha3: 'BGR', currency: { code: 'BGN', name: 'Bulgarian Lev', symbol: 'лв' }, dialCode: '+359', key: 'Bulgaria', language: 'Bulgarian', name: 'Bulgaria' },
  { alpha2: 'BF', alpha3: 'BFA', currency: { code: 'XOF', name: 'West African CFA Franc', symbol: 'Fr' }, dialCode: '+226', key: 'BurkinaFaso', language: 'French', name: 'Burkina Faso' },
  { alpha2: 'BI', alpha3: 'BDI', currency: { code: 'BIF', name: 'Burundian Franc', symbol: 'Fr' }, dialCode: '+257', key: 'Burundi', language: 'Kirundi, French', name: 'Burundi' },
  { alpha2: 'KH', alpha3: 'KHM', currency: { code: 'KHR', name: 'Cambodian Riel', symbol: '៛' }, dialCode: '+855', key: 'Cambodia', language: 'Khmer', name: 'Cambodia' },
  { alpha2: 'CM', alpha3: 'CMR', currency: { code: 'XAF', name: 'Central African CFA Franc', symbol: 'Fr' }, dialCode: '+237', key: 'Cameroon', language: 'French, English', name: 'Cameroon' },
  { alpha2: 'CA', alpha3: 'CAN', currency: { code: 'CAD', name: 'Canadian Dollar', symbol: '$' }, dialCode: '+1', key: 'Canada', language: 'English, French', name: 'Canada' },
  { alpha2: 'IC', alpha3: 'ICA', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+34', key: 'CanaryIslands', language: 'Spanish', name: 'Canary Islands' },
  { alpha2: 'CV', alpha3: 'CPV', currency: { code: 'CVE', name: 'Cape Verdean Escudo', symbol: '$' }, dialCode: '+238', key: 'CapeVerde', language: 'Portuguese', name: 'Cape Verde' },
  { alpha2: 'BQ', alpha3: 'BES', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+599', key: 'CaribbeanNetherlands', language: 'Dutch', name: 'Caribbean Netherlands' },
  { alpha2: 'KY', alpha3: 'CYM', currency: { code: 'KYD', name: 'Cayman Islands Dollar', symbol: '$' }, dialCode: '+1345', key: 'CaymanIslands', language: 'English', name: 'Cayman Islands' },
  { alpha2: 'CF', alpha3: 'CAF', currency: { code: 'XAF', name: 'Central African CFA Franc', symbol: 'Fr' }, dialCode: '+236', key: 'CentralAfricanRepublic', language: 'French, Sango', name: 'Central African Republic' },
  { alpha2: 'EA', alpha3: 'EAM', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+34', key: 'CeutaAndMelilla', language: 'Spanish', name: 'Ceuta and Melilla' },
  { alpha2: 'TD', alpha3: 'TCD', currency: { code: 'XAF', name: 'Central African CFA Franc', symbol: 'Fr' }, dialCode: '+235', key: 'Chad', language: 'French, Arabic', name: 'Chad' },
  { alpha2: 'NA', alpha3: 'NA', currency: { code: 'NA', name: 'NA', symbol: 'NA' }, dialCode: 'NA', key: 'ChequeredFlag', language: 'NA', name: 'Chequered Flag' },
  { alpha2: 'CL', alpha3: 'CHL', currency: { code: 'CLP', name: 'Chilean Peso', symbol: '$' }, dialCode: '+56', key: 'Chile', language: 'Spanish', name: 'Chile' },
  { alpha2: 'CN', alpha3: 'CHN', currency: { code: 'CNY', name: 'Chinese Yuan', symbol: '¥' }, dialCode: '+86', key: 'China', language: 'Mandarin', name: 'China' },
  { alpha2: 'CX', alpha3: 'CXR', currency: { code: 'AUD', name: 'Australian Dollar', symbol: '$' }, dialCode: '+61', key: 'ChristmasIsland', language: 'English', name: 'Christmas Island' },
  { alpha2: 'CP', alpha3: 'CPT', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+262', key: 'ClippertonIsland', language: 'French', name: 'Clipperton Island' },
  { alpha2: 'CC', alpha3: 'CCK', currency: { code: 'AUD', name: 'Australian Dollar', symbol: '$' }, dialCode: '+61', key: 'CocosIslands', language: 'English', name: 'Cocos Islands' },
  { alpha2: 'CO', alpha3: 'COL', currency: { code: 'COP', name: 'Colombian Peso', symbol: '$' }, dialCode: '+57', key: 'Colombia', language: 'Spanish', name: 'Colombia' },
  { alpha2: 'KM', alpha3: 'COM', currency: { code: 'KMF', name: 'Comorian Franc', symbol: 'Fr' }, dialCode: '+269', key: 'Comoros', language: 'Comorian, Arabic, French', name: 'Comoros' },
  { alpha2: 'CG', alpha3: 'COG', currency: { code: 'XAF', name: 'Central African CFA Franc', symbol: 'Fr' }, dialCode: '+242', key: 'CongoBrazzaville', language: 'French', name: 'Congo-Brazzaville' },
  { alpha2: 'CD', alpha3: 'COD', currency: { code: 'CDF', name: 'Congolese Franc', symbol: 'Fr' }, dialCode: '+243', key: 'CongoKinshasa', language: 'French, Lingala, Swahili', name: 'Congo-Kinshasa' },
  { alpha2: 'CK', alpha3: 'COK', currency: { code: 'NZD', name: 'New Zealand Dollar', symbol: '$' }, dialCode: '+682', key: 'CookIslands', language: 'English, Cook Islands Maori', name: 'Cook Islands' },
  { alpha2: 'CR', alpha3: 'CRI', currency: { code: 'CRC', name: 'Costa Rican Colon', symbol: '₡' }, dialCode: '+506', key: 'CostaRica', language: 'Spanish', name: 'Costa Rica' },
  { alpha2: 'HR', alpha3: 'HRV', currency: { code: 'HRK', name: 'Croatian Kuna', symbol: 'kn' }, dialCode: '+385', key: 'Croatia', language: 'Croatian', name: 'Croatia' },
  { alpha2: 'CU', alpha3: 'CUB', currency: { code: 'CUP', name: 'Cuban Peso', symbol: '$' }, dialCode: '+53', key: 'Cuba', language: 'Spanish', name: 'Cuba' },
  { alpha2: 'CW', alpha3: 'CUW', currency: { code: 'ANG', name: 'Netherlands Antillean Guilder', symbol: 'ƒ' }, dialCode: '+599', key: 'Curacao', language: 'Dutch, Papiamento', name: 'Curacao' },
  { alpha2: 'CY', alpha3: 'CYP', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+357', key: 'Cyprus', language: 'Greek, Turkish', name: 'Cyprus' },
  { alpha2: 'CZ', alpha3: 'CZE', currency: { code: 'CZK', name: 'Czech Koruna', symbol: 'Kč' }, dialCode: '+420', key: 'CzechRepublic', language: 'Czech', name: 'Czech Republic' },
  { alpha2: 'DK', alpha3: 'DNK', currency: { code: 'DKK', name: 'Danish Krone', symbol: 'kr' }, dialCode: '+45', key: 'Denmark', language: 'Danish', name: 'Denmark' },
  { alpha2: 'DG', alpha3: 'DGA', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+246', key: 'DiegoGarcia', language: 'English', name: 'Diego Garcia' },
  { alpha2: 'DJ', alpha3: 'DJI', currency: { code: 'DJF', name: 'Djiboutian Franc', symbol: 'Fr' }, dialCode: '+253', key: 'Djibouti', language: 'French, Arabic', name: 'Djibouti' },
  { alpha2: 'DM', alpha3: 'DMA', currency: { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' }, dialCode: '+1767', key: 'Dominica', language: 'English', name: 'Dominica' },
  { alpha2: 'DO', alpha3: 'DOM', currency: { code: 'DOP', name: 'Dominican Peso', symbol: '$' }, dialCode: '+1', key: 'DominicanRepublic', language: 'Spanish', name: 'Dominican Republic' },
  { alpha2: 'EC', alpha3: 'ECU', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+593', key: 'Ecuador', language: 'Spanish', name: 'Ecuador' },
  { alpha2: 'EG', alpha3: 'EGY', currency: { code: 'EGP', name: 'Egyptian Pound', symbol: '£' }, dialCode: '+20', key: 'Egypt', language: 'Arabic', name: 'Egypt' },
  { alpha2: 'SV', alpha3: 'SLV', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+503', key: 'ElSalvador', language: 'Spanish', name: 'El Salvador' },
  { alpha2: 'GQ', alpha3: 'GNQ', currency: { code: 'XAF', name: 'Central African CFA Franc', symbol: 'Fr' }, dialCode: '+240', key: 'EquatorialGuinea', language: 'Spanish, French, Portuguese', name: 'Equatorial Guinea' },
  { alpha2: 'ER', alpha3: 'ERI', currency: { code: 'ERN', name: 'Eritrean Nakfa', symbol: 'Nfk' }, dialCode: '+291', key: 'Eritrea', language: 'Tigrinya, Arabic, English', name: 'Eritrea' },
  { alpha2: 'EE', alpha3: 'EST', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+372', key: 'Estonia', language: 'Estonian', name: 'Estonia' },
  { alpha2: 'SZ', alpha3: 'SWZ', currency: { code: 'SZL', name: 'Swazi Lilangeni', symbol: 'L' }, dialCode: '+268', key: 'Eswatini', language: 'Swati, English', name: 'Eswatini' },
  { alpha2: 'ET', alpha3: 'ETH', currency: { code: 'ETB', name: 'Ethiopian Birr', symbol: 'Br' }, dialCode: '+251', key: 'Ethiopia', language: 'Amharic', name: 'Ethiopia' },
  { alpha2: 'FK', alpha3: 'FLK', currency: { code: 'FKP', name: 'Falkland Islands Pound', symbol: '£' }, dialCode: '+500', key: 'FalklandIslands', language: 'English', name: 'Falkland Islands' },
  { alpha2: 'FO', alpha3: 'FRO', currency: { code: 'DKK', name: 'Danish Krone', symbol: 'kr' }, dialCode: '+298', key: 'FaroeIslands', language: 'Faroese', name: 'Faroe Islands' },
  { alpha2: 'FJ', alpha3: 'FJI', currency: { code: 'FJD', name: 'Fijian Dollar', symbol: '$' }, dialCode: '+679', key: 'Fiji', language: 'English, Fijian, Hindi', name: 'Fiji' },
  { alpha2: 'FI', alpha3: 'FIN', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+358', key: 'Finland', language: 'Finnish, Swedish', name: 'Finland' },
  { alpha2: 'FR', alpha3: 'FRA', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+33', key: 'France', language: 'French', name: 'France' },
  { alpha2: 'GF', alpha3: 'GUF', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+594', key: 'FrenchGuiana', language: 'French', name: 'French Guiana' },
  { alpha2: 'PF', alpha3: 'PYF', currency: { code: 'XPF', name: 'CFP Franc', symbol: 'Fr' }, dialCode: '+689', key: 'FrenchPolynesia', language: 'French', name: 'French Polynesia' },
  { alpha2: 'TF', alpha3: 'ATF', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+262', key: 'FrenchSouthernTerritories', language: 'French', name: 'French Southern Territories' },
  { alpha2: 'GA', alpha3: 'GAB', currency: { code: 'XAF', name: 'Central African CFA Franc', symbol: 'Fr' }, dialCode: '+241', key: 'Gabon', language: 'French', name: 'Gabon' },
  { alpha2: 'GM', alpha3: 'GMB', currency: { code: 'GMD', name: 'Gambian Dalasi', symbol: 'D' }, dialCode: '+220', key: 'Gambia', language: 'English', name: 'Gambia' },
  { alpha2: 'GE', alpha3: 'GEO', currency: { code: 'GEL', name: 'Georgian Lari', symbol: '₾' }, dialCode: '+995', key: 'Georgia', language: 'Georgian', name: 'Georgia' },
  { alpha2: 'DE', alpha3: 'DEU', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+49', key: 'Germany', language: 'German', name: 'Germany' },
  { alpha2: 'GH', alpha3: 'GHA', currency: { code: 'GHS', name: 'Ghanaian Cedi', symbol: '₵' }, dialCode: '+233', key: 'Ghana', language: 'English', name: 'Ghana' },
  { alpha2: 'GI', alpha3: 'GIB', currency: { code: 'GIP', name: 'Gibraltar Pound', symbol: '£' }, dialCode: '+350', key: 'Gibraltar', language: 'English', name: 'Gibraltar' },
  { alpha2: 'GR', alpha3: 'GRC', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+30', key: 'Greece', language: 'Greek', name: 'Greece' },
  { alpha2: 'GL', alpha3: 'GRL', currency: { code: 'DKK', name: 'Danish Krone', symbol: 'kr' }, dialCode: '+299', key: 'Greenland', language: 'Greenlandic, Danish', name: 'Greenland' },
  { alpha2: 'GD', alpha3: 'GRD', currency: { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' }, dialCode: '+1473', key: 'Grenada', language: 'English', name: 'Grenada' },
  { alpha2: 'GP', alpha3: 'GLP', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+590', key: 'Guadeloupe', language: 'French', name: 'Guadeloupe' },
  { alpha2: 'GU', alpha3: 'GUM', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+1671', key: 'Guam', language: 'English, Chamorro', name: 'Guam' },
  { alpha2: 'GT', alpha3: 'GTM', currency: { code: 'GTQ', name: 'Guatemalan Quetzal', symbol: 'Q' }, dialCode: '+502', key: 'Guatemala', language: 'Spanish', name: 'Guatemala' },
  { alpha2: 'GG', alpha3: 'GGY', currency: { code: 'GBP', name: 'British Pound Sterling', symbol: '£' }, dialCode: '+44', key: 'Guernsey', language: 'English', name: 'Guernsey' },
  { alpha2: 'GN', alpha3: 'GIN', currency: { code: 'GNF', name: 'Guinean Franc', symbol: 'Fr' }, dialCode: '+224', key: 'Guinea', language: 'French', name: 'Guinea' },
  { alpha2: 'GW', alpha3: 'GNB', currency: { code: 'XOF', name: 'West African CFA Franc', symbol: 'Fr' }, dialCode: '+245', key: 'GuineaBissau', language: 'Portuguese', name: 'Guinea-Bissau' },
  { alpha2: 'GY', alpha3: 'GUY', currency: { code: 'GYD', name: 'Guyanaese Dollar', symbol: '$' }, dialCode: '+592', key: 'Guyana', language: 'English', name: 'Guyana' },
  { alpha2: 'HT', alpha3: 'HTI', currency: { code: 'HTG', name: 'Haitian Gourde', symbol: 'G' }, dialCode: '+509', key: 'Haiti', language: 'French, Haitian Creole', name: 'Haiti' },
  { alpha2: 'HM', alpha3: 'HMD', currency: { code: 'AUD', name: 'Australian Dollar', symbol: '$' }, dialCode: '+672', key: 'HeardIslandandMcDonaldIslands', language: 'English', name: 'Heard Island and McDonald Islands' },
  { alpha2: 'HN', alpha3: 'HND', currency: { code: 'HNL', name: 'Honduran Lempira', symbol: 'L' }, dialCode: '+504', key: 'Honduras', language: 'Spanish', name: 'Honduras' },
  { alpha2: 'HK', alpha3: 'HKG', currency: { code: 'HKD', name: 'Hong Kong Dollar', symbol: '$' }, dialCode: '+852', key: 'HongKong', language: 'Chinese, English', name: 'Hong Kong' },
  { alpha2: 'HU', alpha3: 'HUN', currency: { code: 'HUF', name: 'Hungarian Forint', symbol: 'Ft' }, dialCode: '+36', key: 'Hungary', language: 'Hungarian', name: 'Hungary' },
  { alpha2: 'IS', alpha3: 'ISL', currency: { code: 'ISK', name: 'Icelandic Krona', symbol: 'kr' }, dialCode: '+354', key: 'Iceland', language: 'Icelandic', name: 'Iceland' },
  { alpha2: 'IN', alpha3: 'IND', currency: { code: 'INR', name: 'Indian Rupee', symbol: '₹' }, dialCode: '+91', key: 'India', language: 'Hindi, English', name: 'India' },
  { alpha2: 'ID', alpha3: 'IDN', currency: { code: 'IDR', name: 'Indonesian Rupiah', symbol: 'Rp' }, dialCode: '+62', key: 'Indonesia', language: 'Indonesian', name: 'Indonesia' },
  { alpha2: 'IR', alpha3: 'IRN', currency: { code: 'IRR', name: 'Iranian Rial', symbol: '﷼' }, dialCode: '+98', key: 'Iran', language: 'Persian', name: 'Iran' },
  { alpha2: 'IQ', alpha3: 'IRQ', currency: { code: 'IQD', name: 'Iraqi Dinar', symbol: 'ع.د' }, dialCode: '+964', key: 'Iraq', language: 'Arabic, Kurdish', name: 'Iraq' },
  { alpha2: 'IE', alpha3: 'IRL', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+353', key: 'Ireland', language: 'Irish, English', name: 'Ireland' },
  { alpha2: 'IM', alpha3: 'IMN', currency: { code: 'GBP', name: 'British Pound Sterling', symbol: '£' }, dialCode: '+44', key: 'IsleofMan', language: 'English, Manx Gaelic', name: 'Isle of Man' },
  { alpha2: 'IL', alpha3: 'ISR', currency: { code: 'ILS', name: 'Israeli New Shekel', symbol: '₪' }, dialCode: '+972', key: 'Israel', language: 'Hebrew', name: 'Israel' },
  { alpha2: 'IT', alpha3: 'ITA', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+39', key: 'Italy', language: 'Italian', name: 'Italy' },
  { alpha2: 'CI', alpha3: 'CIV', currency: { code: 'XOF', name: 'West African CFA Franc', symbol: 'Fr' }, dialCode: '+225', key: 'IvoryCoast', language: 'French', name: 'Ivory Coast' },
  { alpha2: 'JM', alpha3: 'JAM', currency: { code: 'JMD', name: 'Jamaican Dollar', symbol: '$' }, dialCode: '+1876', key: 'Jamaica', language: 'English', name: 'Jamaica' },
  { alpha2: 'JP', alpha3: 'JPN', currency: { code: 'JPY', name: 'Japanese Yen', symbol: '¥' }, dialCode: '+81', key: 'Japan', language: 'Japanese', name: 'Japan' },
  { alpha2: 'JE', alpha3: 'JEY', currency: { code: 'GBP', name: 'British Pound Sterling', symbol: '£' }, dialCode: '+44', key: 'Jersey', language: 'English, French', name: 'Jersey' },
  { alpha2: 'JO', alpha3: 'JOR', currency: { code: 'JOD', name: 'Jordanian Dinar', symbol: 'JD' }, dialCode: '+962', key: 'Jordan', language: 'Arabic', name: 'Jordan' },
  { alpha2: 'KZ', alpha3: 'KAZ', currency: { code: 'KZT', name: 'Kazakhstani Tenge', symbol: '₸' }, dialCode: '+7', key: 'Kazakhstan', language: 'Kazakh, Russian', name: 'Kazakhstan' },
  { alpha2: 'KE', alpha3: 'KEN', currency: { code: 'KES', name: 'Kenyan Shilling', symbol: 'Sh' }, dialCode: '+254', key: 'Kenya', language: 'Swahili, English', name: 'Kenya' },
  { alpha2: 'KI', alpha3: 'KIR', currency: { code: 'AUD', name: 'Australian Dollar', symbol: '$' }, dialCode: '+686', key: 'Kiribati', language: 'English, Kiribati', name: 'Kiribati' },
  { alpha2: 'XK', alpha3: 'XKX', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+383', key: 'Kosovo', language: 'Albanian, Serbian', name: 'Kosovo' },
  { alpha2: 'KW', alpha3: 'KWT', currency: { code: 'KWD', name: 'Kuwaiti Dinar', symbol: 'د.ك' }, dialCode: '+965', key: 'Kuwait', language: 'Arabic', name: 'Kuwait' },
  { alpha2: 'KG', alpha3: 'KGZ', currency: { code: 'KGS', name: 'Kyrgyzstani Som', symbol: 'лв' }, dialCode: '+996', key: 'Kyrgyzstan', language: 'Kyrgyz, Russian', name: 'Kyrgyzstan' },
  { alpha2: 'LA', alpha3: 'LAO', currency: { code: 'LAK', name: 'Lao Kip', symbol: '₭' }, dialCode: '+856', key: 'Laos', language: 'Lao', name: 'Laos' },
  { alpha2: 'LV', alpha3: 'LVA', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+371', key: 'Latvia', language: 'Latvian', name: 'Latvia' },
  { alpha2: 'LB', alpha3: 'LBN', currency: { code: 'LBP', name: 'Lebanese Pound', symbol: 'ل.ل' }, dialCode: '+961', key: 'Lebanon', language: 'Arabic, French', name: 'Lebanon' },
  { alpha2: 'LS', alpha3: 'LSO', currency: { code: 'LSL', name: 'Lesotho Loti', symbol: 'L' }, dialCode: '+266', key: 'Lesotho', language: 'Sesotho, English', name: 'Lesotho' },
  { alpha2: 'LR', alpha3: 'LBR', currency: { code: 'LRD', name: 'Liberian Dollar', symbol: '$' }, dialCode: '+231', key: 'Liberia', language: 'English', name: 'Liberia' },
  { alpha2: 'LY', alpha3: 'LBY', currency: { code: 'LYD', name: 'Libyan Dinar', symbol: 'ل.د' }, dialCode: '+218', key: 'Libya', language: 'Arabic', name: 'Libya' },
  { alpha2: 'LI', alpha3: 'LIE', currency: { code: 'CHF', name: 'Swiss Franc', symbol: 'Fr' }, dialCode: '+423', key: 'Liechtenstein', language: 'German', name: 'Liechtenstein' },
  { alpha2: 'LT', alpha3: 'LTU', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+370', key: 'Lithuania', language: 'Lithuanian', name: 'Lithuania' },
  { alpha2: 'LU', alpha3: 'LUX', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+352', key: 'Luxembourg', language: 'Luxembourgish, French, German', name: 'Luxembourg' },
  { alpha2: 'MO', alpha3: 'MAC', currency: { code: 'MOP', name: 'Macanese Pataca', symbol: 'MOP$' }, dialCode: '+853', key: 'Macao', language: 'Chinese, Portuguese', name: 'Macao' },
  { alpha2: 'MG', alpha3: 'MDG', currency: { code: 'MGA', name: 'Malagasy Ariary', symbol: 'Ar' }, dialCode: '+261', key: 'Madagascar', language: 'French, Malagasy', name: 'Madagascar' },
  { alpha2: 'MW', alpha3: 'MWI', currency: { code: 'MWK', name: 'Malawian Kwacha', symbol: 'MK' }, dialCode: '+265', key: 'Malawi', language: 'English, Chichewa', name: 'Malawi' },
  { alpha2: 'MY', alpha3: 'MYS', currency: { code: 'MYR', name: 'Malaysian Ringgit', symbol: 'RM' }, dialCode: '+60', key: 'Malaysia', language: 'Malay', name: 'Malaysia' },
  { alpha2: 'MV', alpha3: 'MDV', currency: { code: 'MVR', name: 'Maldivian Rufiyaa', symbol: 'ރ.' }, dialCode: '+960', key: 'Maldives', language: 'Dhivehi', name: 'Maldives' },
  { alpha2: 'ML', alpha3: 'MLI', currency: { code: 'XOF', name: 'West African CFA Franc', symbol: 'Fr' }, dialCode: '+223', key: 'Mali', language: 'French', name: 'Mali' },
  { alpha2: 'MT', alpha3: 'MLT', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+356', key: 'Malta', language: 'Maltese, English', name: 'Malta' },
  { alpha2: 'MH', alpha3: 'MHL', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+692', key: 'MarshallIslands', language: 'English', name: 'Marshall Islands' },
  { alpha2: 'MQ', alpha3: 'MTQ', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+596', key: 'Martinique', language: 'French', name: 'Martinique' },
  { alpha2: 'MR', alpha3: 'MRT', currency: { code: 'MRU', name: 'Mauritanian Ouguiya', symbol: 'UM' }, dialCode: '+222', key: 'Mauritania', language: 'Arabic, French', name: 'Mauritania' },
  { alpha2: 'MU', alpha3: 'MUS', currency: { code: 'MUR', name: 'Mauritian Rupee', symbol: '₨' }, dialCode: '+230', key: 'Mauritius', language: 'English, French', name: 'Mauritius' },
  { alpha2: 'YT', alpha3: 'MYT', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+262', key: 'Mayotte', language: 'French', name: 'Mayotte' },
  { alpha2: 'MX', alpha3: 'MEX', currency: { code: 'MXN', name: 'Mexican Peso', symbol: '$' }, dialCode: '+52', key: 'Mexico', language: 'Spanish', name: 'Mexico' },
  { alpha2: 'FM', alpha3: 'FSM', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+691', key: 'Micronesia', language: 'English', name: 'Micronesia' },
  { alpha2: 'MD', alpha3: 'MDA', currency: { code: 'MDL', name: 'Moldovan Leu', symbol: 'L' }, dialCode: '+373', key: 'Moldova', language: 'Romanian', name: 'Moldova' },
  { alpha2: 'MC', alpha3: 'MCO', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+377', key: 'Monaco', language: 'French', name: 'Monaco' },
  { alpha2: 'MN', alpha3: 'MNG', currency: { code: 'MNT', name: 'Mongolian Tugrik', symbol: '₮' }, dialCode: '+976', key: 'Mongolia', language: 'Mongolian', name: 'Mongolia' },
  { alpha2: 'ME', alpha3: 'MNE', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+382', key: 'Montenegro', language: 'Serbian', name: 'Montenegro' },
  { alpha2: 'MS', alpha3: 'MSR', currency: { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' }, dialCode: '+1664', key: 'Montserrat', language: 'English', name: 'Montserrat' },
  { alpha2: 'MA', alpha3: 'MAR', currency: { code: 'MAD', name: 'Moroccan Dirham', symbol: 'د.م.' }, dialCode: '+212', key: 'Morocco', language: 'Arabic, Berber', name: 'Morocco' },
  { alpha2: 'MZ', alpha3: 'MOZ', currency: { code: 'MZN', name: 'Mozambican Metical', symbol: 'MT' }, dialCode: '+258', key: 'Mozambique', language: 'Portuguese', name: 'Mozambique' },
  { alpha2: 'MM', alpha3: 'MMR', currency: { code: 'MMK', name: 'Myanmar Kyat', symbol: 'Ks' }, dialCode: '+95', key: 'Myanmar', language: 'Burmese', name: 'Myanmar' },
  { alpha2: 'NA', alpha3: 'NAM', currency: { code: 'NAD', name: 'Namibian Dollar', symbol: '$' }, dialCode: '+264', key: 'Namibia', language: 'English', name: 'Namibia' },
  { alpha2: 'NR', alpha3: 'NRU', currency: { code: 'AUD', name: 'Australian Dollar', symbol: '$' }, dialCode: '+674', key: 'Nauru', language: 'Nauruan, English', name: 'Nauru' },
  { alpha2: 'NP', alpha3: 'NPL', currency: { code: 'NPR', name: 'Nepalese Rupee', symbol: '₨' }, dialCode: '+977', key: 'Nepal', language: 'Nepali', name: 'Nepal' },
  { alpha2: 'NL', alpha3: 'NLD', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+31', key: 'Netherlands', language: 'Dutch', name: 'Netherlands' },
  { alpha2: 'NC', alpha3: 'NCL', currency: { code: 'XPF', name: 'CFP Franc', symbol: 'Fr' }, dialCode: '+687', key: 'NewCaledonia', language: 'French', name: 'New Caledonia' },
  { alpha2: 'NZ', alpha3: 'NZL', currency: { code: 'NZD', name: 'New Zealand Dollar', symbol: '$' }, dialCode: '+64', key: 'NewZealand', language: 'English, Maori', name: 'New Zealand' },
  { alpha2: 'NI', alpha3: 'NIC', currency: { code: 'NIO', name: 'Nicaraguan Cordoba', symbol: 'C$' }, dialCode: '+505', key: 'Nicaragua', language: 'Spanish', name: 'Nicaragua' },
  { alpha2: 'NE', alpha3: 'NER', currency: { code: 'XOF', name: 'West African CFA Franc', symbol: 'Fr' }, dialCode: '+227', key: 'Niger', language: 'French', name: 'Niger' },
  { alpha2: 'NG', alpha3: 'NGA', currency: { code: 'NGN', name: 'Nigerian Naira', symbol: '₦' }, dialCode: '+234', key: 'Nigeria', language: 'English', name: 'Nigeria' },
  { alpha2: 'NU', alpha3: 'NIU', currency: { code: 'NZD', name: 'New Zealand Dollar', symbol: '$' }, dialCode: '+683', key: 'Niue', language: 'Niuean, English', name: 'Niue' },
  { alpha2: 'NF', alpha3: 'NFK', currency: { code: 'AUD', name: 'Australian Dollar', symbol: '$' }, dialCode: '+672', key: 'NorfolkIsland', language: 'English', name: 'Norfolk Island' },
  { alpha2: 'KP', alpha3: 'PRK', currency: { code: 'KPW', name: 'North Korean Won', symbol: '₩' }, dialCode: '+850', key: 'NorthKorea', language: 'Korean', name: 'North Korea' },
  { alpha2: 'MK', alpha3: 'MKD', currency: { code: 'MKD', name: 'Macedonian Denar', symbol: 'ден' }, dialCode: '+389', key: 'NorthMacedonia', language: 'Macedonian', name: 'North Macedonia' },
  { alpha2: 'MP', alpha3: 'MNP', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+1670', key: 'NorthernMarianaIslands', language: 'English, Chamorro', name: 'Northern Mariana Islands' },
  { alpha2: 'NO', alpha3: 'NOR', currency: { code: 'NOK', name: 'Norwegian Krone', symbol: 'kr' }, dialCode: '+47', key: 'Norway', language: 'Norwegian', name: 'Norway' },
  { alpha2: 'OM', alpha3: 'OMN', currency: { code: 'OMR', name: 'Omani Rial', symbol: 'ر.ع.' }, dialCode: '+968', key: 'Oman', language: 'Arabic', name: 'Oman' },
  { alpha2: 'PK', alpha3: 'PAK', currency: { code: 'PKR', name: 'Pakistani Rupee', symbol: '₨' }, dialCode: '+92', key: 'Pakistan', language: 'Urdu, English', name: 'Pakistan' },
  { alpha2: 'PW', alpha3: 'PLW', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+680', key: 'Palau', language: 'English', name: 'Palau' },
  { alpha2: 'PS', alpha3: 'PSE', currency: { code: 'ILS', name: 'Israeli New Shekel', symbol: '₪' }, dialCode: '+970', key: 'Palestine', language: 'Arabic', name: 'Palestine' },
  { alpha2: 'PA', alpha3: 'PAN', currency: { code: 'PAB', name: 'Panamanian Balboa', symbol: 'B/.' }, dialCode: '+507', key: 'Panama', language: 'Spanish', name: 'Panama' },
  { alpha2: 'PG', alpha3: 'PNG', currency: { code: 'PGK', name: 'Papua New Guinean Kina', symbol: 'K' }, dialCode: '+675', key: 'PapuaNewGuinea', language: 'English', name: 'Papua New Guinea' },
  { alpha2: 'PY', alpha3: 'PRY', currency: { code: 'PYG', name: 'Paraguayan Guarani', symbol: '₲' }, dialCode: '+595', key: 'Paraguay', language: 'Spanish, Guarani', name: 'Paraguay' },
  { alpha2: 'PE', alpha3: 'PER', currency: { code: 'PEN', name: 'Peruvian Sol', symbol: 'S/' }, dialCode: '+51', key: 'Peru', language: 'Spanish', name: 'Peru' },
  { alpha2: 'PH', alpha3: 'PHL', currency: { code: 'PHP', name: 'Philippine Peso', symbol: '₱' }, dialCode: '+63', key: 'Philippines', language: 'Filipino, English', name: 'Philippines' },
  { alpha2: 'PN', alpha3: 'PCN', currency: { code: 'NZD', name: 'New Zealand Dollar', symbol: '$' }, dialCode: '+64', key: 'PitcairnIslands', language: 'English', name: 'Pitcairn Islands' },
  { alpha2: 'PL', alpha3: 'POL', currency: { code: 'PLN', name: 'Polish Zloty', symbol: 'zł' }, dialCode: '+48', key: 'Poland', language: 'Polish', name: 'Poland' },
  { alpha2: 'PT', alpha3: 'PRT', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+351', key: 'Portugal', language: 'Portuguese', name: 'Portugal' },
  { alpha2: 'PR', alpha3: 'PRI', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+1', key: 'PuertoRico', language: 'Spanish, English', name: 'Puerto Rico' },
  { alpha2: 'QA', alpha3: 'QAT', currency: { code: 'QAR', name: 'Qatari Riyal', symbol: 'ر.ق' }, dialCode: '+974', key: 'Qatar', language: 'Arabic', name: 'Qatar' },
  { alpha2: 'CG', alpha3: 'COG', currency: { code: 'XAF', name: 'Central African CFA Franc', symbol: 'Fr' }, dialCode: '+242', key: 'RepublicoftheCongo', language: 'French', name: 'Republic of the Congo' },
  { alpha2: 'RE', alpha3: 'REU', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+262', key: 'Reunion', language: 'French', name: 'Réunion' },
  { alpha2: 'RO', alpha3: 'ROU', currency: { code: 'RON', name: 'Romanian Leu', symbol: 'lei' }, dialCode: '+40', key: 'Romania', language: 'Romanian', name: 'Romania' },
  { alpha2: 'RU', alpha3: 'RUS', currency: { code: 'RUB', name: 'Russian Ruble', symbol: '₽' }, dialCode: '+7', key: 'Russia', language: 'Russian', name: 'Russia' },
  { alpha2: 'RW', alpha3: 'RWA', currency: { code: 'RWF', name: 'Rwandan Franc', symbol: 'Fr' }, dialCode: '+250', key: 'Rwanda', language: 'Kinyarwanda, French, English', name: 'Rwanda' },
  { alpha2: 'BL', alpha3: 'BLM', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+590', key: 'SaintBarthelemy', language: 'French', name: 'Saint Barthélemy' },
  { alpha2: 'SH', alpha3: 'SHN', currency: { code: 'SHP', name: 'Saint Helena Pound', symbol: '£' }, dialCode: '+290', key: 'SaintHelena', language: 'English', name: 'Saint Helena' },
  { alpha2: 'KN', alpha3: 'KNA', currency: { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' }, dialCode: '+1869', key: 'SaintKittsandNevis', language: 'English', name: 'Saint Kitts and Nevis' },
  { alpha2: 'LC', alpha3: 'LCA', currency: { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' }, dialCode: '+1758', key: 'SaintLucia', language: 'English', name: 'Saint Lucia' },
  { alpha2: 'MF', alpha3: 'MAF', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+590', key: 'SaintMartin', language: 'English, French, Dutch', name: 'Saint Martin' },
  { alpha2: 'PM', alpha3: 'SPM', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+508', key: 'SaintPierreandMiquelon', language: 'French', name: 'Saint Pierre and Miquelon' },
  { alpha2: 'VC', alpha3: 'VCT', currency: { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' }, dialCode: '+1784', key: 'SaintVincentandtheGrenadines', language: 'English', name: 'Saint Vincent and the Grenadines' },
  { alpha2: 'WS', alpha3: 'WSM', currency: { code: 'WST', name: 'Samoan Tala', symbol: 'T' }, dialCode: '+685', key: 'Samoa', language: 'Samoan, English', name: 'Samoa' },
  { alpha2: 'SM', alpha3: 'SMR', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+378', key: 'SanMarino', language: 'Italian', name: 'San Marino' },
  { alpha2: 'ST', alpha3: 'STP', currency: { code: 'STN', name: 'São Tomé and Príncipe Dobra', symbol: 'Db' }, dialCode: '+239', key: 'SaoTomeandPrincipe', language: 'Portuguese', name: 'São Tomé and Príncipe' },
  { alpha2: 'SA', alpha3: 'SAU', currency: { code: 'SAR', name: 'Saudi Riyal', symbol: 'ر.س' }, dialCode: '+966', key: 'SaudiArabia', language: 'Arabic', name: 'Saudi Arabia' },
  { alpha2: 'SN', alpha3: 'SEN', currency: { code: 'XOF', name: 'West African CFA Franc', symbol: 'Fr' }, dialCode: '+221', key: 'Senegal', language: 'French', name: 'Senegal' },
  { alpha2: 'RS', alpha3: 'SRB', currency: { code: 'RSD', name: 'Serbian Dinar', symbol: 'дин.' }, dialCode: '+381', key: 'Serbia', language: 'Serbian', name: 'Serbia' },
  { alpha2: 'SC', alpha3: 'SYC', currency: { code: 'SCR', name: 'Seychellois Rupee', symbol: '₨' }, dialCode: '+248', key: 'Seychelles', language: 'Seychellois Creole, English, French', name: 'Seychelles' },
  { alpha2: 'SL', alpha3: 'SLE', currency: { code: 'SLL', name: 'Sierra Leonean Leone', symbol: 'Le' }, dialCode: '+232', key: 'SierraLeone', language: 'English', name: 'Sierra Leone' },
  { alpha2: 'SG', alpha3: 'SGP', currency: { code: 'SGD', name: 'Singapore Dollar', symbol: '$' }, dialCode: '+65', key: 'Singapore', language: 'English, Malay, Mandarin, Tamil', name: 'Singapore' },
  { alpha2: 'SX', alpha3: 'SXM', currency: { code: 'ANG', name: 'Netherlands Antillean Guilder', symbol: 'ƒ' }, dialCode: '+1721', key: 'SintMaarten', language: 'Dutch, English', name: 'Sint Maarten' },
  { alpha2: 'SK', alpha3: 'SVK', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+421', key: 'Slovakia', language: 'Slovak', name: 'Slovakia' },
  { alpha2: 'SI', alpha3: 'SVN', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+386', key: 'Slovenia', language: 'Slovenian', name: 'Slovenia' },
  { alpha2: 'SB', alpha3: 'SLB', currency: { code: 'SBD', name: 'Solomon Islands Dollar', symbol: '$' }, dialCode: '+677', key: 'SolomonIslands', language: 'English', name: 'Solomon Islands' },
  { alpha2: 'SO', alpha3: 'SOM', currency: { code: 'SOS', name: 'Somali Shilling', symbol: 'Sh' }, dialCode: '+252', key: 'Somalia', language: 'Somali, Arabic', name: 'Somalia' },
  { alpha2: 'ZA', alpha3: 'ZAF', currency: { code: 'ZAR', name: 'South African Rand', symbol: 'R' }, dialCode: '+27', key: 'SouthAfrica', language: 'Afrikaans, English', name: 'South Africa' },
  { alpha2: 'KR', alpha3: 'KOR', currency: { code: 'KRW', name: 'South Korean Won', symbol: '₩' }, dialCode: '+82', key: 'SouthKorea', language: 'Korean', name: 'South Korea' },
  { alpha2: 'SS', alpha3: 'SSD', currency: { code: 'SSP', name: 'South Sudanese Pound', symbol: '£' }, dialCode: '+211', key: 'SouthSudan', language: 'English', name: 'South Sudan' },
  { alpha2: 'ES', alpha3: 'ESP', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+34', key: 'Spain', language: 'Spanish', name: 'Spain' },
  { alpha2: 'LK', alpha3: 'LKA', currency: { code: 'LKR', name: 'Sri Lankan Rupee', symbol: 'Rs' }, dialCode: '+94', key: 'SriLanka', language: 'Sinhala, Tamil', name: 'Sri Lanka' },
  { alpha2: 'SD', alpha3: 'SDN', currency: { code: 'SDG', name: 'Sudanese Pound', symbol: '£' }, dialCode: '+249', key: 'Sudan', language: 'Arabic, English', name: 'Sudan' },
  { alpha2: 'SR', alpha3: 'SUR', currency: { code: 'SRD', name: 'Surinamese Dollar', symbol: '$' }, dialCode: '+597', key: 'Suriname', language: 'Dutch', name: 'Suriname' },
  { alpha2: 'SJ', alpha3: 'SJM', currency: { code: 'NOK', name: 'Norwegian Krone', symbol: 'kr' }, dialCode: '+47', key: 'SvalbardandJanMayen', language: 'Norwegian', name: 'Svalbard and Jan Mayen' },
  { alpha2: 'SE', alpha3: 'SWE', currency: { code: 'SEK', name: 'Swedish Krona', symbol: 'kr' }, dialCode: '+46', key: 'Sweden', language: 'Swedish', name: 'Sweden' },
  { alpha2: 'CH', alpha3: 'CHE', currency: { code: 'CHF', name: 'Swiss Franc', symbol: 'Fr' }, dialCode: '+41', key: 'Switzerland', language: 'German, French, Italian, Romansh', name: 'Switzerland' },
  { alpha2: 'SY', alpha3: 'SYR', currency: { code: 'SYP', name: 'Syrian Pound', symbol: '£' }, dialCode: '+963', key: 'Syria', language: 'Arabic', name: 'Syria' },
  { alpha2: 'TW', alpha3: 'TWN', currency: { code: 'TWD', name: 'New Taiwan Dollar', symbol: 'NT$' }, dialCode: '+886', key: 'Taiwan', language: 'Mandarin', name: 'Taiwan' },
  { alpha2: 'TJ', alpha3: 'TJK', currency: { code: 'TJS', name: 'Tajikistani Somoni', symbol: 'ЅМ' }, dialCode: '+992', key: 'Tajikistan', language: 'Tajik', name: 'Tajikistan' },
  { alpha2: 'TZ', alpha3: 'TZA', currency: { code: 'TZS', name: 'Tanzanian Shilling', symbol: 'Sh' }, dialCode: '+255', key: 'Tanzania', language: 'Swahili, English', name: 'Tanzania' },
  { alpha2: 'TH', alpha3: 'THA', currency: { code: 'THB', name: 'Thai Baht', symbol: '฿' }, dialCode: '+66', key: 'Thailand', language: 'Thai', name: 'Thailand' },
  { alpha2: 'TL', alpha3: 'TLS', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+670', key: 'TimorLeste', language: 'Portuguese, Tetum', name: 'Timor-Leste' },
  { alpha2: 'TG', alpha3: 'TGO', currency: { code: 'XOF', name: 'West African CFA Franc', symbol: 'Fr' }, dialCode: '+228', key: 'Togo', language: 'French', name: 'Togo' },
  { alpha2: 'TK', alpha3: 'TKL', currency: { code: 'NZD', name: 'New Zealand Dollar', symbol: '$' }, dialCode: '+690', key: 'Tokelau', language: 'Tokelauan, English', name: 'Tokelau' },
  { alpha2: 'TO', alpha3: 'TON', currency: { code: 'TOP', name: 'Tongan Paʻanga', symbol: 'T$' }, dialCode: '+676', key: 'Tonga', language: 'Tongan, English', name: 'Tonga' },
  { alpha2: 'TT', alpha3: 'TTO', currency: { code: 'TTD', name: 'Trinidad and Tobago Dollar', symbol: '$' }, dialCode: '+1868', key: 'TrinidadandTobago', language: 'English', name: 'Trinidad and Tobago' },
  { alpha2: 'TN', alpha3: 'TUN', currency: { code: 'TND', name: 'Tunisian Dinar', symbol: 'د.ت' }, dialCode: '+216', key: 'Tunisia', language: 'Arabic', name: 'Tunisia' },
  { alpha2: 'TR', alpha3: 'TUR', currency: { code: 'TRY', name: 'Turkish Lira', symbol: '₺' }, dialCode: '+90', key: 'Turkey', language: 'Turkish', name: 'Turkey' },
  { alpha2: 'TM', alpha3: 'TKM', currency: { code: 'TMT', name: 'Turkmenistan Manat', symbol: 'T' }, dialCode: '+993', key: 'Turkmenistan', language: 'Turkmen', name: 'Turkmenistan' },
  { alpha2: 'TC', alpha3: 'TCA', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+1649', key: 'TurksandCaicosIslands', language: 'English', name: 'Turks and Caicos Islands' },
  { alpha2: 'TV', alpha3: 'TUV', currency: { code: 'AUD', name: 'Australian Dollar', symbol: '$' }, dialCode: '+688', key: 'Tuvalu', language: 'Tuvaluan, English', name: 'Tuvalu' },
  { alpha2: 'VI', alpha3: 'VIR', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+1340', key: 'USVirginIslands', language: 'English', name: 'U.S. Virgin Islands' },
  { alpha2: 'UG', alpha3: 'UGA', currency: { code: 'UGX', name: 'Ugandan Shilling', symbol: 'Sh' }, dialCode: '+256', key: 'Uganda', language: 'English, Swahili', name: 'Uganda' },
  { alpha2: 'UA', alpha3: 'UKR', currency: { code: 'UAH', name: 'Ukrainian Hryvnia', symbol: '₴' }, dialCode: '+380', key: 'Ukraine', language: 'Ukrainian', name: 'Ukraine' },
  { alpha2: 'AE', alpha3: 'ARE', currency: { code: 'AED', name: 'United Arab Emirates Dirham', symbol: 'د.إ' }, dialCode: '+971', key: 'UnitedArabEmirates', language: 'Arabic', name: 'United Arab Emirates' },
  { alpha2: 'GB', alpha3: 'GBR', currency: { code: 'GBP', name: 'British Pound Sterling', symbol: '£' }, dialCode: '+44', key: 'UnitedKingdom', language: 'English', name: 'United Kingdom' },
  { alpha2: 'GB', alpha3: 'GBR', currency: { code: 'GBP', name: 'British Pound', symbol: '£' }, dialCode: '+44', key: 'England', language: 'English', name: 'England' },
  { alpha2: 'US', alpha3: 'USA', currency: { code: 'USD', name: 'US Dollar', symbol: '$' }, dialCode: '+1', key: 'UnitedStates', language: 'English', name: 'United States' },
  { alpha2: 'UY', alpha3: 'URY', currency: { code: 'UYU', name: 'Uruguayan Peso', symbol: '$' }, dialCode: '+598', key: 'Uruguay', language: 'Spanish', name: 'Uruguay' },
  { alpha2: 'UZ', alpha3: 'UZB', currency: { code: 'UZS', name: 'Uzbekistani Som', symbol: 'лв' }, dialCode: '+998', key: 'Uzbekistan', language: 'Uzbek', name: 'Uzbekistan' },
  { alpha2: 'VU', alpha3: 'VUT', currency: { code: 'VUV', name: 'Vanuatu Vatu', symbol: 'Vt' }, dialCode: '+678', key: 'Vanuatu', language: 'Bislama, English, French', name: 'Vanuatu' },
  { alpha2: 'VA', alpha3: 'VAT', currency: { code: 'EUR', name: 'Euro', symbol: '€' }, dialCode: '+379', key: 'VaticanCity', language: 'Italian', name: 'Vatican City' },
  { alpha2: 'VE', alpha3: 'VEN', currency: { code: 'VES', name: 'Venezuelan Bolívar', symbol: 'Bs.S.' }, dialCode: '+58', key: 'Venezuela', language: 'Spanish', name: 'Venezuela' },
  { alpha2: 'VN', alpha3: 'VNM', currency: { code: 'VND', name: 'Vietnamese Dong', symbol: '₫' }, dialCode: '+84', key: 'Vietnam', language: 'Vietnamese', name: 'Vietnam' },
  { alpha2: 'WF', alpha3: 'WLF', currency: { code: 'XPF', name: 'CFP Franc', symbol: 'Fr' }, dialCode: '+681', key: 'WallisandFutuna', language: 'French', name: 'Wallis and Futuna' },
  { alpha2: 'EH', alpha3: 'ESH', currency: { code: 'MAD', name: 'Moroccan Dirham', symbol: 'د.م.' }, dialCode: '+212', key: 'WesternSahara', language: 'Arabic', name: 'Western Sahara' },
  { alpha2: 'YE', alpha3: 'YEM', currency: { code: 'YER', name: 'Yemeni Rial', symbol: '﷼' }, dialCode: '+967', key: 'Yemen', language: 'Arabic', name: 'Yemen' },
  { alpha2: 'ZM', alpha3: 'ZMB', currency: { code: 'ZMW', name: 'Zambian Kwacha', symbol: 'ZK' }, dialCode: '+260', key: 'Zambia', language: 'English', name: 'Zambia' },
  { alpha2: 'ZW', alpha3: 'ZWE', currency: { code: 'ZWL', name: 'Zimbabwean Dollar', symbol: 'Z$' }, dialCode: '+263', key: 'Zimbabwe', language: 'English, Shona, Ndebele', name: 'Zimbabwe' },
  { alpha2: 'GB-SCT', alpha3: 'SCT', currency: { code: 'GBP', name: 'British Pound Sterling', symbol: '£' }, dialCode: '+44', key: 'Scotland', language: 'English, Scottish Gaelic', name: 'Scotland' },
  { alpha2: 'GB-WLS', alpha3: 'WLS', currency: { code: 'GBP', name: 'British Pound Sterling', symbol: '£' }, dialCode: '+44', key: 'Wales', language: 'English, Welsh', name: 'Wales' },
  { alpha2: 'GB-NIR', alpha3: 'NIR', currency: { code: 'GBP', name: 'British Pound Sterling', symbol: '£' }, dialCode: '+44', key: 'NorthernIreland', language: 'English', name: 'Northern Ireland' }

];
