import { IFeature } from '@vision/core';
import { Accordion, Button, Icon, IconName, Tab, Tabs, Typography } from 'components/Core';
import Markdown from 'components/Core/Markdown/Markdown';
import { useAppLanguage, useAppTheme, useTranslations } from 'hooks';
import { SliceType } from 'models/store';
import FeaturePage from 'pages/_Misc/Features/FeaturePage';
import { getFeatureDetails } from 'pages/_Misc/Features/Features.utils';
import React, { useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { timeAgoString } from 'utils/datetime';

interface FeatureGuideProps {
  value: IconName | SliceType;
  name: string;
  uiStart?: React.ReactElement;
  faqs?: { question: string, answer: string }[];
  videos?: { name: string, info?: string, url: string }[];
  docs?: string;
  onCreate?: () => void;
  hideGetStarted?: boolean;
  recentItems?: any[],
  onClickItem?: (item: any) => void,
  tutorialGif?: string | any;
  hasFeaturePage?: boolean,
}

export const FeatureGuide: React.FC<FeatureGuideProps> = ({
  value,
  name,
  uiStart,
  faqs,
  docs,
  videos,
  onCreate,
  onClickItem,
  hideGetStarted,
  recentItems,
  tutorialGif,
  hasFeaturePage = true,
}) => {
  const { theme } = useAppTheme();
  const { language } = useAppLanguage();
  const { t } = useTranslations();
  const navigate = useNavigate();

  const [isInitialised, setIsInitialised] = useState<boolean>(false);

  const emptyTabData: (Tab & { key: string })[] = [];

  const height = 'calc(100vh - 8rem)';



  if (!hideGetStarted) {
    emptyTabData.push({
      id: 0,
      key: 'get-started',
      label: (
        <div className="flex flex-row items-center">
          <FontAwesome name="plus-circle" />
          <div className="mx-4">{t.common.getStarted}</div>
        </div>
      ),
      view: (
        <div>
          <div className="w-full h-full overflow-y-auto flex flex-col items-center justify-center pb-16">
            <br />
            <Icon
              key={value}
              // @ts-ignore
              name={value}
              color={theme?.TEXT}
              size={56}
            />

            <div>
              {recentItems && recentItems?.length > 0 ? (
                <div className="w-full mt-4 md:mt-8 flex flex-col justify-center">
                  <Typography size="xl" align="center" className='mx-auto' weight='600'>
                    {/* @ts-ignore */}
                    {t.common.recentItems(t.features[value])}
                  </Typography>
                  <hr className="my-4 md:my-4 border-layout-border" />
                  <div className="flex flex-col lg:flex-row">
                    {recentItems?.map((item: any) => (
                      <div key={item?.id} className={' m-2 p-4 w-80 md:w-72 cursor-pointer border border-opacity-50 bg-transparent hover:bg-primary hover:bg-opacity-20 rounded-lg flex flex-row items-center'}
                        style={{
                          borderColor: theme?.PRIMARY,
                        }}
                        onClick={() => onClickItem?.(item)}>
                        {item?.images ? (
                          <img
                            src={item?.images?.[0]}
                            className="w-16 h-16 max-h-16 max-w-16 rounded-full"
                            alt={item?.name}
                          />
                        ) : (
                          <div className="self-start">
                            <Icon
                              size={24}
                              color={theme?.TEXT}
                                name={value as IconName}
                            />
                          </div>
                        )}
                        <div className="ml-2 h-full flex flex-col justify-between">
                          <div>
                            <Typography weight="600" size="md" className="">{item.name}</Typography>
                            {item?.galleryId && (
                              <>
                                <br />
                                <Typography
                                  color={theme?.TEXT_LIGHT}
                                  size="sm"
                                  className="mt-2 flex flex-row">
                                  <Icon
                                    name="folder"
                                    imageClass='mt-0.5'
                                    size={14}
                                    color={theme?.TEXT_LIGHT}
                                  />&nbsp;{item.galleryId}
                                </Typography>
                              </>
                            )}
                          </div>
                          <Typography color={theme?.TEXT_LIGHT} size={'sm'}>
                            updated {timeAgoString(item.dateUpdated)}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr className="mx-0 my-4 lg:my-4 border-layout-border border-b w-full opacity-50" />
                </div>
              ) : (
                null
              )}
            </div>
            <div className="flex flex-row items-center">
              {/* @ts-ignore */}
              <Typography size="xl" weight="600">{t.common.createItem(t.features[value])}</Typography>
            </div>
            <br />

            <Typography align="center" size="2xl" className='px-2'>
              <p className="py-2">
                {/* @ts-ignore */}
                {t?.components?.featureGuideWelcomeP1(t.features[value])}
              </p>
              <p className="py-2">
                {/* @ts-ignore */}
                {t?.components?.featureGuideWelcomeP2(t.features[value])}
              </p>
              <p className="py-2">
                {/* @ts-ignore */}
                {t?.components?.featureGuideWelcomeP3(t.features[value])}
              </p>
            </Typography>
            <br />
            <br />
            <Button backgroundColor={theme?.PRIMARY} onClick={() => onCreate?.()}
              className="w-20 h-20 rounded-full flex items-center justify-center">
              <FontAwesome name="plus" style={{ fontSize: 20 }} />
            </Button>

            <br />
            <div className='w-full px-8'>
              <hr className='w-full my-4' />
            </div>
            <br />

            {hasFeaturePage ? (
              <FeaturePage
                feature={
                  getFeatureDetails({ key: value } as IFeature, language, t)
                }
                showFeatureButton={false}
              />
            ) : tutorialGif ? <img
              src={tutorialGif}
              alt="tutorial-gif"
                className="rounded-none"
            /> : null}

            <br />
            <br />
          </div>
          {uiStart}
        </div>
      ),
    });
  }

  if (!!docs && docs?.length > 0) {
    emptyTabData.push({
      id: 1,
      key: 'docs',
      label: (
        <div className="flex flex-row items-center">
          <FontAwesome name={'book'} />
          <div className="mx-4">{t.features.documents}</div>
        </div>
      ),
      view: (
        <div
          className="page-content overflow-y-auto pb-20 px-2 no-scrollbar"
          style={{
            height,
          }}>
          <Markdown content={docs as string} />
        </div>
      ),
    });
  }

  if (!!faqs && faqs?.length > 0) {
    emptyTabData.push({
      id: 2,
      key: 'faqs',
      label: (
        <div className="flex flex-row items-center">
          <FontAwesome name="question-circle" />
          <div className="mx-4">{t.common.faqs}</div>
        </div>
      ),
      view: (
        <div
          className="page-content pt-4 overflow-y-auto pb-12 no-scrollbar"
          style={{
            height
          }}
        >
          <div className="w-full mb-4">
            <Typography size="xl" weight="600">{t.common.faqs}</Typography>
          </div>
          <br />
          {
            faqs?.map((faq: { question: string, answer: string }) => (
              <div key={faq.question}>
                <Accordion title={faq.question} openIcon='minus' closedIcon='plus'>
                  <div className="pb-12">
                    <Typography color={theme?.TEXT_LIGHT}>
                      {faq.answer}
                    </Typography>
                  </div>
                </Accordion>
                <hr />
              </div>
            ))}
        </div>
      ),
    });
  }

  if (!!videos && videos?.length > 0) {
    emptyTabData.push({
      id: 3,
      key: 'videos',
      label: (
        <div className="flex flex-row items-center">
          <FontAwesome name="videos" />
          <div className="mx-4">{'Videos'}</div>
        </div>
      ),
      view: (
        <div
          className="page-content overflow-y-auto pb-12 no-scrollbar"
          style={{
            height
          }}>
          {videos?.map((video: {
            name: string,
            info?: string,
            url: string
          }) => (
            <div key={`${video.name}-${video.url}`} className="w-full flex flex-col items-center">
              <iframe
                width="560" height="315"
                src={video.url} title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="my-4" />
            </div>
          ))}
        </div>
      )
    });
  }

  useEffect(() => {
    if (!isInitialised) {
      setIsInitialised(true);
    }

    return () => setIsInitialised(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${isInitialised ? 'opacity-100' : 'opacity-0'} transition-all delay-100 duration-1000 ease-in-out`}>
      <Tabs data={emptyTabData} />
    </div>
  );
};