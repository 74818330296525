/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IFeature } from '@vision/core';
import { IProjectResource } from '@vision/core/lib/models/resource';
import imgGifCharacters from 'assets/gifs/vision-tut-characters.gif';
import imgGifControls from 'assets/gifs/vision-tut-controls.gif';
import imgGifDialogues from 'assets/gifs/vision-tut-dialogues.gif';
import imgGifDocuments from 'assets/gifs/vision-tut-docs.gif';
import imgGifGraphs from 'assets/gifs/vision-tut-graphs.gif';
import imgGifLocations from 'assets/gifs/vision-tut-locations.gif';
import imgGifProjects from 'assets/gifs/vision-tut-projects.gif';
import imgGifWorkspaces from 'assets/gifs/vision-tut-workspaces.gif';
import { ITranslation, SupportedLanguages } from 'data/translations';
import { SliceType } from 'models/store';
import { getCharacterName } from 'pages/Features/Characters/Characters.utils';


export const getItemName = (type: SliceType) => (item: IProjectResource): string | null => {
  switch (type) {
  case 'characters':
    return getCharacterName(item) as string;
  case 'creatures':
  default:
    return item?.name;
  }
};

export const getItemImage = (type: SliceType) => (item: IProjectResource): string | null => {
  switch (type) {
  case 'characters':
  case 'creatures':
  case 'locations':
  case 'objects':
  case 'transports':
    // @ts-ignore
    return item?.images?.[0] || null;
  default:
    return null;
  }
};

export interface IFeatureDetails {
  images?: string[],
  gif?: any,
  key?: string,
}

export interface IFeatureCopy {
  key: string,
  description: {
    [key in SupportedLanguages]: string
  },
  details: {
    title: {
      [key in SupportedLanguages]: string
    },
    content: {
      [key in SupportedLanguages]: string
    },
  }[],
  examples?: {
    title: {
      [key in SupportedLanguages]: string
    },
    content: {
      [key in SupportedLanguages]: string
    },
  }[],
}

export interface IFeatureCopyTranslated {
  key: string,
  description: string,
  details: {
    title: string,
    content: string
  }[],
  examples?: {
    title: string,
    content: string
  }[],
}

const mapFeatureDetails =
  (details: (IFeatureDetails & IFeatureCopyTranslated)[]):
    (IFeatureDetails & IFeatureCopyTranslated)[] => details?.map?.((fD) => ({
    ...fD,
    description: fD.description,
    details: fD.details?.map?.((detail) => ({
      content: detail?.content,
      title: detail?.title,
    })),
    examples: fD?.examples
      ? fD?.examples?.map?.((example) => ({
        content: example?.content,
        title: example?.title,
      }))
      : undefined,
  }));

export const getFeatureDetails = (feature: IFeature, lang: SupportedLanguages, t: ITranslation): IFeature & IFeatureDetails & any => {
  const featuresCopyWithAllLanguages: (IFeatureDetails & IFeatureCopyTranslated)[] = featureDetails?.map?.((arg: IFeatureDetails) => (
    {
      ...arg,
      // @ts-ignore
      ...(t[arg.key || '']?.featurePage)
    }
  ));

  const mappedDetails = mapFeatureDetails(featuresCopyWithAllLanguages);

  const details: IFeatureDetails = (
    mappedDetails?.find((fD) => fD?.key === feature?.key)
    ||
    {
      description: feature?.message,
      details: [],
      examples: [],
      images: [],
      key: feature?.key
    }
  ) as unknown as IFeatureDetails;


  return {
    ...feature,
    ...details,
  };
};

export const featureDetails: (IFeatureDetails)[] = [
  {
    gif: imgGifWorkspaces,
    images: [],
    key: 'workspaces'
  },
  {
    gif: imgGifProjects,
    images: [],
    key: 'projects'
  },
  {
    gif: imgGifCharacters,
    images: [
      'https://i.pinimg.com/736x/01/71/38/0171381ef6e4638265d87569416ff9e0.jpg',
      'https://i.pinimg.com/736x/11/bf/69/11bf69f67543748feefef1fc5dc44ceb.jpg',
      'https://i.pinimg.com/564x/36/84/75/3684750de246859b17e07e628a78a01c.jpg',
      'https://i.pinimg.com/564x/90/98/2e/90982ebd921622fc1a527f37d48250ed.jpg',
      'https://i.pinimg.com/736x/5f/2f/8f/5f2f8f152b1b157e8575f31237366ecd.jpg',
      'https://i.pinimg.com/736x/49/1a/f9/491af9a14e8ceaa6ee63c50c7893054b.jpg',
      'https://i.pinimg.com/564x/e4/08/d0/e408d0adf3ebd80900a9b9afe8290339.jpg',
      'https://i.pinimg.com/736x/8a/a7/75/8aa7756f5789bfc2f23848b5cb88ffd8.jpg',
      // 'https://i.pinimg.com/564x/10/99/1d/10991df19c3d0f4c6041456eb65ae171.jpg',
      'https://i.pinimg.com/564x/ce/d6/f0/ced6f0bc03e6997780ae54b967a14fe5.jpg',
      'https://i.pinimg.com/564x/33/43/f4/3343f4feb77455b787da7fa86cd3236f.jpg',
      'https://i.pinimg.com/564x/f4/7e/62/f47e627039e301124cf92c13601a8114.jpg',
      'https://i.pinimg.com/736x/c7/74/c7/c774c7d687b96ff4f83a2a68865da053.jpg',
      'https://i.pinimg.com/564x/7f/19/5d/7f195df710acda6e8e2f97d826d7ae17.jpg',
      'https://i.pinimg.com/564x/82/e1/47/82e147e0a85af31f114fa6ea5985a8db.jpg',
      'https://i.pinimg.com/736x/c8/f5/c8/c8f5c8e80decdff319d39767f1159982.jpg',
      'https://i.pinimg.com/564x/16/9a/18/169a1881c568449fed8182011553ff38.jpg',
      'https://i.pinimg.com/564x/04/ec/83/04ec83783918ae9ab7ebf45abc09491e.jpg',
      //
      'https://i.pinimg.com/564x/44/17/53/4417530b570a994664bd89278c46a3c3.jpg',
      'https://i.pinimg.com/736x/05/82/56/058256832d879ce75da6450ea7d79dbb.jpg',
      'https://i.pinimg.com/564x/7b/68/ae/7b68aec9e4779136c5fb8a88506dccbf.jpg',
      'https://i.pinimg.com/736x/f5/a7/94/f5a7946a73f2d64b660f70003a2d5639.jpg',
      'https://i.pinimg.com/736x/18/7b/26/187b26805d96fff989119ceac5b504b1.jpg',
      'https://i.pinimg.com/564x/e2/40/f0/e240f0bb09429cae63ead98082ab344f.jpg',
    ],
    key: 'characters'
  },
  {
    images: [
      'https://i.pinimg.com/564x/68/a3/cc/68a3ccb4b8e3fef6e981fdbe45669731.jpg',
      'https://i.pinimg.com/564x/8c/a2/54/8ca254debee9fe6759e0f4afe88cbde3.jpg',
      'https://i.pinimg.com/564x/81/4a/04/814a04997e3e911c058f3e634178e6c9.jpg',
      'https://i.pinimg.com/564x/93/a7/90/93a7901b0949f1505317bc6381787918.jpg',
      'https://i.pinimg.com/474x/8e/74/30/8e7430c3dd5e91619ebe79a522fbe70f.jpg',
      'https://i.pinimg.com/564x/fd/1b/e1/fd1be1a67303fca233fa229fcfc1857f.jpg',
      'https://i.pinimg.com/564x/42/67/f3/4267f314444a12137cfa5cf9813f89a6.jpg',
      'https://i.pinimg.com/564x/d0/2c/d0/d02cd0c6e3a28be2443bf20c0953fa4b.jpg',
      'https://i.pinimg.com/564x/b7/23/a2/b723a269d1486829a7470a976692240e.jpg',
      'https://i.pinimg.com/564x/d6/86/51/d686513d42d4cf72c0497b4fe751a5d2.jpg',
      'https://i.pinimg.com/564x/29/83/cb/2983cbc20352ab54f2e4209089ca07cb.jpg',
      'https://i.pinimg.com/564x/2b/0d/d1/2b0dd1a56c83d3f97792b95ffa5f86aa.jpg',
      'https://i.pinimg.com/564x/77/53/c7/7753c793b9a554b674cd64b4049a04fa.jpg',
      'https://i.pinimg.com/564x/35/87/b9/3587b902878103fa79ecf923f794014d.jpg',
      'https://i.pinimg.com/564x/f1/50/66/f150666f372123c53440c12a45271209.jpg',
      'https://i.pinimg.com/564x/74/56/24/745624041bfd6f2414c0699ac39acc71.jpg',
    ],
    key: 'creatures'
  },
  {
    gif: null,
    images: [
      'https://i.pinimg.com/564x/fc/f5/fa/fcf5fa411a89ed69cc36d9ec5b2db522.jpg',
      'https://i.pinimg.com/564x/ef/9e/52/ef9e527aa9e46c3768478028698483da.jpg',
      'https://i.pinimg.com/736x/7c/28/2b/7c282b6be26964cfc9d0f8032ea253c6.jpg',
      'https://i.pinimg.com/564x/4f/9f/67/4f9f678fa166b6acdb4adc18c8c4b9fd.jpg',
      'https://i.pinimg.com/564x/60/bd/f6/60bdf6f7e8d520b5036b4ca9330e3f4e.jpg',
      'https://i.pinimg.com/564x/a4/db/71/a4db71a51ad894721466b70e3fc3da61.jpg',
      'https://i.pinimg.com/736x/18/7d/85/187d85c391ab47f4ba1f357138fb7630.jpg',
      'https://i.pinimg.com/736x/6d/db/fe/6ddbfe4a46857b54db6925701d0e00f0.jpg',
      'https://i.pinimg.com/736x/62/7a/cc/627accca8802e5fe91534d62b2a30901.jpg',
      'https://i.pinimg.com/564x/46/24/71/4624718577226a1bb5215e39cfc7786b.jpg',
      'https://i.pinimg.com/736x/57/1b/af/571baf500988c9aada13a38c23905cec.jpg',
      'https://i.pinimg.com/564x/9d/f1/1f/9df11f0b36947b98b1baa75b488ae994.jpg',
      'https://i.pinimg.com/564x/fc/00/62/fc0062d255c79f230a98f7cf50c82c40.jpg',
      'https://i.pinimg.com/564x/20/d3/44/20d344777109577314d0b1039514324f.jpg',
      'https://i.pinimg.com/736x/86/2e/24/862e24ae342244ed00c1321103745b45.jpg'
    ],
    key: 'transports'
  },
  {
    images: [
      'https://i.pinimg.com/736x/19/00/c8/1900c8c2a67bf737ae4f826da058c2af.jpg',
      'https://i.pinimg.com/736x/70/e6/c5/70e6c593a1e048889714d921f7b30845.jpg',
      'https://i.pinimg.com/564x/a6/12/8e/a6128e5057c1a0984094263ba5f8796b.jpg',
      'https://i.pinimg.com/564x/08/d4/fd/08d4fd2c9d41feb0965f2cff8daaecda.jpg',
      'https://i.pinimg.com/564x/be/39/a1/be39a19aa84332420df8cdb7876ec65b.jpg',
      'https://i.pinimg.com/564x/c0/91/e6/c091e6caee494bcba21ada09eefe8a4e.jpg',
      'https://i.pinimg.com/564x/11/b3/36/11b33621eef9a043a5f8433b57e087cd.jpg',
      'https://i.pinimg.com/736x/5b/96/e5/5b96e5a4d3fad8ae2eea5e899fe80126.jpg',
      'https://i.pinimg.com/736x/ce/57/72/ce5772b23607efe7e15596b4c7142682.jpg',
      'https://i.pinimg.com/564x/74/0a/64/740a643605c84f583391f82979904136.jpg',
      'https://i.pinimg.com/564x/bb/ce/c9/bbcec99a8c7d6a39993b1357f5c4ed2e.jpg',
    ],
    key: 'objects'
  },
  {
    gif: imgGifLocations,
    images: [
      'https://i.pinimg.com/564x/10/03/26/1003266b74ef63996ff4bc691c13e40d.jpg',
      'https://i.pinimg.com/564x/5d/fa/2c/5dfa2c7df7a3f8c63e523fa36739a017.jpg',
      'https://i.pinimg.com/564x/91/05/b1/9105b1f2778dd522542323d57987623f.jpg',
      'https://i.pinimg.com/564x/cf/3a/19/cf3a19bcbd8eaad59be9f1d02044eb33.jpg',
      'https://i.pinimg.com/564x/ff/58/fb/ff58fbea55890dd9b34261fd500699d9.jpg',
      'https://i.pinimg.com/564x/35/18/5b/35185be4ec454db1b411bd961a0c3526.jpg',
      //
      'https://i.pinimg.com/736x/69/4e/ff/694eff3a95f1184df7b6ea8f183bae64.jpg',
      'https://i.pinimg.com/564x/f0/d1/b0/f0d1b05f7c5af77116def7be7c161508.jpg',
      'https://i.pinimg.com/564x/7e/12/2d/7e122d4abdf0afb66138269b2c8ee641.jpg',
      'https://i.pinimg.com/564x/1b/a4/ec/1ba4ec49123ea95372c8bcfd30ff0c9c.jpg',
      'https://i.pinimg.com/564x/f6/43/49/f64349b739e38bbcc6bdeb78d26b5caf.jpg',
      'https://i.pinimg.com/564x/5f/11/1a/5f111aa12b4a9cd9a3aa350c11894f2b.jpg',
      'https://i.pinimg.com/564x/50/e0/3d/50e03d2a024738f1b96ead575607076f.jpg'
    ],
    key: 'locations'
  },
  {
    gif: imgGifDocuments,
    images: [
      'https://i.pinimg.com/736x/0a/af/70/0aaf70a4a2010109eb953116b0a847c4.jpg',
      'https://i.pinimg.com/564x/03/20/30/03203058750128f77d07ac81dbb993cd.jpg',
      'https://i.pinimg.com/564x/e1/be/23/e1be23a7882ebe3745da75dda0775492.jpg',
      'https://i.pinimg.com/564x/e3/a6/ea/e3a6eac58326cd4f32118f57fb08bd16.jpg',
      'https://i.pinimg.com/564x/73/93/b3/7393b3b930a958698f712f2dd814daf8.jpg',
      'https://i.pinimg.com/564x/8c/10/1c/8c101c02545f46e374e8dcd681ea5ae7.jpg'
    ],
    key: 'documents'
  },
  {
    gif: imgGifDialogues,
    images: [
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS24pM1TdIdIPgvCtyWJj4cltb2Ifuq4jVaMw&s',
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOTJTLmE-wkc6mZzT5y0YeJ_EqPSd6lou--LYSHNjgTPGfhREmyOt3bwTzw6FR9hIASMA&usqp=CAU'
    ],
    key: 'dialogues'
  },
  {
    gif: imgGifControls,
    images: [
      'https://i.pinimg.com/564x/2f/c3/dc/2fc3dc8482673c07986264c001289bac.jpg',
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREAFT_rbKhs6XlEucZGJE7EyOOaI-DV4E6sQ&s',
      'https://i.redd.it/12vqstqfgir41.jpg'
    ],
    key: 'controls'
  },
  {
    gif: imgGifGraphs,
    images: [
      'https://i.pinimg.com/564x/79/49/76/794976baf9c5b43e6b2c69e2627b2b7c.jpg',
      'https://i.pinimg.com/564x/8c/26/f0/8c26f0883d0409e16d4d772222b9ed89.jpg',
      'https://thumbs.dreamstime.com/b/%D0%BA%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B9-%D1%87%D0%B8%D0%BF-cpu-%D0%B2%D1%8B%D1%80%D0%B0%D1%89%D0%B8%D0%B2%D0%B0%D0%B5%D1%82-%D1%82%D0%B5%D1%85%D0%BD%D0%BE-%D0%B4%D0%B5%D1%80%D0%B5%D0%B2%D0%BE-%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D1%81%D1%85%D0%B5%D0%BC%D0%B0-%D0%BA%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%B0-%D1%81-266601643.jpg',
    ],
    key: 'graphs'
  },
  {
    gif: null,
    images: [
      'https://i.pinimg.com/564x/44/bc/8c/44bc8c0c62a00b18bb6204b14e08d026.jpg',
      'https://i.pinimg.com/564x/31/7c/43/317c43fe08a31710ed01538712b27a10.jpg',
      'https://i.pinimg.com/736x/1c/69/21/1c6921a6de046156b675b130f60868dd.jpg',
      'https://i.pinimg.com/564x/5a/00/62/5a006263abce4c99e9e756ee1677243a.jpg',
      'https://i.pinimg.com/564x/36/5f/86/365f866baac8f34f61babd7df9ab7bbd.jpg',
      'https://i.pinimg.com/564x/0a/a5/80/0aa5803eeb2085427b2d3f5c8fca1557.jpg'
    ],
    key: 'ai'
  }
];

