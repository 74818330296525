import { ThemeName } from 'hooks';

export interface ITheme {
  name: string,
  PRIMARY: string,
  SECONDARY: string,
  TERTIARY: string,
  SUCCESS: string,
  WARNING: string,
  ERROR: string,
  // pstring,
  COL1: string,
  COL2: string,
  COL3: string,
  COL4: string,
  COL5: string,
  COL6: string,
  // Theme specific
  TEXT: string;
  TEXT_LIGHT: string;
  TEXT_SECONDARY: string;
  BACKGROUND: string;
  APP_BACKGROUND: string;
  PANEL_BACKGROUND: string;
  PANEL_BACKGROUND_LIGHT: string;
  PANEL_BACKGROUND_DARK: string;
}

export const palette = {
  // palette
  COL1: '#DF542F',
  COL2: '#Ab9A3E',
  COL3: '#DBA576',
  COL4: '#FFF2D7',
  COL5: '#26A4B3',
  COL6: '#243E51',
  ERROR: '#C62828',
  PRIMARY: '#0067FF',
  SECONDARY: '#3e2ad6',
  SUCCESS: '#23AA55',
  TERTIARY: '#5533AA',
  WARNING: '#FB8C00'
};

export const light: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FAFAFA',
  BACKGROUND: '#FFFFFF',
  ERROR: '#C62828',
  PANEL_BACKGROUND: '#EEEEEE',
  PANEL_BACKGROUND_DARK: '#283B80',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#0067FF',
  SECONDARY: '#3e2ad6',
  SUCCESS: '#23AA55',
  TERTIARY: '#5533AA',
  TEXT: '#333333',
  TEXT_LIGHT: '#33333388',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#FB8C00',
  name: 'Lite Vision',
};

export const dark: ITheme = {
  ...palette,
  APP_BACKGROUND: '#0D1117',
  BACKGROUND: '#111111',
  ERROR: '#C62828',
  PANEL_BACKGROUND: '#333333',
  PANEL_BACKGROUND_DARK: '#283B80',
  PANEL_BACKGROUND_LIGHT: '#333333',
  PRIMARY: '#0067FF',
  SECONDARY: '#3e2ad6',
  SUCCESS: '#23AA55',
  TERTIARY: '#5533AA',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FB8C00',
  name: 'Nite Vision',
};

// #region Other Themes
export const vibrantTheme1: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FFCDD2',
  BACKGROUND: '#FFEBEE',
  ERROR: '#FF5733',
  PANEL_BACKGROUND: '#FFEBEE',
  PANEL_BACKGROUND_DARK: '#FF1744',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#FF5733',
  SECONDARY: '#C70039',
  SUCCESS: '#DAF7A6',
  TERTIARY: '#900C3F',
  TEXT: '#581845',
  TEXT_LIGHT: '#58184588',
  TEXT_SECONDARY: '#FFC300',
  WARNING: '#FFC300',
  name: 'Vibrant - Desert Orange',
};

export const vibrantTheme2: ITheme = {
  ...palette,
  APP_BACKGROUND: '#B2EBF2',
  BACKGROUND: '#E0F7FA',
  ERROR: '#FF5733',
  PANEL_BACKGROUND: '#B2EBF2',
  PANEL_BACKGROUND_DARK: '#00ACC1',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#FF5733',
  SECONDARY: '#C70039',
  SUCCESS: '#DAF7A6',
  TERTIARY: '#900C3F',
  TEXT: '#581845',
  TEXT_LIGHT: '#58184588',
  TEXT_SECONDARY: '#FFC300',
  WARNING: '#FFC300',
  name: 'Vibrant 2',
};

export const vibrantTheme3: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FFECB3',
  BACKGROUND: '#FFF3E0',
  ERROR: '#F44336',
  PANEL_BACKGROUND: '#FFEB3B',
  PANEL_BACKGROUND_DARK: '#FFC107',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#FFEB3B',
  SECONDARY: '#FF9800',
  SUCCESS: '#4CAF50',
  TERTIARY: '#FF5722',
  TEXT: '#FF5722',
  TEXT_LIGHT: '#FF572288',
  TEXT_SECONDARY: '#FFEB3B',
  WARNING: '#FFEB3B',
  name: 'Vibrant 3',
};

export const vibrantTheme4: ITheme = {
  ...palette,
  APP_BACKGROUND: '#D1C4E9',
  BACKGROUND: '#EDE7F6',
  ERROR: '#F44336',
  PANEL_BACKGROUND: '#EDE7F6',
  PANEL_BACKGROUND_DARK: '#8E24AA',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#9C27B0',
  SECONDARY: '#673AB7',
  SUCCESS: '#00BCD4',
  TERTIARY: '#3F51B5',
  TEXT: '#673AB7',
  TEXT_LIGHT: '#673AB788',
  TEXT_SECONDARY: '#FFC107',
  WARNING: '#FFC107',
  name: 'Vibrant - Grape',
};

export const vibrantTheme5: ITheme = {
  ...palette,
  APP_BACKGROUND: '#B3E5FC',
  BACKGROUND: '#E1F5FE',
  ERROR: '#F44336',
  PANEL_BACKGROUND: '#E1F5FE',
  PANEL_BACKGROUND_DARK: '#1E88E5',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#2196F3',
  SECONDARY: '#00BCD4',
  SUCCESS: '#FFEB3B',
  TERTIARY: '#4CAF50',
  TEXT: '#00BCD4',
  TEXT_LIGHT: '#00BCD488',
  TEXT_SECONDARY: '#FFC107',
  WARNING: '#FFC107',
  name: 'Vibrant - Aqua',
};

export const vibrantTheme6: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FF69B4',
  BACKGROUND: '#FFD700',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#FF6347',
  PANEL_BACKGROUND_DARK: '#FF1493',
  PANEL_BACKGROUND_LIGHT: '#FF00FF',
  PRIMARY: '#FF4500',
  SECONDARY: '#FFD700',
  SUCCESS: '#FF6347',
  TERTIARY: '#32CD32',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Vibrant 6'
};

export const vibrantTheme7: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FFFF00',
  BACKGROUND: '#00FFFF',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#32CD32',
  PANEL_BACKGROUND_DARK: '#FF4500',
  PANEL_BACKGROUND_LIGHT: '#FF6347',
  PRIMARY: '#FF00FF',
  SECONDARY: '#00FFFF',
  SUCCESS: '#32CD32',
  TERTIARY: '#FFFF00',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Vibrant 7'
};

export const vibrantTheme8: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FF00FF',
  BACKGROUND: '#FFD700',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#32CD32',
  PANEL_BACKGROUND_DARK: '#FF1493',
  PANEL_BACKGROUND_LIGHT: '#FF4500',
  PRIMARY: '#32CD32',
  SECONDARY: '#FFD700',
  SUCCESS: '#FF6347',
  TERTIARY: '#FF4500',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Vibrant 8'
};

export const vibrantTheme9: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FFFF00',
  BACKGROUND: '#FF00FF',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#32CD32',
  PANEL_BACKGROUND_DARK: '#FF1493',
  PANEL_BACKGROUND_LIGHT: '#FF4500',
  PRIMARY: '#FF6347',
  SECONDARY: '#FF00FF',
  SUCCESS: '#32CD32',
  TERTIARY: '#FFD700',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Vibrant 9'
};

export const vibrantTheme10: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FFD700',
  BACKGROUND: '#32CD32',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#FF6347',
  PANEL_BACKGROUND_DARK: '#FF4500',
  PANEL_BACKGROUND_LIGHT: '#FF00FF',
  PRIMARY: '#FFFF00',
  SECONDARY: '#32CD32',
  SUCCESS: '#FF6347',
  TERTIARY: '#FF00FF',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Vibrant 10'
};

export const classicTheme1: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FAFAFA',
  BACKGROUND: '#FAFFFF',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#e8e8e8',
  PANEL_BACKGROUND_DARK: '#7F8C8D',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#3498DB',
  SECONDARY: '#2ECC71',
  SUCCESS: '#1ABC9C',
  TERTIARY: '#9B59B6',
  TEXT: '#2C3E50',
  TEXT_LIGHT: '#2C3E5088',
  TEXT_SECONDARY: '#ECF0F1',
  WARNING: '#F1C40F',
  name: 'Classic 1'
};

export const classicTheme2: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F7FAFE',
  BACKGROUND: '#FFF',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#F7FAFE',
  PANEL_BACKGROUND_DARK: '#2C3E50',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#2980B9',
  SECONDARY: '#27AE60',
  SUCCESS: '#16A085',
  TERTIARY: '#8E44AD',
  TEXT: '#34495E',
  TEXT_LIGHT: '#34495E88',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#F39C12',
  name: 'Classic 2'
};

export const classicTheme3: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F0F0F0',
  BACKGROUND: '#E0E0E0',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#CCCCCC',
  PANEL_BACKGROUND_DARK: '#95A5A6',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#1ABC9C',
  SECONDARY: '#2ECC71',
  SUCCESS: '#27AE60',
  TERTIARY: '#3498DB',
  TEXT: '#7F8C8D',
  TEXT_LIGHT: '#7F8C8D88',
  TEXT_SECONDARY: '#ECF0F1',
  WARNING: '#E67E22',
  name: 'Classic 3'
};

export const classicTheme4: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F7F9F9',
  BACKGROUND: '#D5D8DC',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#BFC9CA',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#E74C3C',
  SECONDARY: '#F39C12',
  SUCCESS: '#2ECC71',
  TERTIARY: '#9B59B6',
  TEXT: '#34495E',
  TEXT_LIGHT: '#34495E88',
  TEXT_SECONDARY: '#ECF0F1',
  WARNING: '#E67E22',
  name: 'Classic 4'
};

export const classicTheme5: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F2F4F4',
  BACKGROUND: '#E5E8E8',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#D5DBDB',
  PANEL_BACKGROUND_DARK: '#7D3C98',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#8E44AD',
  SECONDARY: '#2980B9',
  SUCCESS: '#27AE60',
  TERTIARY: '#1ABC9C',
  TEXT: '#2C3E50',
  TEXT_LIGHT: '#2C3E5088',
  TEXT_SECONDARY: '#ECF0F1',
  WARNING: '#F1C40F',
  name: 'Classic 5'
};

export const neonTheme1: ITheme = {
  ...palette,
  APP_BACKGROUND: '#111111',
  BACKGROUND: '#000000',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#333333',
  PANEL_BACKGROUND_DARK: '#222222',
  PANEL_BACKGROUND_LIGHT: '#444444',
  PRIMARY: '#DD0BDD',
  SECONDARY: '#54ACC4',
  SUCCESS: '#00FF00',
  TERTIARY: '#FFFF00',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon 1'
};

export const neonTheme2: ITheme = {
  ...palette,
  APP_BACKGROUND: '#2F4F4F',
  BACKGROUND: '#2F4F4F',
  ERROR: '#8B0000',
  PANEL_BACKGROUND: '#8A2BE2',
  PANEL_BACKGROUND_DARK: '#483D8B',
  PANEL_BACKGROUND_LIGHT: '#DA70D6',
  PRIMARY: '#FFD700',
  SECONDARY: '#FF6347',
  SUCCESS: '#00FF7F',
  TERTIARY: '#00CED1',
  TEXT: '#FF4500',
  TEXT_LIGHT: '#FF450088',
  TEXT_SECONDARY: '#00FFFF',
  WARNING: '#FF69B4',
  name: 'Neon - Avorange'
};

export const neonTheme3: ITheme = {
  ...palette,
  APP_BACKGROUND: '#800080',
  BACKGROUND: '#800080',
  ERROR: '#00FFFF',
  PANEL_BACKGROUND: '#800080',
  PANEL_BACKGROUND_DARK: '#483D8B',
  PANEL_BACKGROUND_LIGHT: '#DA70D6',
  PRIMARY: '#7FFFD4',
  SECONDARY: '#FF1493',
  SUCCESS: '#FFD700',
  TERTIARY: '#00FF00',
  TEXT: '#FF69B4',
  TEXT_LIGHT: '#FF69B488',
  TEXT_SECONDARY: '#4B0082',
  WARNING: '#DC143C',
  name: 'Neon - Magenta'
};

export const neonTheme4: ITheme = {
  ...palette,
  APP_BACKGROUND: '#000000',
  BACKGROUND: '#000000',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#333333',
  PANEL_BACKGROUND_DARK: '#222222',
  PANEL_BACKGROUND_LIGHT: '#444444',
  PRIMARY: '#0BDD0B',
  SECONDARY: '#ACC454',
  SUCCESS: '#00FF00',
  TERTIARY: '#00FFFF',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon - Dark Kang'
};

export const neonTheme5: ITheme = {
  ...palette,
  APP_BACKGROUND: '#101010',
  BACKGROUND: '#080808',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#303030',
  PANEL_BACKGROUND_DARK: '#202020',
  PANEL_BACKGROUND_LIGHT: '#484848',
  PRIMARY: '#C70039',
  SECONDARY: '#FFC300',
  SUCCESS: '#00FF00',
  TERTIARY: '#DAF7A6',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FF5733',
  name: 'Neon 5'
};

export const neonTheme6: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1E1A2F',
  BACKGROUND: '#2A1B2E',
  ERROR: '#FF1493',
  PANEL_BACKGROUND: '#330033',
  PANEL_BACKGROUND_DARK: '#220022',
  PANEL_BACKGROUND_LIGHT: '#440044',
  PRIMARY: '#962DFF',
  SECONDARY: '#b81858',
  SUCCESS: '#6FFF00',
  TERTIARY: '#FF5733',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFD700',
  name: 'Neon 6',
};

export const neonTheme7: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1D1F2A',
  BACKGROUND: '#1E1A2F',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#333300',
  PANEL_BACKGROUND_DARK: '#222200',
  PANEL_BACKGROUND_LIGHT: '#444400',
  PRIMARY: '#FFD700',
  SECONDARY: '#d737EF',
  SUCCESS: '#00FF00',
  TERTIARY: '#FFC300',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon 7',
};

export const neonTheme8: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1F1B2B',
  BACKGROUND: '#1D1F2A',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#330033',
  PANEL_BACKGROUND_DARK: '#220022',
  PANEL_BACKGROUND_LIGHT: '#440044',
  PRIMARY: '#00FF00',
  SECONDARY: '#1D1F2A',
  SUCCESS: '#FF00FF',
  TERTIARY: '#33FFBD',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon - Matrix',
};

export const neonTheme9: ITheme = {
  ...palette,
  APP_BACKGROUND: '#2B1A1E',
  BACKGROUND: '#1F1B2B',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#330033',
  PANEL_BACKGROUND_DARK: '#220022',
  PANEL_BACKGROUND_LIGHT: '#440044',
  PRIMARY: '#FF1493',
  SECONDARY: '#1F1B2B',
  SUCCESS: '#00FF00',
  TERTIARY: '#FF33FF',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon - Dark Pink',
};

export const neonTheme10: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1E1A2F',
  BACKGROUND: '#1b1828',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#330033',
  PANEL_BACKGROUND_DARK: '#220022',
  PANEL_BACKGROUND_LIGHT: '#440044',
  PRIMARY: '#00EEFF',
  SECONDARY: '#0067FF',
  SUCCESS: '#FF00FF',
  TERTIARY: '#33FFBD',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon - Dark Teal',
};

export const neonTheme11: ITheme = {
  ...palette,
  APP_BACKGROUND: '#2B1A1E',
  BACKGROUND: '#1F1B2B',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#330033',
  PANEL_BACKGROUND_DARK: '#220022',
  PANEL_BACKGROUND_LIGHT: '#440044',
  PRIMARY: '#FF69B4',
  SECONDARY: '#1F1B2B',
  SUCCESS: '#00FF00',
  TERTIARY: '#FF4500',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon - Dark Rose',
};

export const neonTheme12: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1F1B2B',
  BACKGROUND: '#1D1F2A',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#333300',
  PANEL_BACKGROUND_DARK: '#222200',
  PANEL_BACKGROUND_LIGHT: '#444400',
  PRIMARY: '#00FF00',
  SECONDARY: '#188985',
  SUCCESS: '#FF00FF',
  TERTIARY: '#33FFBD',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon 12'
};

export const neonTheme13: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1D1F2A',
  BACKGROUND: '#1E1A2F',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#333300',
  PANEL_BACKGROUND_DARK: '#222200',
  PANEL_BACKGROUND_LIGHT: '#444400',
  PRIMARY: '#FF6347',
  SECONDARY: '#1E1A2F',
  SUCCESS: '#00FF00',
  TERTIARY: '#FFD700',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon 13'
};

export const neonTheme14: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1F1B2B',
  BACKGROUND: '#2B1A1E',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#330033',
  PANEL_BACKGROUND_DARK: '#220022',
  PANEL_BACKGROUND_LIGHT: '#440044',
  PRIMARY: '#00FFFF',
  SECONDARY: '#9722e4',
  SUCCESS: '#FF00FF',
  TERTIARY: '#33FFBD',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon 14'
};

export const neonTheme15: ITheme = {
  ...palette,
  APP_BACKGROUND: '#2B1A1E',
  BACKGROUND: '#1F1B2B',
  ERROR: '#FF0000',
  PANEL_BACKGROUND: '#330033',
  PANEL_BACKGROUND_DARK: '#220022',
  PANEL_BACKGROUND_LIGHT: '#440044',
  PRIMARY: '#FF1493',
  SECONDARY: '#641acb',
  SUCCESS: '#00FF00',
  TERTIARY: '#FF33FF',
  TEXT: '#FFFFFF',
  TEXT_LIGHT: '#FFFFFF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Neon 15'
};

export const lightTheme1: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FAFAFA',
  BACKGROUND: '#ECF0F1',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#BDC3C7',
  PANEL_BACKGROUND_DARK: '#7F8C8D',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#3498DB',
  SECONDARY: '#2ECC71',
  SUCCESS: '#1ABC9C',
  TERTIARY: '#9B59B6',
  TEXT: '#2C3E50',
  TEXT_LIGHT: '#2C3E5088',
  TEXT_SECONDARY: '#ECF0F1',
  WARNING: '#F1C40F',
  name: 'Light 1'
};

export const lightTheme2: ITheme = {
  ...palette,
  APP_BACKGROUND: '#ECF0F1',
  BACKGROUND: '#BDC3C7',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#D5D8DC',
  PANEL_BACKGROUND_DARK: '#2C3E50',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#2980B9',
  SECONDARY: '#27AE60',
  SUCCESS: '#16A085',
  TERTIARY: '#8E44AD',
  TEXT: '#34495E',
  TEXT_LIGHT: '#34495E88',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#F39C12',
  name: 'Light 2'
};

export const lightTheme3: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F0F0F0',
  BACKGROUND: '#E0E0E0',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#CCCCCC',
  PANEL_BACKGROUND_DARK: '#95A5A6',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#1ABC9C',
  SECONDARY: '#2ECC71',
  SUCCESS: '#27AE60',
  TERTIARY: '#3498DB',
  TEXT: '#7F8C8D',
  TEXT_LIGHT: '#7F8C8D88',
  TEXT_SECONDARY: '#ECF0F1',
  WARNING: '#E67E22',
  name: 'Light - Green'
};

export const lightTheme4: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F7F9F9',
  BACKGROUND: '#D5D8DC',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#BFC9CA',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#E74C3C',
  SECONDARY: '#F39C12',
  SUCCESS: '#2ECC71',
  TERTIARY: '#9B59B6',
  TEXT: '#34495E',
  TEXT_LIGHT: '#34495E88',
  TEXT_SECONDARY: '#ECF0F1',
  WARNING: '#E67E22',
  name: 'Light - Orange'
};

export const lightTheme5: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F2F4F4',
  BACKGROUND: '#E5E8E8',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#D5DBDB',
  PANEL_BACKGROUND_DARK: '#7D3C98',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#8E44AD',
  SECONDARY: '#2980B9',
  SUCCESS: '#27AE60',
  TERTIARY: '#1ABC9C',
  TEXT: '#2C3E50',
  TEXT_LIGHT: '#2C3E5088',
  TEXT_SECONDARY: '#ECF0F1',
  WARNING: '#F1C40F',
  name: 'Light - Purple'
};


export const darkTheme1: ITheme = {
  ...palette,
  APP_BACKGROUND: '#17202A',
  BACKGROUND: '#1C2833',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#273746',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#2C3E50',
  PRIMARY: '#8E44AD',
  SECONDARY: '#2980B9',
  SUCCESS: '#27AE60',
  TERTIARY: '#1ABC9C',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F1C40F',
  name: 'Dark 1',
};

export const darkTheme2: ITheme = {
  ...palette,
  APP_BACKGROUND: '#212F3D',
  BACKGROUND: '#2C3E50',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#34495E',
  PANEL_BACKGROUND_DARK: '#1B2631',
  PANEL_BACKGROUND_LIGHT: '#566573',
  PRIMARY: '#E74C3C',
  SECONDARY: '#F39C12',
  SUCCESS: '#2ECC71',
  TERTIARY: '#9B59B6',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark 2',
};

export const darkTheme3: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1C2833',
  BACKGROUND: '#17202A',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#2E4053',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#3498DB',
  SECONDARY: '#2ECC71',
  SUCCESS: '#1ABC9C',
  TERTIARY: '#9B59B6',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F1C40F',
  name: 'Dark 3',
};

export const darkTheme4: ITheme = {
  ...palette,
  APP_BACKGROUND: '#17202A',
  BACKGROUND: '#1C2833',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#273746',
  PANEL_BACKGROUND_DARK: '#212F3D',
  PANEL_BACKGROUND_LIGHT: '#2C3E50',
  PRIMARY: '#1ABC9C',
  SECONDARY: '#2ECC71',
  SUCCESS: '#27AE60',
  TERTIARY: '#3498DB',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark 4',
};

export const darkTheme5: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1B2631',
  BACKGROUND: '#212F3D',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#2C3E50',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#2980B9',
  SECONDARY: '#27AE60',
  SUCCESS: '#16A085',
  TERTIARY: '#8E44AD',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F39C12',
  name: 'Dark 5',
};

export const darkTheme6: ITheme = {
  ...palette,
  APP_BACKGROUND: '#17202A',
  BACKGROUND: '#1C2833',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#273746',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#2C3E50',
  PRIMARY: '#8E44AD',
  SECONDARY: '#2980B9',
  SUCCESS: '#27AE60',
  TERTIARY: '#1ABC9C',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F1C40F',
  name: 'Dark 6',
};

export const darkTheme7: ITheme = {
  ...palette,
  APP_BACKGROUND: '#212F3D',
  BACKGROUND: '#2C3E50',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#34495E',
  PANEL_BACKGROUND_DARK: '#1B2631',
  PANEL_BACKGROUND_LIGHT: '#566573',
  PRIMARY: '#E74C3C',
  SECONDARY: '#F39C12',
  SUCCESS: '#2ECC71',
  TERTIARY: '#9B59B6',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark 7',
};

export const darkTheme8: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1C2833',
  BACKGROUND: '#17202A',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#2E4053',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#1ABC9C',
  SECONDARY: '#2ECC71',
  SUCCESS: '#1ABC9C',
  TERTIARY: '#9B59B6',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F1C40F',
  name: 'Dark - Green',
};

export const darkTheme9: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1C2833',
  BACKGROUND: '#17202A',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#2E4053',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#3498DB',
  SECONDARY: '#2ECC71',
  SUCCESS: '#1ABC9C',
  TERTIARY: '#9B59B6',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F1C40F',
  name: 'Dark 9',
};

export const darkTheme10: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1C2833',
  BACKGROUND: '#17202A',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#2E4053',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#3498DB',
  SECONDARY: '#2ECC71',
  SUCCESS: '#1ABC9C',
  TERTIARY: '#9B59B6',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F1C40F',
  name: 'Dark 10',
};

export const darkTheme11: ITheme = {
  ...palette,
  APP_BACKGROUND: '#17202A',
  BACKGROUND: '#1C2833',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#273746',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#2C3E50',
  PRIMARY: '#E74C3C',
  SECONDARY: '#F39C12',
  SUCCESS: '#2ECC71',
  TERTIARY: '#9B59B6',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark - BloodOrange',
};

export const darkTheme12: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1C2833',
  BACKGROUND: '#17202A',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#2E4053',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#1ABC9C',
  SECONDARY: '#2ECC71',
  SUCCESS: '#27AE60',
  TERTIARY: '#3498DB',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark 12',
};

export const darkTheme13: ITheme = {
  ...palette,
  APP_BACKGROUND: '#17202A',
  BACKGROUND: '#1C2833',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#273746',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#2C3E50',
  PRIMARY: '#8E44AD',
  SECONDARY: '#2980B9',
  SUCCESS: '#27AE60',
  TERTIARY: '#1ABC9C',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F1C40F',
  name: 'Dark 13',
};

export const darkTheme14: ITheme = {
  ...palette,
  APP_BACKGROUND: '#212F3D',
  BACKGROUND: '#2C3E50',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#34495E',
  PANEL_BACKGROUND_DARK: '#1B2631',
  PANEL_BACKGROUND_LIGHT: '#566573',
  PRIMARY: '#2980B9',
  SECONDARY: '#27AE60',
  SUCCESS: '#16A085',
  TERTIARY: '#8E44AD',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F39C12',
  name: 'Dark 14',
};

export const darkTheme15: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1C2833',
  BACKGROUND: '#17202A',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#2E4053',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#1ABC9C',
  SECONDARY: '#2ECC71',
  SUCCESS: '#27AE60',
  TERTIARY: '#3498DB',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark 15',
};

export const darkTheme16: ITheme = {
  ...palette,
  APP_BACKGROUND: '#212F3D',
  BACKGROUND: '#2C3E50',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#34495E',
  PANEL_BACKGROUND_DARK: '#1B2631',
  PANEL_BACKGROUND_LIGHT: '#566573',
  PRIMARY: '#E74C3C',
  SECONDARY: '#F39C12',
  SUCCESS: '#2ECC71',
  TERTIARY: '#9B59B6',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark - Orange',
};

export const darkTheme17: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1C2833',
  BACKGROUND: '#17202A',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#2E4053',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#1ABC9C',
  SECONDARY: '#2ECC71',
  SUCCESS: '#27AE60',
  TERTIARY: '#3498DB',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark 17',
};

export const darkTheme18: ITheme = {
  ...palette,
  APP_BACKGROUND: '#17202A',
  BACKGROUND: '#1C2833',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#273746',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#2C3E50',
  PRIMARY: '#8E44AD',
  SECONDARY: '#2980B9',
  SUCCESS: '#27AE60',
  TERTIARY: '#1ABC9C',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F1C40F',
  name: 'Dark 18',
};

export const darkTheme19: ITheme = {
  ...palette,
  APP_BACKGROUND: '#212F3D',
  BACKGROUND: '#2C3E50',
  ERROR: '#C0392B',
  PANEL_BACKGROUND: '#34495E',
  PANEL_BACKGROUND_DARK: '#1B2631',
  PANEL_BACKGROUND_LIGHT: '#566573',
  PRIMARY: '#2980B9',
  SECONDARY: '#27AE60',
  SUCCESS: '#16A085',
  TERTIARY: '#8E44AD',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#F39C12',
  name: 'Dark 19',
};

export const darkTheme20: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1C2833',
  BACKGROUND: '#17202A',
  ERROR: '#E74C3C',
  PANEL_BACKGROUND: '#2E4053',
  PANEL_BACKGROUND_DARK: '#1F618D',
  PANEL_BACKGROUND_LIGHT: '#34495E',
  PRIMARY: '#1ABC9C',
  SECONDARY: '#2ECC71',
  SUCCESS: '#27AE60',
  TERTIARY: '#3498DB',
  TEXT: '#ECF0F1',
  TEXT_LIGHT: '#ECF0F188',
  TEXT_SECONDARY: '#2C3E50',
  WARNING: '#E67E22',
  name: 'Dark 20',
};
// #endregion

// #region POPULAR THEMES
export const darculaLight: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FAFAFA',
  BACKGROUND: '#FFFFFF',
  ERROR: '#E06C75',
  PANEL_BACKGROUND: '#EEEEEE',
  PANEL_BACKGROUND_DARK: '#3C3F41',
  PANEL_BACKGROUND_LIGHT: '#E6E6E6',
  PRIMARY: '#A9B7C6',
  SECONDARY: '#F07178',
  SUCCESS: '#50FA7B',
  TERTIARY: '#CC7832',
  TEXT: '#2B2B2B',
  TEXT_LIGHT: '#2B2B2B88',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#FFC66D',
  name: 'Darcula Light',
};

export const darculaDark: ITheme = {
  ...palette,
  APP_BACKGROUND: '#2B2B2B',
  BACKGROUND: '#3C3F41',
  ERROR: '#E06C75',
  PANEL_BACKGROUND: '#2B2B2B',
  PANEL_BACKGROUND_DARK: '#3C3F41',
  PANEL_BACKGROUND_LIGHT: '#3C3F41',
  PRIMARY: '#A9B7C6',
  SECONDARY: '#F07178',
  SUCCESS: '#50FA7B',
  TERTIARY: '#CC7832',
  TEXT: '#A9B7C6',
  TEXT_LIGHT: '#A9B7C688',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFC66D',
  name: 'Darcula Dark',
};

export const solarizedLight: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FDF6E3',
  BACKGROUND: '#FDF6E3',
  ERROR: '#DC322F',
  PANEL_BACKGROUND: '#EEE8D5',
  PANEL_BACKGROUND_DARK: '#93A1A1',
  PANEL_BACKGROUND_LIGHT: '#FDF6E3',
  PRIMARY: '#268BD2',
  SECONDARY: '#2AA198',
  SUCCESS: '#859900',
  TERTIARY: '#B58900',
  TEXT: '#657B83',
  TEXT_LIGHT: '#657B8388',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#CB4B16',
  name: 'Solarized Light',
};

export const solarizedDark: ITheme = {
  ...palette,
  APP_BACKGROUND: '#002B36',
  BACKGROUND: '#073642',
  ERROR: '#DC322F',
  PANEL_BACKGROUND: '#073642',
  PANEL_BACKGROUND_DARK: '#586E75',
  PANEL_BACKGROUND_LIGHT: '#073642',
  PRIMARY: '#268BD2',
  SECONDARY: '#2AA198',
  SUCCESS: '#859900',
  TERTIARY: '#B58900',
  TEXT: '#839496',
  TEXT_LIGHT: '#83949688',
  TEXT_SECONDARY: '#000000',
  WARNING: '#CB4B16',
  name: 'Solarized Dark',
};

export const githubLight: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F6F8FA',
  BACKGROUND: '#FFFFFF',
  ERROR: '#D73A49',
  PANEL_BACKGROUND: '#E1E4E8',
  PANEL_BACKGROUND_DARK: '#24292E',
  PANEL_BACKGROUND_LIGHT: '#F6F8FA',
  PRIMARY: '#0366D6',
  SECONDARY: '#28A745',
  SUCCESS: '#28A745',
  TERTIARY: '#6F42C1',
  TEXT: '#24292E',
  TEXT_LIGHT: '#24292E88',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#FFA500',
  name: 'GitHub Light',
};

export const githubDark: ITheme = {
  ...palette,
  APP_BACKGROUND: '#0D1117',
  BACKGROUND: '#161B22',
  ERROR: '#F85149',
  PANEL_BACKGROUND: '#21262D',
  PANEL_BACKGROUND_DARK: '#2D333B',
  PANEL_BACKGROUND_LIGHT: '#161B22',
  PRIMARY: '#58A6FF',
  SECONDARY: '#238636',
  SUCCESS: '#238636',
  TERTIARY: '#A371F7',
  TEXT: '#C9D1D9',
  TEXT_LIGHT: '#C9D1D988',
  TEXT_SECONDARY: '#000000',
  WARNING: '#F0883E',
  name: 'GitHub Dark',
};

export const electronLight: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FAFAFA',
  BACKGROUND: '#FFFFFF',
  ERROR: '#FF4D4F',
  PANEL_BACKGROUND: '#F0F0F0',
  PANEL_BACKGROUND_DARK: '#141E24',
  PANEL_BACKGROUND_LIGHT: '#F8F8F8',
  PRIMARY: '#007ACC',
  SECONDARY: '#00BCD4',
  SUCCESS: '#4CAF50',
  TERTIARY: '#FF9800',
  TEXT: '#333333',
  TEXT_LIGHT: '#33333388',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#FFC107',
  name: 'Electron Light',
};

export const electronDark: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1E1E1E',
  BACKGROUND: '#2D2D2D',
  ERROR: '#FF4D4F',
  PANEL_BACKGROUND: '#3C3C3C',
  PANEL_BACKGROUND_DARK: '#141E24',
  PANEL_BACKGROUND_LIGHT: '#2D2D2D',
  PRIMARY: '#007ACC',
  SECONDARY: '#00BCD4',
  SUCCESS: '#4CAF50',
  TERTIARY: '#FF9800',
  TEXT: '#D4D4D4',
  TEXT_LIGHT: '#D4D4D488',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFC107',
  name: 'Electron Dark',
};

export const atomLight: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FAFAFA',
  BACKGROUND: '#FFFFFF',
  ERROR: '#E06C75',
  PANEL_BACKGROUND: '#F0F0F0',
  PANEL_BACKGROUND_DARK: '#1D1F21',
  PANEL_BACKGROUND_LIGHT: '#F8F8F8',
  PRIMARY: '#61AFEF',
  SECONDARY: '#98C379',
  SUCCESS: '#98C379',
  TERTIARY: '#D19A66',
  TEXT: '#282C34',
  TEXT_LIGHT: '#282C3488',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#E5C07B',
  name: 'Atom Light',
};

export const atomDark: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1D1F21',
  BACKGROUND: '#282C34',
  ERROR: '#E06C75',
  PANEL_BACKGROUND: '#2C313A',
  PANEL_BACKGROUND_DARK: '#1D1F21',
  PANEL_BACKGROUND_LIGHT: '#282C34',
  PRIMARY: '#61AFEF',
  SECONDARY: '#98C379',
  SUCCESS: '#98C379',
  TERTIARY: '#D19A66',
  TEXT: '#ABB2BF',
  TEXT_LIGHT: '#ABB2BF88',
  TEXT_SECONDARY: '#000000',
  WARNING: '#E5C07B',
  name: 'Atom Dark',
};

export const vscodeLight: ITheme = {
  ...palette,
  APP_BACKGROUND: '#F3F3F3',
  BACKGROUND: '#FFFFFF',
  ERROR: '#FF5370',
  PANEL_BACKGROUND: '#F0F0F0',
  PANEL_BACKGROUND_DARK: '#1E1E1E',
  PANEL_BACKGROUND_LIGHT: '#F3F3F3',
  PRIMARY: '#007ACC',
  SECONDARY: '#009688',
  SUCCESS: '#4CAF50',
  TERTIARY: '#FF9800',
  TEXT: '#333333',
  TEXT_LIGHT: '#33333388',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#FFB86C',
  name: 'VSCode Light',
};

export const vscodeDark: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1E1E1E',
  BACKGROUND: '#252526',
  ERROR: '#FF5370',
  PANEL_BACKGROUND: '#333333',
  PANEL_BACKGROUND_DARK: '#1E1E1E',
  PANEL_BACKGROUND_LIGHT: '#252526',
  PRIMARY: '#007ACC',
  SECONDARY: '#009688',
  SUCCESS: '#4CAF50',
  TERTIARY: '#FF9800',
  TEXT: '#D4D4D4',
  TEXT_LIGHT: '#D4D4D488',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFB86C',
  name: 'VSCode Dark',
};

export const visualStudioLight: ITheme = {
  ...palette,
  APP_BACKGROUND: '#FFFFFF',
  BACKGROUND: '#F6F6F6',
  ERROR: '#E51400',
  PANEL_BACKGROUND: '#EEEEEE',
  PANEL_BACKGROUND_DARK: '#5D5D5D',
  PANEL_BACKGROUND_LIGHT: '#FFFFFF',
  PRIMARY: '#007ACC',
  SECONDARY: '#68217A',
  SUCCESS: '#4CAF50',
  TERTIARY: '#FF9800',
  TEXT: '#333333',
  TEXT_LIGHT: '#33333388',
  TEXT_SECONDARY: '#FFFFFF',
  WARNING: '#FFA500',
  name: 'Visual Studio Light',
};

export const visualStudioDark: ITheme = {
  ...palette,
  APP_BACKGROUND: '#1E1E1E',
  BACKGROUND: '#2D2D30',
  ERROR: '#E51400',
  PANEL_BACKGROUND: '#333333',
  PANEL_BACKGROUND_DARK: '#1E1E1E',
  PANEL_BACKGROUND_LIGHT: '#2D2D30',
  PRIMARY: '#007ACC',
  SECONDARY: '#68217A',
  SUCCESS: '#4CAF50',
  TERTIARY: '#FF9800',
  TEXT: '#D4D4D4',
  TEXT_LIGHT: '#D4D4D488',
  TEXT_SECONDARY: '#000000',
  WARNING: '#FFA500',
  name: 'Visual Studio Dark',
};

//#endregion

export const themes: {
  // eslint-disable-next-line no-unused-vars
  [key in ThemeName]?: ITheme
} = {
  atomDark,
  atomLight,
  // eslint-disable-next-line sort-keys
classicTheme1,
  classicTheme2,
  classicTheme3,

  classicTheme4,

classicTheme5,

darculaDark,

darculaLight,
  // eslint-disable-next-line sort-keys
dark,
  darkTheme1,
  darkTheme10,
  darkTheme11,
  darkTheme12,
  darkTheme13,
  darkTheme14,
  darkTheme15,
  darkTheme16,
  darkTheme17,
  darkTheme18,
  darkTheme19,
  darkTheme2,
  darkTheme20,
  darkTheme3,
  darkTheme4,
  darkTheme5,
  darkTheme6,

  darkTheme7,
  darkTheme8,
  darkTheme9,

  electronDark,
  electronLight,
  githubDark,
  githubLight,
  light,
  lightTheme3,
  lightTheme4,
  lightTheme5,
  neonTheme1,
  neonTheme10,
  neonTheme11,
  neonTheme12,
  neonTheme13,
  neonTheme14,
  neonTheme15,

  neonTheme2,
  neonTheme3,
  neonTheme4,
  neonTheme5,
  neonTheme6,
  neonTheme7,
  neonTheme8,
  neonTheme9,
  solarizedDark,
  solarizedLight,

  vibrantTheme1,
  vibrantTheme10,
  vibrantTheme2,
  vibrantTheme3,
  vibrantTheme4,
  vibrantTheme5,
  vibrantTheme6,
  vibrantTheme7,
  vibrantTheme8,
  vibrantTheme9,
  visualStudioDark,
  visualStudioLight,
  vscodeDark,
  vscodeLight,
};

export const themeNames: ThemeName[] = [
  // light
  'light',
  'dark',
  'darculaDark',
  'solarizedDark',
  'githubDark',
  // 'electronDark',
  'atomDark',
  'vscodeDark',
  // 'visualStudioDark',
  'darculaLight',
  'solarizedLight',
  'githubLight',
  // 'electronLight',
  'atomLight',
  'vscodeLight',
  // 'visualStudioLight',

  // 'neonTheme1',
  'neonTheme8', // keep: matrix
  'neonTheme4', // keep: darkKang
  // 'neonTheme5',
  // 'neonTheme6',
  // 'neonTheme7',
  'neonTheme2', // keep: avorange
  'neonTheme3', // keep: magenta
  'neonTheme9', // keep: neonDarkPink
  'neonTheme11', // keep: darkRose
  'neonTheme10', // keep: darkTeal
  // 'neonTheme12',
  // 'neonTheme13',
  // 'neonTheme14',
  // 'neonTheme15',

  // 'classicTheme1',
  // 'classicTheme2',
  // 'classicTheme3',
  // 'classicTheme4',
  // 'classicTheme5',

  'vibrantTheme5', // keep: aqua
  'vibrantTheme4', // keep: grape
  'vibrantTheme1', // keep: desertOrange
  // 'vibrantTheme2',
  // 'vibrantTheme3',
  // 'vibrantTheme6',
  // 'vibrantTheme7',
  // 'vibrantTheme8',
  // 'vibrantTheme9',
  // 'vibrantTheme10',

  // 'lightTheme1',
  // 'lightTheme2',
  'lightTheme3', // keep: lightGreen
  'lightTheme4', // keep: lightOrange
  'lightTheme5', // keep: lightPurple

  // 'darkTheme1',
  // 'darkTheme2',
  // 'darkTheme3',
  // 'darkTheme4',
  // 'darkTheme5',
  // 'darkTheme6',
  // 'darkTheme7',
  'darkTheme8', // keep: darkGreen
  // 'darkTheme9',
  // 'darkTheme10',
  // 'darkTheme12',
  // 'darkTheme13',
  // 'darkTheme14',
  // 'darkTheme15',
  'darkTheme16', // keep: darkOrange
  'darkTheme11', // keep: bloodOrange
  // 'darkTheme17',
  // 'darkTheme18',
  // 'darkTheme19',
  // 'darkTheme20',
];