export const images = [
  // Quality
  // - Cars
  'https://img.wallscloud.net/uploads/thumb/1665713509/lamborghini-huracan-1-58338-1024x576-MM-80.webp',
  'https://img.wallscloud.net/uploads/thumb/3348792796/supercar-1-56956-1024x576-MM-80.webp',
  'https://img.wallscloud.net/uploads/thumb/303197027/cyberpunk-car-1-56137-1024x576-MM-80.webp',
  // Abstract
  'https://i.pinimg.com/564x/42/01/6b/42016be929b20544d0411ef0e3f2640a.jpg',
  'https://i.pinimg.com/564x/35/cd/8e/35cd8eeca9a6cb611241e0fe8b920288.jpg',
  'https://i.pinimg.com/564x/40/08/b8/4008b8bda003be0cb635f490488e8298.jpg',
  'https://i.pinimg.com/564x/1d/bd/ac/1dbdac8f833a6ac7b6777fcf2b4c9aa3.jpg',
  'https://i.pinimg.com/564x/5e/b3/e3/5eb3e34afa66a290d1e33a432b160378.jpg',
  'https://i.pinimg.com/564x/76/37/b7/7637b76b887023f12e052e5fe2a7f631.jpg',
  'https://i.pinimg.com/736x/1c/41/a1/1c41a1f8cdbc9e6405d9a49727a4102a.jpg',
  'https://i.pinimg.com/564x/d5/b1/37/d5b137200e5fae1e4f85cece087106ba.jpg',
  'https://i.pinimg.com/564x/73/7a/8e/737a8e836c1201562927279dfdba0c08.jpg',
  'https://i.pinimg.com/564x/94/1a/d1/941ad16035c34cd53be602c19f10eae3.jpg',
  'https://i.pinimg.com/736x/fa/36/59/fa36594a49c87d354b342f8f7a612e4b.jpg',
  'https://i.pinimg.com/736x/9f/1a/ab/9f1aab0044141f31c3f4626643258abf.jpg',
  'https://i.pinimg.com/736x/a3/11/15/a311156dd98371f80c19b4e07e714e59.jpg',
  'https://i.pinimg.com/564x/f5/49/c0/f549c07979c33a3092fedef35a22ab5d.jpg',
  'https://i.pinimg.com/736x/e6/0b/ea/e60bea4af0d03c1e7c12df9b2bd0521a.jpg',
  'https://i.pinimg.com/564x/59/3e/6f/593e6f9d8ae3c10fdf6d959339a66736.jpg',
  'https://i.pinimg.com/564x/7d/d6/6d/7dd66d49c889832f192d9e5fa0db47a1.jpg',
  'https://i.pinimg.com/736x/ac/a4/a6/aca4a6080e98bdac6f0035ca69ce83f5.jpg',
  'https://i.pinimg.com/564x/bd/e1/ff/bde1ff7e0b0bae5d2fd1f3f176371112.jpg',
  'https://i.pinimg.com/736x/7c/86/16/7c8616ba739622d6c3b45e4dc5dd7a45.jpg',
  'https://i.pinimg.com/736x/42/ce/59/42ce59657fa4bd2333a5f8987a390cf2.jpg',
  'https://i.pinimg.com/564x/78/b5/51/78b551a6ed3f40758601a5396caefe6c.jpg',
  'https://i.pinimg.com/564x/6a/77/0b/6a770bfedcfc49dc0c294d20349a2dd9.jpg',
  //  - Girl
  'https://img.wallscloud.net/uploads/thumb/3681101357/cyberpunk-girl-1-58808-1024x576-MM-80.webp',
  'https://img.wallscloud.net/uploads/thumb/4173644406/cyberpunk-1-59331-1024x576-MM-80.webp',
  'https://wallpapercave.com/wp/wp6364312.jpg',
  'https://wallpapercave.com/wp/wp9651627.jpg',
  'https://wallpapercave.com/wp/wp6364332.jpg',
  'https://images5.alphacoders.com/572/572950.jpg',
  'https://i.pinimg.com/originals/51/3e/9a/513e9a0039f6e4f3f2deb8569510c0e6.jpg',
  'https://e0.pxfuel.com/wallpapers/238/981/desktop-wallpaper-starcraft-ghost-art.jpg',
  'https://img.wallscloud.net/uploads/thumb/493108725/cyberpunk-girl-1-59084-1024x576-MM-80.webp',
  // Mystical
  'https://i.pinimg.com/originals/4f/6b/3b/4f6b3b7a30b6fcc6c0e76517ab4dd1df.jpg',
  'https://i.pinimg.com/564x/52/f4/25/52f4251c4085a148173f912d5895e617.jpg',
  'https://cdn.80.lv/api/upload/post/3677/images/5d2c89643a5c3/widen_2440x0.jpg',
  'https://s.yimg.com/uu/api/res/1.2/qjPWidgRzR9Yj6fM5r8oXg--~B/Zmk9ZmlsbDtoPTQ4Mjt3PTg3NTthcHBpZD15dGFjaHlvbg--/https://o.aolcdn.com/hss/storage/midas/ddef7394ecadbda0254af22448a73f67/204656923/FNAC_Venus_Warp_Gate_1410173250_tif_jpgcopy.jpg.cf.jpg',
  'https://i.pinimg.com/736x/6f/a7/0a/6fa70a305a03b34770ecfa4b316a5a6e.jpg',
  'https://i.pinimg.com/originals/51/6e/f0/516ef0273355f7f61899238b2ab524c7.jpg',
  // City
  'https://i.pinimg.com/564x/32/c4/f0/32c4f00a07032431f69420a209cbf072.jpg',
  'https://i.pinimg.com/564x/c4/f8/13/c4f813107ba8c5d54191d84a4a871595.jpg',
  'https://i.pinimg.com/564x/bc/0a/79/bc0a797e4459a90ed52f4e8a36113d73.jpg',
  'https://cdnb.artstation.com/p/assets/images/images/022/060/131/large/mitchell-stuart-mitchellstuart-platform-artstn-01.jpg?1573977921',
  'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/cddfa585-0197-4c1c-a6ba-b75632f25625/dg0ioj3-8b3ea866-fa18-4f49-9fce-16f440d7b714.png/v1/fill/w_1212,h_659,q_70,strp/futuristic_city__concept_art_by_exclusiveartmaker193_dg0ioj3-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2NkZGZhNTg1LTAxOTctNGMxYy1hNmJhLWI3NTYzMmYyNTYyNVwvZGcwaW9qMy04YjNlYTg2Ni1mYTE4LTRmNDktOWZjZS0xNmY0NDBkN2I3MTQucG5nIiwiaGVpZ2h0IjoiPD0xMDQ0Iiwid2lkdGgiOiI8PTE5MjAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uud2F0ZXJtYXJrIl0sIndtayI6eyJwYXRoIjoiXC93bVwvY2RkZmE1ODUtMDE5Ny00YzFjLWE2YmEtYjc1NjMyZjI1NjI1XC9leGNsdXNpdmVhcnRtYWtlcjE5My00LnBuZyIsIm9wYWNpdHkiOjk1LCJwcm9wb3J0aW9ucyI6MC40NSwiZ3Jhdml0eSI6ImNlbnRlciJ9fQ.O-MVOni8h4xWN59-5Ar63Gu7nrQnFeul-dMWN9lQXX4',
  'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/25e26e66-993b-407b-97d5-4987bc8c7d16/dg45sph-9fb4dd03-ddb5-4293-9ddc-6fd265599d8a.jpg/v1/fill/w_1189,h_672,q_70,strp/scifi_city_6_by_taliarebellious_dg45sph-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTA4NSIsInBhdGgiOiJcL2ZcLzI1ZTI2ZTY2LTk5M2ItNDA3Yi05N2Q1LTQ5ODdiYzhjN2QxNlwvZGc0NXNwaC05ZmI0ZGQwMy1kZGI1LTQyOTMtOWRkYy02ZmQyNjU1OTlkOGEuanBnIiwid2lkdGgiOiI8PTE5MjAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.VmI_dzva33Dd7yownv6KXDOLrIbFeno3xdZIcuEeR6w',
  'https://cdna.artstation.com/p/assets/images/images/038/175/352/large/josef-barton-neoncityprev.jpg?1622395123',
  // - Ninja / Blade / Gunner
  'https://img.wallscloud.net/uploads/thumb/1775280249/samurai-1-57452-1024x576-MM-80.webp',
  'https://w.wallhaven.cc/full/9d/wallhaven-9dzl2d.jpg',
  'https://wallscloud.net/img/resize/1920/1080/MM/2022-11-24-cyberpunk-1-55495.jpg',
  'https://preview.redd.it/dhhhv587pkc21.png?width=960&crop=smart&auto=webp&s=a7a598296244e4bf819b5a6fd5bfdc9bf7cfb033',
  'https://preview.redd.it/72x61a1v5zv41.png?width=960&crop=smart&auto=webp&s=d2a56cffc34a9a6d7468d088c9d07f20e40a1251',
  'https://preview.redd.it/pri7mesiu9751.png?width=960&crop=smart&auto=webp&s=1ccae3ec90327c8dd55d6889a88db5475075ed69',
  'https://wallscloud.net/img/resize/1600/900/MM/2023-05-05-ghost-runner-1-59010.png',
  // Cyberpunk
  'https://www.pcgamesn.com/wp-content/sites/pcgamesn/2018/09/cyberpunk-2077.jpg',
  'https://cdnb.artstation.com/p/assets/images/images/004/070/599/large/manthos-lappas-fierce-edge-artwork-full-res-jpg.jpg?1480020985',
  'https://images.hdqwalls.com/download/ciri-the-witcher-x-cyberpunk-4k-6d-1920x1080.jpg',
  'https://cdn.mos.cms.futurecdn.net/zpeDhNxL6nif6wpkX3Ds8e-1200-80.jpg.webp',
  'https://cdnb.artstation.com/p/assets/images/images/015/562/935/large/david-legnon-render070000d.jpg?1548810134',
  'https://img.wallscloud.net/uploads/thumb/1088651268/killer-1-56165-1024x576-MM-80.webp',
  // Misc
  'https://i.pinimg.com/originals/82/dc/21/82dc215243da039ad2dbef8aad27b77c.jpg',
  'https://i.pinimg.com/originals/60/82/78/60827886a2bb485dd600b72b9bef922a.jpg',
  'https://i.pinimg.com/564x/7e/94/61/7e9461829af4bc11e693180336c471d7.jpg',
  'https://i.pinimg.com/originals/f1/b3/ed/f1b3ed81b80963977ae18234aad78abf.jpg',
  'https://w0.peakpx.com/wallpaper/428/908/HD-wallpaper-devil-may-cry-5-vergil-yamato.jpg',
  'https://www.pixel4k.com/wp-content/uploads/2019/03/spiderman-miles-lost-in-space-4k_1553071367.jpg.webp',
  // DMC
  'https://www.reddit.com/media?url=https%3A%2F%2Fi.redd.it%2F0zgc2y925cc91.jpg',
  'https://wallpapercave.com/wp/wp5709621.jpg',
  // 'https://rare-gallery.com/resol/1366x768/341675-Devil-May-Cry-5-Video-Game-Dante-Vergil.jpg',
  // 'https://rare-gallery.com/resol/1920x1080/318777-Devil-May-Cry-5-Vergil-Katana-4K.jpg',
  // 'https://rare-gallery.com/resol/1366x768/318768-Devil-May-Cry-5-Vergil-Devil-Trigger-4K.jpg',
  'https://wallpapercave.com/dwp1x/wp7065892.jpg',
  'https://e0.pxfuel.com/wallpapers/462/12/desktop-wallpaper-devil-may-cry-5.jpg',
  // GameDev / Playstation
  'https://i.pinimg.com/originals/41/a2/da/41a2dab8c153b7e225508b7f9d65c79c.jpg',
  'https://i.pinimg.com/originals/8f/2b/8d/8f2b8d2d3efaeec43dcbc371d1e90be8.jpg',
  'https://i.pinimg.com/originals/29/76/6c/29766c2ad11ce9d60958864b6498c20b.jpg',
  'https://i.pinimg.com/originals/32/bf/70/32bf705f631c01ec2293734c1b1cc3f4.jpg',
  'https://i.pinimg.com/originals/73/cd/d4/73cdd4a3b3d9e80b34f64bca8442913f.jpg',
  'https://i.pinimg.com/originals/1b/ff/bb/1bffbbac86b5cbd7f6268ff561750873.jpg',
  'https://i.pinimg.com/originals/ee/e2/2f/eee22ff4f6d5260a5f46facd2e6a1d5c.jpg',
  'https://i.pinimg.com/originals/5f/ba/47/5fba4760a61a2816a396a651e277b25a.jpg',
  'https://i.pinimg.com/originals/be/57/cf/be57cfe3aafa68f453a5e0c8705d432e.jpg',
  'https://i.pinimg.com/originals/bd/d7/d6/bdd7d6872092080ba5e4c8a649265918.jpg',
  'https://i.pinimg.com/originals/82/41/b7/8241b72a580b3a2bba5e08d6a1c3fd17.png',
  'https://i.pinimg.com/originals/b5/24/39/b5243955324354a48d746f04fd9218cc.jpg',
  'https://i.pinimg.com/originals/36/e9/42/36e942a59253a6b082cd587bf120a48a.jpg',
  'https://i.pinimg.com/originals/c8/f4/76/c8f476e2754fe5d1a4b9fc26f49fb034.jpg',
  'https://i.pinimg.com/originals/12/0d/04/120d04fdd2c047920bc7c1b55535f1e3.jpg',
  'https://i.pinimg.com/originals/22/ac/19/22ac19399cdc0bf66256d081220ab64c.jpg',
  'https://i.pinimg.com/originals/cd/c3/58/cdc358252ca6fa1b2523123a0abbb4cf.jpg',
  'https://i.pinimg.com/originals/2b/4b/41/2b4b41609847917ab1a037b8cc79af46.jpg',
  'https://i.pinimg.com/originals/5f/ba/47/5fba4760a61a2816a396a651e277b25a.jpg',
];

export const messages = [
  'Welcome to Vision',
  'Ideas, AI, Action!',
  'Create and Innovate',
  'Login to your world',
  'Enter the creative zone',
  'Start your journey here',
  'Access your imagination',
  'Embark on your quest',
  'Welcome to the creative hub',
  'Your ideas, your space',
  'Discover, login, create',
  'Empower your vision',
  'Enter the realm of possibilities',
  'Craft your masterpiece',
  'Dive into creativity',
  'Login and innovate',
  'Open the doors to creation',
  'Step into the studio',
  'Fuel your passion, login',
  'Welcome to your canvas',
  'Unleash your potential',
  'Login for limitless creativity',
  'Let your ideas flow',
  'Expand your horizons, log in',
  'Discover your inner artist',
  'Begin your creative journey',
  'Unlock your imagination',
  'Embark on a creative adventure',
  'Join the creativity community',
  'Where ideas come to life',
  'Create, innovate, explore',
  'Empower your creative vision',
  'Explore endless possibilities',
  'Shape your creative vision',
  'Immerse yourself in creativity',
  'Start crafting your dreams',
  'Ignite your creativity, log in',
  'Welcome to the world of creation',
  'Unleash your creative potential',
  'Where creativity knows no bounds',
  'Let your imagination run wild',
  'Join the creative revolution',
  'Embrace your creative spirit, log in',
];

export const welcomeMessages = [
  'Explore a world of possibilities, enhanced by artificial intelligence!',
  'Unlock the potential of your imagination, fueled by cutting-edge A.I. technology!',
  'Welcome to a realm where your ideas come to life, powered by A.I. innovation!',
  'Step into a universe where creativity meets artificial intelligence, igniting endless possibilities!',
  'Join us on a journey where your dreams are transformed into reality, guided by the magic of A.I.!',
  'Embark on an adventure where your visions are amplified by the wonders of artificial intelligence!',
  'Enter a space where innovation knows no bounds, fueled by the intelligence of tomorrow!',
  'Welcome to a playground of creativity, where A.I. sparks new ideas at every turn!',
  'Discover a sanctuary where your imagination takes flight, powered by the intelligence of the future!',
  'Join our community and unleash the full potential of your ideas, supercharged by A.I.!',
  //
  'Empower your ideas with the force of A.I. innovation!',
  'Transform your concepts into reality with cutting-edge A.I. power!',
  'Welcome to a realm of limitless creativity, driven by A.I. excellence!',
  'Where genius meets technology, your imagination takes flight!',
  'Unleash your potential in a world sculpted by A.I. brilliance!',
  'Step into the future of creativity, powered by A.I. ingenuity!',
  'Welcome to a space where innovation is the norm and creativity thrives!',
  'Where imagination meets intelligence, extraordinary things happen!',
  'Experience the magic of A.I. as it elevates your creative journey!',
  'Unlock new dimensions of creativity with the magic of A.I.!',
  'Explore boundless horizons with the assistance of cutting-edge A.I. technology!',

  'Unleash the full potential of your ideas with the aid of advanced artificial intelligence!',
  'Welcome to a world where innovation is limitless, powered by the intelligence of tomorrow!',
  'Step into a realm where creativity and artificial intelligence converge, opening doors to infinite possibilities!',
  'Join us on a voyage where your aspirations are brought to life through the marvels of A.I.!',
  'Embark on an odyssey where your visions are magnified by the marvels of artificial intelligence!',
  'Enter a domain where creativity knows no bounds, driven by the intellect of the future!',
  'Welcome to a playground of innovation, where A.I. sparks inspiration at every juncture!',
  'Discover a haven where your imagination soars, empowered by the intelligence of tomorrow!',
  'Join our community and witness the transformation of your concepts, turbocharged by A.I.!',
  'Empower your concepts with the brilliance of A.I. innovation!',
  'Turn your ideas into reality with the unparalleled power of A.I.!',
  'Welcome to a universe of endless creativity, propelled by A.I. excellence!',
  'Where brilliance intersects with technology, your imagination flourishes!',
  'Unleash your potential in a world shaped by A.I. brilliance!',
  'Step into the forefront of creativity, driven by the ingenuity of A.I.!',
  'Welcome to a realm where innovation reigns supreme and creativity flourishes!',
  'Where imagination merges with intelligence, extraordinary creations emerge!',
  'Experience the enchantment of A.I. as it enriches your creative journey!',
  'Unlock new realms of creativity with the enchanting power of A.I.!'

];