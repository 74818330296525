import { useAppTheme } from 'hooks';
import React, { useEffect, useState } from 'react';

interface TextAreaProps {
  value: string,
  onChange: Function,
  label?: string,
  id?: string,
  limit?: number,
  style?: any,
  className?: string,
  ref?: any,
  name?: string,
}

export const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  id,
  label,
  limit,
  style,
  type,
  className,
  name,
  ...rest
}: any) => {
  const { theme } = useAppTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [isLabelLifted, setIsLabelLifted] = useState(false);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const newText = event.target.value;

    if (limit) {
      if (newText.length <= limit) {
        onChange(newText);
      }
    } else {
      onChange(newText);
    }
  };

  useEffect(() => {
    const isLifted = isFocused || value?.length > 0 || true;

    setIsLabelLifted(isLifted);
  }, [isFocused, value]);

  return (
    <div className="w-full">
      <label
        onClick={(e) => e.stopPropagation()}
        className={`absolute transition-all opacity-60 ${isLabelLifted ? '-top-4 text-xs' : 'top-0 text-md'
          }`}
      >
        {label}
      </label>
      <textarea
        id={id}
        type={type}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleTextChange}
        style={{
          backgroundColor: theme?.BACKGROUND,
          border: `${isFocused ? `2px solid ${theme?.PRIMARY}` : '1px solid #88888888'}`,
          borderRadius: '4px',
          color: theme?.TEXT,
          paddingBottom: '0.5rem',
          paddingLeft: '0.3rem',
          paddingRight: '0.3rem',
          paddingTop: '0.5rem',
          ...style,
        }}
        className={className}
        name={name}
        {...rest}
      />
      {
        limit ? (
          <div className="-mt-2">
            {value?.length || 0} / {limit}
          </div>
        ) : null
      }
    </div>
  );
};

export default TextArea;
