import { useAppTheme } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import Typography from '../Typography/Typography';
import { View } from '../View/View';

interface AudioPlayerProps {
  isExpanded?: boolean;
  data: string;
  title?: string;
  autoPlay?: boolean;
  playlist?: {
    url: string;
    name: string;
    duration?: number;
  }[];
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({
  data,
  title,
  autoPlay,
  playlist,
  isExpanded,
}) => {
  const { theme } = useAppTheme();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.4);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [isShowingPlaylist, setIsShowingPlaylist] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const playlistRef = useRef<HTMLDivElement | null>(null); // Reference for the playlist

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch(error => {
            console.error('Error playing audio:', error);
          });
      } else {
        audioRef.current.pause();
      }
    }
  }, [data, isPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.1;
    }
  }, []);

  useEffect(() => {
    if (data && audioRef.current !== null) {
      audioRef.current.src = data;
      audioRef.current.load();
      setIsPlaying(autoPlay as boolean);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
  };

  const toggleVolumeSlider = () => {
    setShowVolumeSlider(!showVolumeSlider);
  };

  const togglePlaylistVisibility = () => {
    setIsShowingPlaylist(prev => !prev);
  };

  const handleTrackSelect = (index: number) => {
    setActiveIndex(index);
    if (playlist && playlist[index]) {
      audioRef.current!.src = playlist[index].url;
      audioRef.current!.load();
      setIsPlaying(true);
    }
  };

  // Close playlist if clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (playlistRef.current && !playlistRef.current.contains(event.target as Node)) {
      setIsShowingPlaylist(false);
    }
  };

  useEffect(() => {
    // Close playlist if isExpanded is false
    if (!isExpanded) {
      setIsShowingPlaylist(false);
    }

    // Attach event listener for outside clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpanded]);

  return (
    <div className="flex flex-row items-center">
      <button
        onClick={togglePlay}
        style={{ border: `1px solid ${theme?.TEXT}88` }}
        className="px-2 py-0 rounded-full flex flex-row justify-center items-center"
      >
        {isPlaying
          ? <FontAwesome
            name="pause"
            style={{
              color: `${theme?.TEXT}88`,
              fontSize: '0.7rem',
              marginLeft: '0.1rem',
              marginRight: '0.1rem',
            }} />
          : <FontAwesome
            name="play"
            style={{
              color: `${theme?.TEXT}88`,
              fontSize: '0.7rem',
              marginLeft: '0.2rem',
            }}
          />
        }
      </button>
      {showVolumeSlider && (
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={volume}
          onChange={handleVolumeChange}
        />
      )}

      <View flex flexRow justify='between' items='center' className='px-2 relative w-full'>
        {isExpanded && isShowingPlaylist ? (
          <div ref={playlistRef}
            className="playlist absolute mt-12 z-10 overflow-hidden"
            style={{
              backgroundColor: theme?.BACKGROUND,
              border: `1px solid ${theme?.TEXT_LIGHT}`,
              borderRadius: '0.5rem',
              boxShadow: `0 -2px 10px 1px ${theme?.TEXT}33`,
              color: theme?.TEXT,
              maxHeight: '13.5rem',
              overflowY: 'auto',
              width: 'calc(100% - 1rem)',
            }}
          > {/* Added ref and styling */}
            {playlist && playlist.map((item, index) => (
              <div
                key={index}
                onClick={() => handleTrackSelect(index)}
                style={{
                  // backgroundColor: activeIndex === index ? theme?.PRIMARY : 'transparent',
                  backgroundColor: 'transparent',
                  borderBottom: `1px solid ${theme?.TEXT_LIGHT}88`,
                  cursor: 'pointer',
                  margin: '0.2rem 0',
                  padding: '0.5rem'
                }}
              >
                <Typography color={activeIndex === index ? theme?.PRIMARY : theme?.TEXT_LIGHT}>{index + 1}. {item.name} - {item.duration ? item.duration : 'N/A'}</Typography>
              </div>
            ))}
          </div>
        ) : (
          isExpanded && playlist && playlist.length > 1 ? (
            <Typography color={theme?.TEXT_LIGHT}>
                {playlist[activeIndex]?.name}
                <Typography color={theme?.TEXT_LIGHT} size={'sm'} className='ml-2'>
                  ({activeIndex + 1}/{playlist.length})
                </Typography>
            </Typography>
          ) : null
        )}

        {isExpanded && (
          <FontAwesome
            onClick={togglePlaylistVisibility}
            name={isShowingPlaylist ? 'caret-up' : 'list'}
            style={{
              color: `${theme?.TEXT}88`,
            }}
          />
        )}
      </View>
      <audio ref={audioRef} controls={false} autoPlay={autoPlay} />
    </div>
  );
};

export default AudioPlayer;
