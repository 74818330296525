import React, { useState } from 'react';
import './ContentRating.scss';
import { IContentRating, contentRatings } from './data';
import { useAppTheme } from 'hooks';
import View from '../View/View';
import Typography from '../Typography/Typography';
import Dropdown from '../Dropdown/Dropdown';
import { toSentenceCase } from '@vision/core';

interface ContentRatingProps {
  selectedRatings: string[]; // List of selected rating ids
  onChange?: (selectedRatings: string[]) => void;
}

export const ContentRating: React.FC<ContentRatingProps> = ({
  selectedRatings = [],
  onChange,
}) => {
  const allCategories = ['games', 'movies', 'tv', ' audio', 'podcasts'];
  const [categories, setCategories] = useState<string>(allCategories.join(','));
  const [activeRatings, setActiveRatings] = useState<string[]>(selectedRatings);

  const { theme } = useAppTheme(); // Assuming you have a theme context

  const primaryColor = theme?.PRIMARY || '#0067FF'; // Default to blue if no theme

  const toggleRating = (ratingId: string): void => {
    const updatedRatings = activeRatings.includes(ratingId)
      ? activeRatings.filter((id) => id !== ratingId)
      : [...activeRatings, ratingId];

    setActiveRatings(updatedRatings);

    if (onChange) {
      onChange(updatedRatings);
    }
  };

  return (
    <View className="content-rating w-full" flex flexCol justify='start' items='start'>
      <View className='w-full'>
        <Dropdown
          isMulti
          value={categories}
          onChange={setCategories}
          options={allCategories?.map((item: string) => ({
            label: toSentenceCase(item),
            value: item
          }))}
        />
      </View>
      <View className="rating-info">
        {contentRatings ?
          contentRatings?.filter((rating: IContentRating) =>
            rating.industries.some(industry => categories.includes(industry)) || true
          )
            .map((rating: IContentRating) => {
              const isActive = activeRatings.includes(rating.id);
              return (
                <View key={rating.id} flex flexRow items='center' justify='start'>
                  <View
                    className="content-type cursor-pointer"
                    onClick={() => toggleRating(rating.id)}
                    style={{
                      backgroundColor: isActive ? theme?.PRIMARY : theme?.TEXT,
                      border: `2px solid ${isActive ? primaryColor : theme?.TEXT}`,
                      borderRadius: '50%',
                      color: isActive ? '#FFF' : theme?.BACKGROUND,
                      // Adjust width as needed
                      height: '5rem',
                      margin: '8px 0',
                      opacity: isActive ? 1 : 0.6,
                      padding: '8px',
                      textAlign: 'center',
                      width: '5rem'
                    }}
                  >
                    <strong>{rating.symbol}</strong>
                  </View>
                  <View>
                    <Typography weight='700' className='mx-4'>{rating.name}</Typography>
                    <Typography>{rating.description}</Typography>
                  </View>
                </View>
              );
            }) : null}

      </View>
    </View>
  );
};

export default ContentRating;
