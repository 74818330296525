export interface IContentRating {
    id: string; // e.g. 'r'
    name: string; // e.g. 'Restricted'
    symbol: string; // e.g. 'R'
    description: string; // Age restrictions and content description
    industries: string[]; // e.g. ['movies', 'games']
}

export const contentRatings: IContentRating[] = [
    // Movies
    {
        description: 'Suitable for all ages. No content that would offend parents if viewed by children.',
        id: 'g',
        industries: ['movies'],
        name: 'General Audience',
        symbol: 'G'
    },
    {
        description: 'Some material may not be suitable for children. Parents are urged to provide guidance.',
        id: 'pg',
        industries: ['movies'],
        name: 'Parental Guidance',
        symbol: 'PG'
    },
    {
        description: 'Some material may be inappropriate for children under 13. Parents are urged to be cautious.',
        id: 'pg-13',
        industries: ['movies'],
        name: 'Parents Strongly Cautioned',
        symbol: 'PG-13'
    },
    {
        description: 'Restricted to viewers 17 and older unless accompanied by an adult. Contains adult material such as strong language, violence, or sexual content.',
        id: 'r',
        industries: ['movies'],
        name: 'Restricted',
        symbol: 'R'
    },
    {
        description: 'No one 17 and under is admitted. Contains explicit adult content that parents are urged to avoid for children.',
        id: 'nc-17',
        industries: ['movies'],
        name: 'Adults Only',
        symbol: 'NC-17'
    },

    // Games
    {
        description: 'Suitable for all ages. Content is generally suitable for children, with no explicit language or violence.',
        id: 'e',
        industries: ['games'],
        name: 'Everyone',
        symbol: 'E'
    },
    {
        description: 'Content is suitable for children aged 10 and older. May contain mild violence or suggestive themes.',
        id: 'e10+',
        industries: ['games'],
        name: 'Everyone 10+',
        symbol: 'E10+'
    },
    {
        description: 'Content is suitable for ages 13 and older. May contain violence, suggestive themes, or crude humor.',
        id: 't',
        industries: ['games'],
        name: 'Teen',
        symbol: 'T'
    },
    {
        description: 'Suitable for ages 17 and older. Content may include intense violence, blood and gore, sexual content, and strong language.',
        id: 'm',
        industries: ['games'],
        name: 'Mature',
        symbol: 'M'
    },
    {
        description: 'Content suitable only for adults aged 18 and older. Includes prolonged scenes of intense violence or graphic sexual content.',
        id: 'ao',
        industries: ['games'],
        name: 'Adults Only',
        symbol: 'AO'
    },

    // TV
    {
        description: 'Suitable for all children. Designed to be appropriate for all ages.',
        id: 'tv-y',
        industries: ['tv'],
        name: 'All Children',
        symbol: 'TV-Y'
    },
    {
        description: 'Suitable for children aged 7 and older. May contain mild fantasy violence.',
        id: 'tv-y7',
        industries: ['tv'],
        name: 'Older Children',
        symbol: 'TV-Y7'
    },
    {
        description: 'May contain material unsuitable for children under 14. Parents are urged to exercise greater care in monitoring this program.',
        id: 'tv-14',
        industries: ['tv'],
        name: 'Parents Strongly Cautioned',
        symbol: 'TV-14'
    },
    {
        description: 'Designed to be viewed by adults and may be unsuitable for children under 17. Contains explicit language, sexual content, or graphic violence.',
        id: 'tv-ma',
        industries: ['tv'],
        name: 'Mature Audience',
        symbol: 'TV-MA'
    },

    // Audio
    {
        description: 'Content suitable for all listeners. No offensive language, themes, or explicit content.',
        id: 'a-general',
        industries: ['audio'],
        name: 'General Audience',
        symbol: 'A-G'
    },
    {
        description: 'Content may contain mild language or suggestive themes but is generally suitable for most audiences.',
        id: 'a-pg',
        industries: ['audio'],
        name: 'Parental Guidance',
        symbol: 'A-PG'
    },
    {
        description: 'Content contains explicit language, violence, or themes not suitable for younger listeners. Listener discretion is advised.',
        id: 'a-explicit',
        industries: ['audio'],
        name: 'Explicit',
        symbol: 'A-E'
    },

    // Podcasts
    {
        description: 'Content suitable for all listeners, designed to be appropriate for a general audience.',
        id: 'p-general',
        industries: ['podcasts'],
        name: 'General Audience',
        symbol: 'P-G'
    },
    {
        description: 'Content may include mild language or themes but is generally suitable for a broader audience. Parental guidance suggested.',
        id: 'p-pg',
        industries: ['podcasts'],
        name: 'Parental Guidance',
        symbol: 'P-PG'
    },
    {
        description: 'Content contains explicit language, themes, or discussions that may not be suitable for younger audiences. Explicit warnings are provided.',
        id: 'p-explicit',
        industries: ['podcasts'],
        name: 'Explicit',
        symbol: 'P-E'
    }
];
