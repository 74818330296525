import { IconName } from 'components/Core';

export type IAppNavBarItem = {
  isAdmin?: boolean;
  key?: string;
  name?: string;
  icon?: IconName;
  path?: string;
  onClick?: Function;
  status?: 'concept' | 'pre-alpha' | 'alpha' | 'beta' | 'ready';
  isDivider?: boolean;
}


export const whiteListedPaths = [
  // admin
  '/v1/admin',
  '/v1/admin/users',
  '/v1/admin/dashboard',
  '/v1/admin/finances',
  '/v1/admin/maintenance',
  '/v1/admin/marketing',
  '/v1/admin/messaging',
  '/v1/admin/environment',
  '/v1/admin/analytics',
  // users
  '/v1/dashboard',
  '/v1/projects',
  '/v1/workspaces',
  '/v1/multimedia',
  // FEATURES

  // creative
  '/v1/characters',
  '/v1/creatures',
  '/v1/factions',
  '/v1/locations',
  '/v1/objects',
  '/v1/transports',
  // management
  '/v1/documents',
  '/v1/controls',
  '/v1/dialogues',
  '/v1/sketches',
  '/v1/segments',
  '/v1/graphs',
  // '/v1/issues',
  // ai
  '/v1/ai',

  //
  // '/v1/multimedia',

  // misc
  '/v1/account'
];

export const navbarItems: IAppNavBarItem[] = [
  // {
  //   icon: 'workspaces',
  //   key: 'workspaces',
  //   name: 'App Workspaces',
  //   path: '/v1/workspaces',
  // },
  // {
  //   icon: 'projects',
  //   key: 'projects',
  //   name: 'Projects',
  //   path: '/v1/projects',
  // },
  // { isDivider: true },
  {
    icon: 'dashboard',
    key: 'dashboard',
    name: 'Dashboard',
    path: '/v1/dashboard',
  },
  { isDivider: true },

  {
    icon: 'characters',
    key: 'characters',
    name: 'Characters',
    path: '/v1/characters',
    status: 'beta',
  },
  {
    icon: 'factions',
    key: 'factions',
    name: 'Factions',
    path: '/v1/factions',
    status: 'beta',
  },
  {
    icon: 'location',
    key: 'locations',
    name: 'Locations',
    path: '/v1/locations',
    status: 'beta',
  },
  {
    icon: 'creatures',
    key: 'creatures',
    name: 'Creatures',
    path: '/v1/creatures',
    status: 'concept',
  },
  {
    icon: 'objects',
    key: 'objects',
    name: 'Objects',
    path: '/v1/objects',
    status: 'concept',
  },

  {
    icon: 'transports',
    key: 'transports',
    name: 'transports',
    path: '/v1/transports',
    status: 'concept',
  },
  { isDivider: true },
  {
    icon: 'ai',
    key: 'ai',
    name: 'AI',
    path: '/v1/ai',
    // status: 'pre-alpha'
  },
  { isDivider: true },
  {
    icon: 'document',
    key: 'documents',
    name: 'Documents',
    path: '/v1/documents',
    // status: 'beta'
  },
  {
    icon: 'controls',
    key: 'controls',
    name: 'Controls',
    path: '/v1/controls',
    // status: 'alpha'
  },
  {
    icon: 'sketches',
    key: 'sketches',
    name: 'Sketches',
    path: '/v1/sketches'
  },
  {
    icon: 'dialogue',
    key: 'dialogues',
    name: 'Dialogues',
    path: '/v1/dialogues',
  // status: 'alpha'
  },
  {
    icon: 'graph',
    key: 'graphs',
    name: 'Graphs',
    path: '/v1/graphs',
    // status: 'pre-alpha'
  },
  {
    icon: 'quests',
    key: 'segments',
    name: 'segments',
    path: '/v1/segments',
    // status: 'concept'
  },
  // { isDivider: true },
  // {
  //   icon: 'video',
  //   key: 'multimedia',
  //   name: 'multimedia',
  //   path: '/v1/multimedia'
  // }
  // {
  //     key: 'maps',
  //     name: 'Maps',
  //     path: '/v1/maps',
  //     icon: 'mountains',
  //     // status: 'pre-alpha'
  // },
];

export const adminNavItems: IAppNavBarItem[] = [
  {
    icon: 'dashboard',
    key: 'dashboard',
    name: 'dashboard',
    path: '/v1/admin/dashboard',
  },
  { isDivider: true },
  {
    icon: 'users',
    key: 'admin/users',
    name: 'users',
    path: '/v1/admin/users',
  },
  // {
  //   icon: 'features',
  //   key: 'admin/features',
  //   name: 'features',
  //   path: '/v1/admin/features',
  // },
  // {
  //   icon: 'services',
  //   key: 'admin/services',
  //   name: 'services',
  //   path: '/v1/admin/services',
  // },
  {
    icon: 'database',
    key: 'admin/environment',
    name: 'Environment',
    path: '/v1/admin/environment',
  },
  // {
  //   icon: 'maintenance',
  //   key: 'admin/maintenance',
  //   name: 'maintenance',
  //   path: '/v1/admin/maintenance',
  // },
  {
    icon: 'finances',
    key: 'admin/finances',
    name: 'finances',
    path: '/v1/admin/finances',
  },
  { isDivider: true },
  {
    icon: 'documents',
    key: 'admin/documents',
    name: 'docs',
    path: '/v1/admin/docs',
  },
  { isDivider: true },
  {
    icon: 'marketing',
    key: 'admin/marketing',
    name: 'marketing',
    path: '/v1/admin/marketing',
  },
  {
    icon: 'messaging',
    key: 'admin/messaging',
    name: 'messaging',
    path: '/v1/admin/messaging',
  },
  {
    icon: 'analytics',
    key: 'admin/analytics',
    name: 'analytics',
    path: '/v1/admin/analytics',
  },
  // { isDivider: true }
  // ...navbarItems
];
