import { ITranslation } from '.';

export const jp: ITranslation = {
  account: {
    billing: '請求',
    chooseTheme: 'テーマを選択',
    dark: 'ダーク',
    email: 'メールアドレス',
    features: '機能',
    light: 'ライト',
    name: '名前',
    profile: 'プロフィール',
    settingExplorerAutoExpand: '各機能のためにエクスプローラー内のフォルダーを自動的に展開する',
    settingExplorerEditOnSelect: 'エクスプローラーでアイテムを選択するとエディターを自動的に開く',
    settingNavbarExpanded: 'ナビゲーションバーを展開',
    settingPickLanguage: '言語を選択',
    settingPickTheme: 'テーマを選択',
    settingPlayMusicOnStartup: 'プロジェクト起動時に音楽を再生',
    settingShowClock: '時計を表示',
    signOut: 'サインアウト',
    subscription: 'サブスクリプション',
    title: 'アカウント',
  },

  ai: {
    featurePage: {
      description: 'AI生成コンテンツは創造的なワークフローを加速し、効率的に高品質なメディアリソースを制作するのに役立ちます。',
      details: [
        {
          content: '高度なAIモデルを活用して高品質なテキストコンテンツを迅速に生成し、執筆プロセスを強化し、創造性を刺激します。',
          title: 'AI駆動のテキスト生成'
        },
        {
          content: 'テキストプロンプトをAIで美しい画像に変換し、時間を節約し、創造的な成果を向上させます。',
          title: '革新的なAI画像作成'
        },
        {
          content: 'AIを使用して音声演技や効果音を含むオーディオコンテンツを生成し、オーディオ制作のワークフローを合理化します。',
          title: 'AI駆動のオーディオ制作'
        },
        {
          content: 'AI生成のビデオで複雑な視覚的ストーリーを作成し、ビデオ作成をより効率的でアクセスしやすくします。',
          title: 'AIによるビデオ生成'
        },
        {
          content: 'AIを使用してテキスト記述から詳細な3Dモデルを作成し、キャラクターや環境を簡単に活気づけます。',
          title: 'AI生成の3Dモデル'
        }
      ],
      key: 'ai'
    }

  },

  auth: {
    acceptTerms: '[利用規約](/legal)に同意します',
    alreadyHaveAccount: 'すでにアカウントをお持ちですか？',
    dontHaveAccount: 'アカウントを持っていませんか？',
    logIn: 'ログイン',
    logOut: 'ログアウト',
    signInWith: (provider: string) => `${provider}でサインインする`,
    signOut: 'サインアウト',
    signUp: 'サインアップ',
    singleSignOn: 'シングルサインオン',

    welcomeHeading: 'Visionへようこそ',
    welcomeSentence: '当プラットフォームへようこそ。',
  },

  blueprints: {
    description: '文書の作成を迅速化するためのドキュメントテンプレートのセット',
    title: '設計図',
  },

  characters: {
    description: '「ダイアログ」などで使用するキャラクターを生成および編集します。',
    docs: `
  # Vision Studio | キャラクター

Vision Studio の**キャラクター**機能は、ゲーム、映画、小説などのメディアプロジェクト用に詳細なキャラクタープロファイルを作成および管理する機能です。この機能を使用すると、性格、背景、特性などを定義するための包括的なツールが提供されます。

## 機能レイアウト

キャラクター機能は、キャラクター作成プロセス全体での組織と生産性を向上させるように構成されています。

### エクスプローラー

エクスプローラービューでは、プロジェクト内のすべてのキャラクタープロファイルが、「メインキャラクター」、「サポートキャラクター」、「NPC」などのフォルダにカテゴライズされて一覧表示されます。

### 空のビュー

キャラクター機能に入ると、新規ユーザー向けの導入ガイドやチュートリアルを提供する「はじめる」タブ、キャラクターの作成と管理に関するよくある質問に答える「FAQ」タブ、キャラクター作成技術のデモンストレーションを提供する「ビデオ」タブ、キャラクタープロファイルと属性のためのドキュメントとテンプレートを提供する「ドキュメント」タブ、JSON、CSV、PDF形式でキャラクタープロファイルをエクスポートできる「エクスポート」タブが表示されます。

### コンテンツビュー

エクスプローラーからキャラクタープロファイルを選択すると、コンテンツビューに情報が表示されます。ここでは、ユーザーはキャラクタープロファイルの詳細を表示および編集できます。

- **アクションバー**: プロファイルの編集、複製、保存、削除などのオプションを提供します。
- **ツールパネル**: 「編集」、「AI」、「インポート」などのタブを提供し、キャラクターの詳細を設定および強化します。
- **プレビュー**: 最終変更前にキャラクタープロファイルと変更をプレビューできます。

### 機能ツール

機能ツールパネルは、キャラクタープロファイルの作成と管理のための高度な機能を提供します。

- **作成/編集タブ**: \`ICharacter\`スキーマを使用してキャラクター属性を定義し、人口統計、特性、背景などを指定します。
- **AIタブ**: 指定された基準とテーマに基づいてキャラクタープロファイルを生成するためのAIツールを利用します。
- **インポートタブ**: JSON形式を使用して外部ソースからキャラクタープロファイルをインポートします。

## インポートとエクスポート

キャラクター機能は、効率的なコラボレーションとプロジェクト管理のためのシームレスなインポートおよびエクスポート機能をサポートしています。

### インポート

JSON形式を使用してキャラクタープロファイルと属性をインポートできます。これにより、データの互換性と整合性が確保されます。

### エクスポート

キャラクタープロファイルは、次の形式でエクスポートできます：
- JSON
- CSV
- PDF

空のビュー内のエクスポートタブを使用して、キャラクタープロファイルのコレクション全体またはサブセットをエクスポートして共有および保存できます。

## キャラクタープロファイルの作成と管理

### 新しいキャラクターの作成

新しいキャラクタープロファイルを作成するには、「はじめる」タブに移動し、+ ボタンをクリックします。これにより、キャラクターの人口統計、特性、背景を定義する「キャラクターツール」パネルが開きます。

### キャラクターの詳細の編集

キャラクタープロファイルを編集することで、人口統計、特性、背景などを「編集」タブを使用して変更できます。

### AI支援キャラクター生成

「キャラクターツール」パネルの「AI」タブを使用すると、機械学習アルゴリズムを活用して、ユーザーが定義した基準とテーマに基づいてキャラクタープロファイルを提案できます。

### キャラクタープロファイルのインポートとエクスポート

「インポート」タブを使用して、外部ソースからJSON形式でキャラクタープロファイルをインポートします。また、「エクスポート」タブでは、JSON、CSV、またはPDF形式でキャラクタープロファイルをエクスポートして共有および協力することができます。

## よくある質問

以下は **キャラクター** 機能に関するよくある質問です：

1. **新しいキャラクタープロファイルを作成する方法は？**
   - 「はじめる」タブに移動し、+ ボタンをクリックして「キャラクターツール」パネルを開きます。必要な人口統計、特性、背景を定義します。

2. **他のプロジェクトからキャラクタープロファイルをインポートできますか？**
   - はい、「インポート」タブを使用して、外部ソースからJSON形式でキャラクタープロファイルをインポートできます。

3. **キャラクタープロファイルのエクスポートに対応しているフォーマットは？**
   - キャラクタープロファイルは、JSON、CSV、PDF形式でエクスポートできます。これにより、互換性と協力が確保されます。

4. **AIタブはどのようにキャラクタープロファイルの生成を支援しますか？**
   - AIタブは、ユーザーが定義したテーマと属性に基づいてキャラクタープロファイルを提案するために機械学習を利用します。

5. **作成できるキャラクタープロファイルの数に制限はありますか？**
   - キャラクタープロファイルの数には、サブスクリプションプランに基づいて制限があります。

6. **変更を最終確認する前にキャラクタープロファイルをプレビューできますか？**
   - はい、アクションバーの「プレビュー」オプションを使用して、キャラクタープロファイルと変更を視覚化できます。

7. **どのようなメディアプロジェクトがキャラクター機能を活用できますか？**
   - Vision Studioは、詳細なキャラクタープロファイルを必要とするゲーム、映画、小説などのメディアプロジェクトのキャラクター作成をサポートしています。

8. **チームメンバーと共にキャラクタープロファイルを共同作成できますか？**
   - はい、チームメンバーをワークスペースに招待してキャラクタープロファイルの作成と管理に協力できます。

9. **一般的なキャラクタープロファイル用の事前定義されたテンプレートはありますか？**
   - はい、「ドキュメント」タブを見て、キャラクタープロファイルの作成に関するテンプレートとガイドラインを探索できます。

10. **プロジェクトからキャラクタープロファイルを削除するにはどうすればよいですか？**
    - エクスプローラービューでキャラクタープロファイルを選択し、「アクションバー」で「削除」オプションを使用します。表示されるモーダルで削除を確認します。

## 付録
  `,
    faqs: [
      {
        answer: 'キャラクターパネルは、属性、コンセプトアート、バックストーリー、人口統計、所属など、ゲームデザインに特化したAI駆動のキャラクター生成機能を提供します。',
        question: 'ゲームデザインにおいて、キャラクターパネルはどのような機能を提供しますか？',
      },
      {
        answer: 'AIは、属性の提案、説明に基づくコンセプトアートの生成、説得力のあるバックストーリーの作成、人口統計と所属の決定を通じて、キャラクターデザインプロセスを効率化します。',
        question: 'AIはキャラクター生成にどのように役立ちますか？',
      },
      {
        answer: 'もちろんです。AIが基盤を提供しますが、キャラクターのあらゆる側面をカスタマイズして、ゲームのビジョンに完全に合致させる柔軟性があります。',
        question: '生成されたキャラクターをカスタマイズできますか？',
      },
      {
        answer: 'はい、キャラクターパネルはダイアログパネルとシームレスに統合されており、キャラクター情報を使用してダイアログ作成プロセスを効率化および改善します。',
        question: 'ダイアログのような他のパネルと統合されていますか？',
      },
      {
        answer: 'はい、さまざまな形式でキャラクタープロファイルをエクスポートできるため、ゲーム開発のワークフローの他の側面に簡単に統合できます。',
        question: 'キャラクタープロファイルを外部で使用するためにエクスポートできますか？',
      },
      {
        answer: '各キャラクターの人口統計、バックストーリー、スキル、能力、性格の特性を指定できます。',
        question: '--- キャラクターの詳細はどのように指定できますか？',
      },
      {
        answer: 'はい、コンセプトアートをアップロードして、キャラクターのテクスチャ付き3Dモデルを生成できます。',
        question: 'キャラクターのコンセプトアートをアップロードできますか？',
      },
      {
        answer: 'キャラクターは、エクスプローラーの検索可能なリストを使用してカスタムフォルダーで整理できます。',
        question: 'キャラクターをどのように整理できますか？',
      },
      {
        answer: 'はい、AIツールを使用してキャラクタープロファイルとバックストーリーを生成できます。',
        question: 'AIを使用してキャラクタープロファイルを生成できますか？',
      },
      {
        answer: 'キャラクターは、PDF、Docx、およびテクスチャ付き3Dモデル形式でエクスポートできます。',
        question: 'キャラクターのエクスポート形式はどのようなものがありますか？',
      },
      {
        answer: 'はい、特定の権限を持つ共同作業者を招待して、キャラクター作成に取り組むことができます。',
        question: '他の人と共同でキャラクター作成を行えますか？',
      },
      {
        answer: 'はい、詳細なキャラクタープロファイルの作成を支援するためのテンプレートとガイドラインがあります。',
        question: 'キャラクター作成のためのテンプレートはありますか？',
      },
      {
        answer: 'エクスプローラーからキャラクターを選択し、「キャラクターツール」を使用して詳細を編集します。',
        question: '既存のキャラクターを編集するにはどうすればよいですか？',
      },
      {
        answer: 'はい、アクションバーの「複製」オプションを使用してキャラクタープロファイルを複製できます。',
        question: 'キャラクタープロファイルを複製できますか？',
      },
      {
        answer: 'キャラクターを選択してアクションバーの「削除」オプションを使用します。削除前に確認ウィンドウが表示されます。',
        question: 'キャラクターを削除するにはどうすればよいですか？',
      }
    ],
    featurePage: {
      description: 'キャラクターは物語の心と魂であり、それぞれが独自の背景、個性、ストーリーアークを持っています。',
      details: [
        {
          content: '名前、背景、性格、視覚的表現など、詳細なキャラクタープロフィールを作成します。キャラクターに深みと信頼性を持たせて生き生きとさせましょう。',
          title: '詳細なキャラクター作成',
        },
        {
          content: 'キャラクターの説明に基づいて高品質のコンセプトアートを生成します。キャラクターを正確に視覚化し、世界構築の体験を向上させます。',
          title: '驚くべきコンセプトアート',
        },
        {
          content: 'キャラクターの3Dモデルを生成する実験を行います。これにより、物語に新たな次元が加わり、キャラクターがさらに魅力的になります。',
          title: '没入型の3Dモデル',
        }
      ],
      key: 'characters'
    },
    form: {
      abilities: '能力',
      alias: 'エイリアス',
      background: '背景',
      demongraphics: '人口統計',
      education: '教育',
      ethnicity: '民族',
      firstName: 'ファーストネーム',
      gender: '性別',
      identity: 'アイデンティティ',
      images: '画像',
      language: '言語',
      lastName: 'ラストネーム',
      name: '名前',
      nationality: '国籍',
      occupation: '職業',
      personality: '性格',
      sexuality: 'セクシュアリティ',
      skills: 'スキル',
    },
    title: 'キャラクター'
  },

  common: {
    behaviour: '行動',
    description: '説明',
    diet: '食事',
    folder: 'フォルダー',
    folders: 'フォルダー',
    fuelType: '燃料の種類',
    galleryIds: 'フォルダー',
    habitat: '生息地',
    lore: '伝承',
    material: '材料',
    plot: 'プロット',
    speed: '速度',

    abilities: '能力',
    abort: '中止',
    add: '追加',
    addItem: (value) => `${value}を追加`,
    admin: '管理者',
    alias: '別名',
    art: 'アート',
    artConcept: 'コンセプトアート',
    back: '戻る',
    background: '背景',
    cancel: '中止',
    close: '閉じる',
    confirm: '確認する',
    continue: '続ける',
    create: '作成',
    createItem: (value) => `${value}を作成`,
    daily: '毎日',
    day: '日',
    days: '日々',
    delete: '削除',
    deleteItem: (value) => `${value}を削除`,
    demographics: '人口統計',
    docs: 'ドキュメント',
    download: 'ダウンロード',
    duplicate: '複製',
    edit: '編集',
    editItem: (value) => `${value}を編集`,
    education: '教育',
    ethnicity: 'エスニシティ',
    explorer: 'エクスプローラー',
    export: 'エクスポート',
    exports: 'エクスポート',
    faqs: 'よくある質問',
    features: '機能',
    firstName: 'ファーストネーム',
    gender: '性別',
    getStarted: 'はじめる',
    hi: 'こんにちは',
    hour: '時間',
    hourly: '毎時',
    hours: '時間',
    identity: 'アイデンティティ',
    images: '画像',
    import: 'インポート',
    imports: 'インポート',
    itemExplorer: (value) => `エクスプローラー ${value}`,
    itemPreview: (value) => `プレビュー ${value}`,
    itemTools: (value) => `ツール ${value}`,
    language: '言語',

    lastName: 'ラストネーム',
    legal: '法的事項',
    minute: '分',
    minutely: '毎分',
    minutes: '分',
    month: '月',
    monthly: '毎月',
    months: '月々',
    name: '名前',
    nationality: '国籍',
    needHelp: 'ヘルプが必要',
    next: '次へ',
    occupation: '職業',
    overview: '概要',
    personality: '性格',
    preview: 'プレビュー',
    pricing: '価格設定',
    recentItems: (items: string) => `最近の${items}`,
    save: '保存',
    search: '検索',
    searchResource: (resource) => `${resource}を検索`,
    settings: '設定',
    sexuality: 'セクシュアリティ',
    skills: 'スキル',
    tools: 'ツール',
    webApp: 'ウェブアプリ',
    webSite: 'ウェブサイト',
    week: '週',
    weekly: '毎週',
    weeks: '週間',
    year: '年',
    yearly: '毎年',
    years: '年間',
  },

  components: {
    confirmActionHeading: (action: string, resource: string) => `${resource}を${action}`,
    confirmActionOnItem: (action: string, resource: string) => `この${resource}を${action}してもよろしいですか？`,
    confirmActionOnItemDescription: (primary: string, secondary: string) => `続行するには「${primary}」を押し、キャンセルするには「${secondary}」を押してください。`,
    featureGuideWelcomeP1: (featureName: string) => `*${featureName}*機能へようこそ。`,
    featureGuideWelcomeP2: (featureName: string) => `ここでは、新しい*${featureName}*を作成するか、既存のものを編集することができます。`,
    featureGuideWelcomeP3: (featureName: string) => `保存されたすべての*${featureName}*は、左側の「エクスプローラー」に表示されます。`,
    sidebarClose: 'サイドバーを閉じる',

  },

  controls: {
    featurePage: {
      description: 'コントロールはプロジェクト内のインタラクティブ要素を管理するツールを提供し、スムーズで魅力的なユーザーエクスペリエンスを確保します。',
      details: [
        {
          content: 'PlayStation、Xbox、Nintendo Switchなどの主要なプラットフォームに合わせたコントロールスキームを実装し、一貫したユーザーエクスペリエンスを保証します。',
          title: 'プラットフォーム固有のコントロール',
        },
        {
          content: 'プロジェクトを開始するために、事前に設定されたコントロールスキームのコレクションにアクセスし、必要に応じて微調整のためにカスタマイズします。',
          title: '広範なプリセットライブラリ',
        },
        {
          content: 'カスタムコントロールスキームを作成および管理し、\'戦闘\'対\'探索\'など、簡単にアクセスできるようにフォルダーに整理します。',
          title: '整理されたコントロールスキーム',
        },
      ],
      key: 'controls',
    }

  },

  creatures: {
    featurePage: {
      description: 'クリーチャーは、物語に命と多様性を加える動物や非人型キャラクターです。',
      details: [
        {
          content: '詳細で想像力豊かなクリーチャーデザインを作成し、世界にシームレスに統合されるようにします。',
          title: 'ユニークなクリーチャーデザイン'
        },
        {
          content: 'クリーチャーの行動、生息地、および生態学的役割を定義して、世界をより没入型で現実的なものにします。',
          title: '行動と生態学'
        },
        {
          content: '物語に魔法や神話の生き物を取り入れます。それぞれが独自の伝承や神秘的な能力を持っています。',
          title: '魔法や神話の生き物'
        }
      ],
      key: 'creatures'
    }
  },

  dashboard: {
    addItem: 'ドキュメントを追加',
    addProject: 'プロジェクトを追加',
    contentRating: 'コンテンツ評価',
    description: 'ドキュメントの作成、編集、閲覧を行います。',
    devTechnology: '開発技術',
    projectArt: 'コンセプトアート',
    projectArtDescription: 'Visionのさまざまな利用可能な機能のさまざまなコレクションからのプロジェクトのさまざまなアートワーク。',
    projectFeatures: 'プロジェクト機能',
    projectFeaturesDescription: '機能は、基礎となるリソースに影響を与えずに、プロジェクトに任意に追加および削除できます。',
    projectInfo: 'プロジェクト情報',
    projectInfoDescription: 'プロジェクトの公開情報を変更する',
    projects: '',
    releaseDate: 'リリース日',
    tableHeaders: {
      lastUpdated: '更新日',
      projectName: 'プロジェクト名',
      sections: 'セクション',
    },
    targetPlatform: 'ターゲットプラットフォーム',
    technology: '技術',
    title: 'ダッシュボード',
    usersWithAccess: 'アクセスできるユーザー',
    viewMore: 'もっと見る',

  },

  dialogues: {

  },

  documents: {
    description: '',
    featurePage: {
      description: 'ドキュメントは、さまざまな形式をサポートしながら、コンテンツの作成と整理における主要なツールです。',
      details: [
        {
          content: '豊富なフォーマットオプションを備えた強力なテキストエディターを利用して、詳細で視覚的に魅力的なドキュメントを作成します。',
          title: '高度なテキスト編集'
        },
        {
          content: 'フォルダーとサブフォルダーに文書をきちんと整理して、簡単にアクセスおよび管理できるようにします。',
          title: '効率的な文書管理'
        },
        {
          content: 'PDF、Markdown、Docx など、複数の形式で文書をインポートおよびエクスポートし、互換性と共有の容易さを確保します。',
          title: '多用途のインポート＆エクスポート'
        }
      ],
      key: 'documents'
    },
    title: ''

  },

  features: {
    '3D': '3D',
    account: 'アカウント',
    ai: 'AI',
    ai3D: 'AI 3D',
    aiAudio: 'AIオーディオ',
    aiExamples: 'AI例',
    aiImages: 'AI画像',
    aiText: 'AIテキスト',
    aiVideo: 'AIビデオ',
    asset: '資産',
    assets: '資産',
    audio: 'オーディオ',
    character: 'キャラクター',
    characters: 'キャラクター',
    control: 'コントロール',
    controls: 'コントロール',
    creature: 'クリーチャー',
    creatures: 'クリーチャー',
    dashboard: 'ダッシュボード',
    dialogue: 'ダイアログ',
    dialogues: 'ダイアログ',
    document: 'ドキュメント',
    documents: 'ドキュメント',
    examples: '例',
    graph: 'グラフ',
    graphs: 'グラフ',
    home: 'ホーム',
    homePage: 'ホームページ',
    illustration: 'イラストレーション',
    illustrations: 'イラストレーションズ',
    images: '画像',
    location: 'ロケーション',
    locations: 'ロケーション',
    media: 'メディア',
    multimedia: 'マルチメディア',
    object: 'オブジェクト',
    objects: 'オブジェクト',
    project: 'プロジェクト',
    projects: 'プロジェクト',
    quest: 'クエスト',
    quests: 'クエスト',
    setting: '設定',
    settings: '設定',
    subscription: 'サブスクリプション',
    subscriptions: 'サブスクリプション',
    text: 'テキスト',
    transport: 'トランスポート',
    transports: 'トランスポーツ',
    user: 'ユーザー',
    users: 'ユーザー',
    vehicle: '車両',
    vehicles: '車両',
    video: 'ビデオ',
    workspace: 'ワークスペース',
    workspaces: 'ワークスペース',
  },

  galleries: {
    'character': {
      'description': '「ダイアログ」などで使用するキャラクターを生成および編集します。',
      'title': 'キャラクターギャラリー'
    },
    description: '視覚的なコンセプトをブレインストームし、それをギャラリーコレクションにまとめる場所です。',
    image: {
      description: 'カスタムAIで生成された画像を生成します',
      enterPrompt: 'プロンプトを入力してください',
      pickStyle: 'アートスタイルを選択してください',
      title: '画像ギャラリー',
    },
    sound: {
      description: 'AIによって生成された音声クリップを制作します',
      title: 'サウンドギャラリー'
    },
    title: 'ギャラリー',
    video: {
      description: 'AIを使ってテキストから直接ビデオを制作します',
      title: 'ビデオギャラリー'
    }
  },

  generators: {
    description: 'この体験を可能にする基本的なAIジェネレーターのスイート',
    title: 'ジェネレーター',
  },

  graphs: {
    featurePage: {
      description: 'グラフはプロジェクト内のデータと関係を視覚的に表現し、明確さと洞察を提供します。',
      details: [
        {
          content: '複雑なデータと関係を視覚的に表現するためにグラフを作成およびカスタマイズし、理解と洞察を深めます。',
          title: '包括的なグラフ作成',
        },
        {
          content: 'キャラクターの関係、プロットポイント、およびその他のストーリー要素を説明するためにグラフを使用し、複雑な情報をよりアクセスしやすくします。',
          title: '効果的なデータ可視化',
        },
        {
          content: 'データを探索して操作できるインタラクティブなグラフを作成し、プロジェクトをより深く理解できるようにします。',
          title: 'インタラクティブなグラフ',
        },
      ],
      key: 'graphs',
    }

  },

  landing: {
    allFeatures: 'すべての機能',
    getAccess: 'アクセスする',
    sections: [
    ],

    watchVideo: 'ビデオを見る',
  },

  locations: {
    featurePage: {
      description: '場所は物語の設定を提供し、豊富な詳細とビジュアルであなたの世界を生き生きとさせます。',
      details: [
        {
          content: '豊かなテキストと画像で場所を定義し、説明して、あなたの物語を強化する没入型の環境を作り出します。',
          title: '詳細な場所の作成'
        },
        {
          content: 'インタラクティブな地図を使って場所とその接続を視覚化し、物語の世界を理解しやすくナビゲートしやすくします。',
          title: 'インタラクティブな地図の統合'
        },
        {
          content: '場所に歴史的および伝承に基づく情報を追加し、物語を豊かにする深みと文脈を提供します。',
          title: '豊かな場所の伝承'
        }
      ],
      key: 'locations'
    }

  },

  objects: {
    featurePage: {
      description: 'オブジェクトは、武器、装備、戦利品、通貨など、物語の中で使用、収集、交換できる無生物のアイテムです。',
      details: [
        {
          content: '各オブジェクトの使用法、起源、および物語の重要性を含む詳細な説明を作成します。',
          title: '詳細なオブジェクトの説明'
        },
        {
          content: '物語のオブジェクトを簡単に整理および管理し、その利用可能性と使用状況を追跡します。',
          title: 'インベントリ管理'
        },
        {
          content: 'オブジェクトの堅牢な貿易システムを開発し、物語の経済的側面を強化します。',
          title: '貿易と経済'
        }
      ],
      key: 'objects'
    }
  },

  pricing: {
    // Translations for the pricing section here
  },

  projects: {
    addProject: 'プロジェクトを追加',
    createProject: 'プロジェクトを作成',
    editor: {
      linkCopiedToClipboard: 'リンクがクリップボードにコピーされました',
    },
    featurePage: {
      description: 'プロジェクトは、すべての関連コンテンツやアイデアを一箇所に収め、あなたの創造的なビジョンの基盤となります。',
      details: [
        {
          content: 'ゲーム、テレビシリーズ、グラフィックノベルなど、特定のクリエイティブなアイデアに専念した複数のプロジェクトを作成して管理します。アイデアを整理して集中させます。',
          title: '多様なプロジェクト管理'
        },
        {
          content: '各プロジェクトをニーズに合わせたユニークな機能でカスタマイズします。データを失うことなく、いつでも機能を追加または削除して、柔軟性と適応性を確保します。',
          title: 'カスタマイズ可能な機能'
        },
        {
          content: 'インポート/エクスポート機能を使用して、プロジェクトをワークスペースやアカウント間で簡単に転送します。移行中にデータの完全性を維持します。',
          title: '手間のかからないインポート＆エクスポート'
        }
      ],
      key: 'projects'
    },
    generating: {
      // Translations for the generating section here
    },
    importProject: 'プロジェクトをインポート',
    newProject: '新しいプロジェクト',
    setup: {
      generate: '生成する',
      next: '次へ',
      projectName: 'プロジェクト名は何ですか？',
      projectPrompt: 'プロジェクトについての説明は何ですか？',
    }

  },

  transports: {
    featurePage: {
      description: 'トランスポートは、車両、ワームホール、ポータルガン、鉄道、タイムマシンなど、時空を通るあらゆる移動手段です。',
      details: [
        {
          content: '従来の車両から未来的なワームホールやタイムマシンまで、さまざまな輸送手段を探求してください。',
          title: '革新的な輸送手段'
        },
        {
          content: 'ハイパーループシステムからテレポーテーションデバイスまで、輸送技術の最新の進歩を発見してください。',
          title: '先進技術'
        },
        {
          content: '輸送方法の素晴らしいビジュアルを作成して、聴衆を時空間を旅する体験に没入させます。',
          title: '没入型ビジュアル'
        }
      ],
      key: 'transports'
    }

  },

  version: {
    currentVersion: '現在のバージョンは：',
    getVersion: '今すぐ取得！',
    header: 'ビジョンアップデート',
    newVersion: '新しいバージョン',
    updateNow: '今すぐ更新',
  },

  workspaces: {
    addWorkspace: 'ワークスペースを追加',
    addWorkspaceImage: 'ワークスペースの画像を追加',
    addWorkspaceName: 'ワークスペース名を追加',
    addWorkspaceUsers: 'ワークスペースのユーザーを追加',
    addWorkspaceUsersInfo: 'ワークスペースにユーザーを追加し、各ユーザーに権限を割り当て、このワークスペース内のすべてのプロジェクトをこれらと共有します。',
    chooseAWorkspace: 'ワークスペースを選択',
    createWorkspace: 'ワークスペースを作成',
    featurePage: {
      description: 'ワークスペースを使用すると、プロジェクトを整理し、チームメンバーと集中した環境でシームレスに協力することができます。',
      details: [
        {
          content: 'ゲーム、執筆など、さまざまな種類のプロジェクトのために個別のワークスペースを作成します。それぞれのワークスペースは、プロジェクトを整理し、コンテキストに特化しています。',
          title: 'プロジェクトごとに調整されたワークスペース'
        },
        {
          content: '特定のワークスペース内でチームメンバーを招待してコラボレーションします。アクセスを制御するために役割と権限を割り当て、すべてのメンバーが必要な情報を持っていることを確認します。',
          title: 'コラボレーションサポート'
        },
        {
          content: 'ダークまたはライトテーマでワークスペースをパーソナライズします。テーマ切り替えボタンを使用して簡単に切り替えることができ、生産性と快適性を向上させます。',
          title: 'カスタマイズ可能なテーマ'
        }
      ],
      key: 'workspaces'
    },
    permissions: '権限',
    toCreateProjectsIn: 'プロジェクトを作成するために'
  },
};


export default jp;