import { useAppTheme, useTranslations } from 'hooks';

import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Icon } from '../Icon/CustomIcon/CustomIcon';

interface CloseButtonProps {
  onClick?: () => void;
  showLabel?: boolean
}

export const CloseButton: React.FC<CloseButtonProps> = ({
  onClick,
  showLabel = true
}) => {
  const { t } = useTranslations();
  const { theme } = useAppTheme();

  return (
    <div className="flex flex-row items-center cursor-pointer hover:text-primary" onClick={() => {
      onClick?.();
    }}>
      <div className="m-1 cursor-pointer hover:text-primary">
        {/* <FontAwesome name="close" className="hover:text-primary" /> */}
        <Icon
          name="close"
          imageClass='-mt-2'
          color={theme?.TEXT}
        />
      </div>

      {showLabel && (
        <div className="ml-2 hover:text-primary">{t.common.close}</div>
      )}
    </div>
  );
};

export default CloseButton;