/* eslint-disable sort-keys */
import React from 'react';
// Admin
const AdminDashboard = React.lazy(() => import('./Admin/AdminDashboard'));
const AdminEnvironment = React.lazy(() => import('./Admin/Environment/AdminEnvironment'));
const AdminMarketing = React.lazy(() => import('./Admin/AdminMarketing'));
const AdminMessaging = React.lazy(() => import('./Admin/AdminMessaging'));
const AdminMaintenance = React.lazy(() => import('./Admin/Environment/AdminMaintenance'));
const AdminFinances = React.lazy(() => import('./Admin/Finances/AdminFinances'));
const AdminServices = React.lazy(() => import('./Admin/Environment/AdminServices'));
const AdminAnalytics = React.lazy(() => import('./Admin/AdminAnalytics'));
const AdminFeatures = React.lazy(() => import('./Admin/Environment/AdminFeatures'));
const AdminUsers = React.lazy(() => import('./Admin/Users/AdminUsers'));
const AdminDocs = React.lazy(() => import('./Admin/AdminDocs'));
const ApiEntertainment = React.lazy(() => import('./ApiEntertainment'));
const AuthLogin = React.lazy(() => import('./Auth/AuthLogin'));
const AuthSignUp = React.lazy(() => import('./Auth/AuthSignUp'));
const AppFeatureMaintenance = React.lazy(() => import('components/App/AppFeatureMaintenance'));
const PrivatePage = React.lazy(() => import('./Auth/PrivatePage'));
const Account = React.lazy(() => import('./_Misc/Account/Account'));
const AccountAddCard = React.lazy(() => import('./_Misc/Account/AccountAddCard'));
const Changelog = React.lazy(() => import('./_Misc/Changelog/Changelog'));
const VisionDashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const Objects = React.lazy(() => import('./Features/Objects/v1/Objects'));
const Projects = React.lazy(() => import('./Projects/Projects'));
const ProjectNew = React.lazy(() => import('./Projects/ProjectSetup/ProjectNew'));
const ProjectCreator = React.lazy(() => import('./Projects/ProjectSetup/ProjectCreator'));
const ProjectImporter = React.lazy(() => import('./Projects/ProjectSetup/ProjectImporter'));
// const Factions = React.lazy(() => import('./Features/Factions/v1/Factions'));
const Segments = React.lazy(() => import('./Features/Segments/v1/Segments'));
const Sketches = React.lazy(() => import('./Features/Sketches/v1/Sketches'));
const Workspaces = React.lazy(() => import('./Workspaces/Workspaces'));
const WorkspacesCreator = React.lazy(() => import('./Workspaces/WorkspaceCreator'));
const Characters = React.lazy(() => import('./Features/Characters/v1/Characters'));
const Controls = React.lazy(() => import('./Features/Controls/Controls'));
const Creatures = React.lazy(() => import('./Features/Creatures/v1/Creatures'));
const Documents = React.lazy(() => import('./Features/Documents/Documents'));
const DocumentEditor = React.lazy(() => import('./Features/Documents/Editor/DocumentEditor'));
const Locations = React.lazy(() => import('./Features/Locations/Locations'));
const Dialogues = React.lazy(() => import('./Features/Dialogues/v1/Dialogues'));
const Graphs = React.lazy(() => import('./Features/Graphs/Graphs'));
const Transports = React.lazy(() => import('./Features/Transports/v1/Transports'));
const AIContainer = React.lazy(() => import('./AI/AIContainer'));
const Chats = React.lazy(() => import('./AI/Chats/Chats'));
const Images = React.lazy(() => import('./AI/Images/Images'));
const IssueTracker = React.lazy(() => import('./IssueTracking/IssueTracker'));
const IssueLogger = React.lazy(() => import('./IssueTracking/IssueLogger'));
const Sounds = React.lazy(() => import('./AI/Sounds/Sounds'));
const Videos = React.lazy(() => import('./AI/Videos/Videos'));
const Landing = React.lazy(() => import('./_Misc/Landing/Landing'));
const Pricing = React.lazy(() => import('./_Misc/Pricing/Pricing'));
const WaitingList = React.lazy(() => import('./_Misc/WaitingList/WaitingList'));
const Features = React.lazy(() => import('./_Misc/Features/Features'));
const FeatureDocs = React.lazy(() => import('./_Misc/FeatureDocs/FeatureDocs'));
const LegalDocuments = React.lazy(() => import('./_Misc/Legal/Legal'));
const Download = React.lazy(() => import('./_Misc/Download/Download'));
const Docs = React.lazy(() => import('./_Misc/Docs/Docs'));
const NotFound = React.lazy(() => import('./_Misc/NotFound/NotFound'));

const routes: {
  path: string,
  element: React.ReactElement
}[] = [
    {
      element: (
        <PrivatePage>
          <ApiEntertainment />
        </PrivatePage>
      ),
      path: '/entertainment'
    },
    {
      element: (
        <PrivatePage>
          <IssueTracker />
        </PrivatePage>
      ),
      path: '/v1/issues'
    },
    {
      element: (
        <PrivatePage>
          <IssueLogger />
        </PrivatePage>
      ),
      path: '/v1/issues/log'
    },
    // Public routes
    {
      element: (
        <Landing />
      ),
      path: '/'
    },
    {
      element: (
        <AuthLogin />
      ),
      path: '/auth/login'
    },
    {
      element: (
        <AuthSignUp />
      ),
      path: '/auth/signup'
    },
    {
      element: (
        <Download />
      ),
      path: '/download'
    },
    {
      element: (
        <Pricing />
      ),
      path: '/pricing'
    },
    {
      element: (
        <Features />
      ),
      path: '/features'
    },
    {
      element: (
        <Features />
      ),
      path: '/features/:id'
    },
    {
      element: (
        <LegalDocuments />
      ),
      path: '/legal'
    },
    {
      element: (
        <LegalDocuments />
      ),
      path: '/legal/:id'
    },
    {
      element: (
        <Docs />
      ),
      path: '/docs'
    },
    {
      element: (
        <Docs />
      ),
      path: '/docs/:id'
    },
    {
      element: (
        <WaitingList />
      ),
      path: '/v1/waiting-list'
    },
    {
      element: (
        <PrivatePage>
          <Workspaces />
        </PrivatePage>
      ),
      path: '/v1/workspaces'
    },
    {
      element: (
        <PrivatePage>
          <WorkspacesCreator />
        </PrivatePage>
      ),
      path: '/v1/workspaces/create'
    },
    {
      element: (
        <PrivatePage>
          <Projects />
        </PrivatePage>
      ),
      path: '/v1/projects'
    },
    {
      element: (
        <PrivatePage>
          <ProjectNew />
        </PrivatePage>
      ),
      path: '/v1/projects/new'
    },
    {
      element: (
        <PrivatePage>
          <ProjectImporter />
        </PrivatePage>
      ),
      path: '/v1/projects/import'
    },
    {
      element: (
        <PrivatePage>
          <ProjectCreator isFullScreen />
        </PrivatePage>
      ),
      path: '/v1/projects/create'
    },
    {
      element: (
        <PrivatePage>
          <VisionDashboard />
        </PrivatePage>
      ),
      path: '/v1/dashboard'
    },
    {
      element: (
        <PrivatePage>
          <FeatureDocs />
        </PrivatePage>
      ),
      path: '/v1/feature-docs'
    },
    {
      element: (
        <PrivatePage>
          <FeatureDocs />
        </PrivatePage>
      ),
      path: '/v1/feature-docs/:id'
    },
    {
      element: (
        <PrivatePage feature="documents">
          <Documents />
        </PrivatePage>
      ),
      path: '/v1/documents'
    },
    {
      element: (
        <PrivatePage feature="documents">
          <Documents />
        </PrivatePage>
      ),
      path: '/v1/documents/:id',
    },
    {
      element: (
        <PrivatePage feature="transports">
          <Transports />
        </PrivatePage>
      ),
      path: '/v1/transports',
    },
    {
      element: (
        <PrivatePage feature="transports">
          <Transports />
        </PrivatePage>
      ),
      path: '/v1/transports/:id'
    },
    {
      element: (
        <PrivatePage feature="characters">
          <Characters />
        </PrivatePage>
      ),
      path: '/v1/characters'
    },
    {
      element: (
        <PrivatePage feature="characters">
          <Characters />
        </PrivatePage>
      ),
      path: '/v1/characters/:id'
    },
    {
      element: (
        <PrivatePage feature="creatures">
          <Creatures />
        </PrivatePage>
      ),
      path: '/v1/creatures'
    },
    {
      element: (
        <PrivatePage feature="creatures">
          <Creatures />
        </PrivatePage>
      ),
      path: '/v1/creatures/:id'
    },
    {
      element: (
        <PrivatePage feature="documents">
          <DocumentEditor />
        </PrivatePage>
      ),
      path: '/v1/documents/edit/:id'
    },
    {
      element: (
        <PrivatePage feature="segments">
          <Segments />
        </PrivatePage>
      ),
      path: '/v1/segments'
    },
    {
      element: (
        <PrivatePage feature="sketches">
          <Sketches />
        </PrivatePage>
      ),
      path: '/v1/sketches'
    },
    {
      element: (
        <PrivatePage feature="segments">
          <Segments />
        </PrivatePage>
      ),
      path: '/v1/segments/:id'
    },
    {
      element: (
        <PrivatePage feature="controls">
          <Controls />
        </PrivatePage>
      ),
      path: '/v1/controls'
    },
    {
      element: (
        <PrivatePage feature="controls">
          <Controls />
        </PrivatePage>
      ),
      path: '/v1/controls/:id'
    },
    {
      element: (
        <PrivatePage feature="texts">
          <Chats />
        </PrivatePage>
      ),
      path: '/v1/chats'
    },
    {
      element: (
        <PrivatePage feature="chats">
          <Chats />
        </PrivatePage>
      ),
      path: '/v1/chats/:id'
    },
    {
      element: (
        <PrivatePage feature="images">
          <Images />
        </PrivatePage>
      ),
      path: '/v1/images'
    },
    {
      element: (
        <PrivatePage feature="sounds">
          <Sounds />
        </PrivatePage>
      ),
      path: '/v1/sounds'
    },
    {
      element: (
        <PrivatePage feature="videos">
          <Videos />
        </PrivatePage>
      ),
      path: '/v1/videos'
    },
    {
      element: (
        <PrivatePage feature="graphs">
          <Graphs />
        </PrivatePage>
      ),
      path: '/v1/graphs',
    },
    {
      element: (
        <PrivatePage feature="graphs">
          <Graphs />
        </PrivatePage>
      ),
      path: '/v1/graphs/:id',
    },
    {
      element: (
        <PrivatePage feature="dialogues">
          <Dialogues />
        </PrivatePage>
      ),
      path: '/v1/dialogues',
    },
    {
      element: (
        <PrivatePage feature="dialogues">
          <Dialogues />
        </PrivatePage>
      ),
      path: '/v1/dialogues/:id',
    },
    {
      element: (
        <PrivatePage feature="illustrations">
          <AppFeatureMaintenance slice={'illustrations'} />
        </PrivatePage>
      ),
      path: '/v1/illustrations',
    },
    {
      element: (
        <PrivatePage feature="illustrations">
          <AppFeatureMaintenance slice={'illustrations'} />
        </PrivatePage>
      ),
      path: '/v1/illustrations/:id',
    },
    {
      element: (
        <PrivatePage feature="locations">
          <Locations />
        </PrivatePage>
      ),
      path: '/v1/locations'
    },
    {
      element: (
        <PrivatePage feature="locations">
          <Locations />
        </PrivatePage>
      ),
      path: '/v1/locations/:id'
    },
    {
      element: (
        <PrivatePage feature="objects">
          <Objects />
        </PrivatePage>
      ),
      path: '/v1/objects'
    },
    {
      element: (
        <PrivatePage feature="objects">
          <Objects />
        </PrivatePage>
      ),
      path: '/v1/objects/:id'
    },
    {
      element: (
        <PrivatePage feature="ai">
          <AIContainer />
        </PrivatePage>
      ),
      path: '/v1/ai'
    },
    {
      element: (
        <PrivatePage>
          <Account />
        </PrivatePage>
      ),
      path: '/v1/account'
    },
    {
      element: (
        <PrivatePage>
          <AccountAddCard />
        </PrivatePage>
      ),
      path: '/v1/add-card'
    },
    {
      element: (
        <NotFound />
      ),
      path: '/not-found'
    },
    {
      element: (
        <NotFound />
      ),
      path: '*'
    }
  ];

export const adminRoutes = [
  {
    element: (
      <PrivatePage>
        <AdminEnvironment />
      </PrivatePage>
    ),
    path: '/v1/admin/environment'
  },
  {
    element: (
      <PrivatePage>
        <AdminUsers />
      </PrivatePage>
    ),
    path: '/v1/admin/users'
  },
  {
    element: (
      <PrivatePage>
        <AdminDashboard />
      </PrivatePage>
    ),
    path: '/v1/admin/dashboard'
  },
  {
    element: (
      <PrivatePage>
        <AdminFeatures />
      </PrivatePage>
    ),
    path: '/v1/admin/features'
  },
  {
    element: (
      <PrivatePage>
        <AdminFinances />
      </PrivatePage>
    ),
    path: '/v1/admin/finances'
  },
  {
    element: (
      <PrivatePage>
        <AdminServices />
      </PrivatePage>
    ),
    path: '/v1/admin/services'
  },
  {
    element: (
      <PrivatePage>
        <AdminDocs />
      </PrivatePage>
    ),
    path: '/v1/admin/docs'
  },
  {
    element: (
      <PrivatePage>
        <AdminMaintenance />
      </PrivatePage>
    ),
    path: '/v1/admin/maintenance'
  },
  {
    element: (
      <PrivatePage>
        <AdminMarketing />
      </PrivatePage>
    ),
    path: '/v1/admin/marketing'
  },
  {
    element: (
      <PrivatePage>
        <AdminMessaging />
      </PrivatePage>
    ),
    path: '/v1/admin/messaging'
  },
  {
    element: (
      <PrivatePage>
        <AdminAnalytics />
      </PrivatePage>
    ),
    path: '/v1/admin/analytics'
  },
  {
    element: (
      <Changelog />
    ),
    path: '/changelog'
  },
  {
    element: (
      <NotFound />
    ),
    path: '/not-found'
  },
  {
    element: (
      <NotFound />
    ),
    path: '*'
  }
];

export default routes;
